import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConstants, SavaariServerErrorcode } from '../../environments/environment';
import { StorageService } from './storage.service';
import { TokenService } from './token.service';


@Injectable({
    providedIn: 'root'
})
export class BookingSessionService {

    private baseUrl: string = AppConstants.apiUrl;      // URL to the api of the user details and seo content
    private apiPlaygroundUrl: string = AppConstants.apiPlaygroundUrl;       // URL to the API PLAYGROUND
    private sourceCitiesPath: string = AppConstants.sourceCitiesPath;       // URL to the source cities details fetching
    private destinationCitiesPath: string = AppConstants.destinationCitiesPath;     // URL to the destination cities details fetching
    private localSubTripTypes: string = AppConstants.localSubTripTypes;     // URL to the destination cities details fetching
    private localitiesPath = AppConstants.localitiesPath;   // URL to the localities fetching in the API PLAYGROUND (of savaari team)


    private ApiUrl_internal: string = AppConstants.ApiUrl_internal;       // URL to the ADMIN API
    private enquiryApiUrl: string = AppConstants.enquiryApiUrl;

    private cancelBookingApiUrl: string = AppConstants.cancelBookingApiUrl

    private email_check_ApiUrl: string = AppConstants.email_check_ApiUrl;
    private date_time_path: string = AppConstants.date_time_path;
    private tripType = ''
    private subTripType = ''
    private previousSubTripTypeTrack = '';
    private selectedSourceCity: any
    private selectedAirportName: any
    private selectedLocality: any
    private selectedDestinationCity!: Array<any>;
    private fromDate!: Date;
    private toDate!: Date;
    private pickupTime: any
    private pickUpTimeOptions: Array<string> = [];
    private localityCities: Array<any> = [];

    private sourceCities: Array<any> = [];
    private destinationCities: Array<any> = [];

    private chardhamCities!: Array<any>;

    private tripTypeId = 3;

    private customerLocalityDetails: any;

    private dropLocalityDetails: any;

    private selectedLastDestinationCity!: Array<any>;

    private lastDestinationCityAppended = false;

    private bookingEditUrl: string = this.baseUrl + AppConstants.bookingEditUrl;
    private bookingUpdateUrl: string = this.baseUrl + AppConstants.bookingUpdateUrl;
    private bookingBillUrl: string = this.ApiUrl_internal + AppConstants.bookingBillUrl;
    imageUrl: string = 'https://admin.savaari.com/admin/select_car_banner_configuration.php';
    private temp: Array<any> = [];

    constructor(private tokenService: TokenService, private http: HttpClient, private localStorage: StorageService) {

    }

    // Retrieve the booking related details of the home page
    public retriveParamsFromLocalStorage() {

        const retrievedObject = this.localStorage.getItem('BookingParameters');
        if (retrievedObject) {

            const decoded = JSON.parse(retrievedObject);

            if (decoded.tripType) {
                this.tripType = (this.tripType) ? this.tripType : decoded.tripType;
            }

            if (decoded.subTripType) {
                this.subTripType = (this.subTripType) ? this.subTripType : decoded.subTripType;
            }
            if (decoded.previousSubTripTypeTrack) {
                this.previousSubTripTypeTrack = (this.previousSubTripTypeTrack) ? this.previousSubTripTypeTrack : decoded.previousSubTripTypeTrack;
            }
            if (decoded.selectedSourceCity) {
                this.selectedSourceCity = (this.selectedSourceCity) ? this.selectedSourceCity : decoded.selectedSourceCity;
            }
            if (decoded.customerLocalityDetails) {
                this.customerLocalityDetails = (this.customerLocalityDetails) ? this.customerLocalityDetails : decoded.customerLocalityDetails;
            }
            if (decoded.selectedSourceCity) {
                this.selectedDestinationCity = (this.selectedDestinationCity) ? this.selectedDestinationCity : decoded.selectedDestinationCity;
            }
            if (decoded.fromDate) {
                this.fromDate = (this.fromDate) ? this.fromDate : new Date(decoded.fromDate);
            }
            if (decoded.toDate) {
                this.toDate = (this.toDate) ? this.toDate : new Date(decoded.toDate);
            }
            if (decoded.pickupTime) {
                this.pickupTime = (this.pickupTime) ? this.pickupTime : decoded.pickupTime;
            }
            if (decoded.pickUpTimeOptions) {
                this.pickUpTimeOptions = (this.pickUpTimeOptions) ? this.pickUpTimeOptions : decoded.pickUpTimeOptions;
            }

            if (decoded.tripTypeId) {
                this.tripTypeId = (this.tripTypeId) ? this.tripTypeId : decoded.tripTypeId;
            }

        }
    }

    // Save the booking related details of the home page
    public saveParamsToLocalStorage() {
        const objectToSave: any = {};
        objectToSave['tripType'] = this.tripType;
        objectToSave['subTripType'] = this.subTripType;
        objectToSave['previousSubTripTypeTrack'] = this.previousSubTripTypeTrack;
        objectToSave['selectedSourceCity'] = this.selectedSourceCity;
        objectToSave['customerLocalityDetails'] = this.customerLocalityDetails;
        objectToSave['selectedDestinationCity'] = this.selectedDestinationCity;
        objectToSave['fromDate'] = this.fromDate;
        objectToSave['toDate'] = this.toDate;
        objectToSave['pickupTime'] = this.pickupTime;
        objectToSave['pickUpTimeOptions'] = this.pickUpTimeOptions;
        objectToSave['tripTypeId'] = this.tripTypeId;
        this.localStorage.setItem('BookingParameters', JSON.stringify(objectToSave));

    }

    // Remove the booking related details of the home page
    public clearParamsInLocalStorage() {
        this.localStorage.removeItem('BookingParameters');
    }

    //  getImage(): Observable<Blob> {
    //     const res: any = this.http.get(this.imageUrl);
    //     const contentType = res.headers.get('Banner');
    //     if (contentType == 'image/jpeg' || contentType == 'image/png' || contentType == 'image/jpeg; charset=UTF-8' || contentType == 'image/png; charset=UTF-8') {
    //         return  this.http.get(this.imageUrl, { responseType: 'blob' });;
    //     } else {
    //         throw (new Error());
    //     }


    //   }


    // Get saved to date from service
    public getToDate() {
        return this.toDate;
    }

    // Get saved from date from service
    public getFromDate() {
        return this.fromDate;
    }

    // Get saved pickup time from service
    public getPickupTime() {
        return this.pickupTime;
    }

    // Get saved destination city from service
    public getSelectedDestinationCity() {
        if (this.selectedDestinationCity == null) {
            return [];
        }
        return this.selectedDestinationCity;
    }

    // Get saved source city from service
    public getSelectedSourceCity() {
        return this.selectedSourceCity;
    }
    
    public getAirportName() {
        return  this.selectedAirportName;
    }
    // Get saved locality from service
    public getSelectedLocality() {
        return this.selectedLocality;
    }

    // Get saved trip type from service
    public getTripType() {
        return this.tripType;
    }

    // Get saved sub trip type from service
    public getSubTripType() {
        return this.subTripType;
    }

    // Get saved previous sub trip type from service
    public getPreviousSubTripType() {
        return this.previousSubTripTypeTrack;
    }

    public getPickUpTimeOptions() {
        return this.pickUpTimeOptions;
    }

    // Get saved sub trip type id from service
    public getTripTypeId() {
        return this.tripTypeId;
    }


    // Set  'to date' to service
    public setToDate(toDate: Date) {
        this.toDate = toDate
    }

    // Set 'from date' to service
    public setFromDate(fromDate: Date) {
        this.fromDate = fromDate
    }

    // Set 'pickup time' to service
    public setPickupTime(pickupTime: any) {
        this.pickupTime = pickupTime
    }

    // Set 'pickup time options' to service
    public setPickupTimeOptions(options: Array<string>) {
        this.pickUpTimeOptions = options
    }

    // Set 'trip type' to service
    public setTripType(tripType: string) {
        this.tripType = tripType;
        this.sourceCities = [];
        this.destinationCities = [];

    }

    // Set 'sub trip type id' to service
    public setTripTypeId(tripTypeId: number) {
        this.tripTypeId = tripTypeId;
        this.sourceCities = [];
        this.destinationCities = [];

    }


    // Set 'sub trip type' to service
    public setSubTripType(subTripType: string) {
        this.subTripType = subTripType;
        this.sourceCities = [];
        this.destinationCities = [];
    }

    // Set 'previous sub trip type' to service
    public setPreviousSubTripType(subTripType: string) {
        this.previousSubTripTypeTrack = subTripType;
    }

    // Set 'selected source city' to service
    public setSelectedSourceCity(city: any) {
        this.selectedSourceCity = city;
        this.destinationCities = [];
    }

    public async setAirportName(city: any) {
        const airportNames = this.getAirportNames();
        this.selectedAirportName = (await airportNames).find((a: any) => a.cityName === city);
        console.log(this.selectedAirportName );
    }

    // Set 'selected source city' to service
    public setSelectedLocality(locality: any) {
        this.selectedLocality = locality;
    }

    // Set 'selected destination city' to service
    public setSelectedDestinationCity(cities: any) {
        this.selectedDestinationCity = cities;
    }



    // Get 'potential source cities' list
    public getPotentialSourceCity(term: String) {

        term = term.replace(/^\s+|\s+$/g, '');
        if (this.sourceCities) {
            return this.sourceCities.find(city => city.cityName === term);
        }

        return null;

    }

    // Fetching Locality list for local booking widget from the API PLAYGROUND
    async getAirportLocalities(selectedSourceCityId: string): Promise<any> {

        try {

            // if (this.localityCities != null) {
            //     return this.localityCities;
            // }            
            let token = await this.tokenService.getToken()
            const params = new HttpParams({
                fromObject: {
                    'sourceCity': selectedSourceCityId,
                    'token': token
                }
            });

            const url = this.apiPlaygroundUrl + this.localitiesPath;
            let cacheKey = this.tripType + 'locality' + this.selectedSourceCity.cityId
            this.localityCities = this.localStorage.getCacheItem(cacheKey);

            if (this.localityCities == null) {
                const res: any = await this.http.get(url, { params: params }).toPromise()
                this.localityCities = res.data;
                this.localStorage.setCacheItem(cacheKey, this.localityCities);
            }
            return this.localityCities;

        } catch (error: any) {
            try {
                const errors = error.json().errors;
                if (errors instanceof Array) {
                    if (errors.length > 0) {
                        let error = errors[0];
                        if (error.errroCode == SavaariServerErrorcode.PartnerApiTokenError) {
                            this.tokenService.removeToken();
                        }
                    }
                }

                return [];

            } catch (e) {

                return [];

            }
        }
    }

    async preFetchLocalSubTripTypes(): Promise<any[]> {

        try {
            let token = await this.tokenService.getToken();

            const url = this.apiPlaygroundUrl + this.localSubTripTypes

            let cacheKey = "LocalSubTripTypes";
            if (this.localStorage.getCacheItem(cacheKey) == null) {
                const res: any = await this.http.get(url, { params: { 'token': token } }).toPromise()
                this.localStorage.setCacheItem(cacheKey, res.data);
            }
            return [];

        } catch (error: any) {

            try {
                const errors = error.json().errors;
                if (errors instanceof Array) {
                    if (errors.length > 0) {
                        let error = errors[0];
                        if (error.errroCode == SavaariServerErrorcode.PartnerApiTokenError) {
                            this.tokenService.removeToken();
                        }
                    }
                }

                return [];

            } catch (e) {

                return [];

            }

        }
    }

    async preFetchDestinationCities(selectedSourceCityId: string): Promise<any[]> {

        try {
            let token = await this.tokenService.getToken();

            if (!this.tripType) {
                this.tripType = 'outstation';
            }
            const params = new HttpParams({
                fromObject: {
                    'tripType': this.tripType,
                    'subTripType': 'roundTrip',
                    'token': token,
                    'sourceCity': selectedSourceCityId
                }
            });

            const url = this.apiPlaygroundUrl + this.destinationCitiesPath;

            let cacheKey = this.tripType + 'roundTrip';
            /* if (this.tripType == "outstation" && this.subTripType == "roundTrip") {
                cacheKey = this.tripType + this.subTripType
            } else {
                cacheKey = this.tripType + this.subTripType + this.selectedSourceCity.cityId
            } */

            if (this.localStorage.getCacheItem(cacheKey) == null) {
                const res: any = await this.http.get(url, { params: params }).toPromise();
                this.localStorage.setCacheItem(cacheKey, res.data);
                return res;
            }
            return [];

        } catch (error: any) {

            try {
                const errors = error.json().errors;
                if (errors instanceof Array) {
                    if (errors.length > 0) {
                        let error = errors[0];
                        if (error.errroCode == SavaariServerErrorcode.PartnerApiTokenError) {
                            this.tokenService.removeToken();
                        }
                    }
                }

                return [];

            } catch (e) {

                return [];

            }

        }
    }

    async roundtripDestinationCities(): Promise<any[]> {

        try {
            let token = await this.tokenService.getToken();
            const params = new HttpParams({
                fromObject: {
                    'tripType': 'outstation',
                    'subTripType': 'roundTrip',
                    'token': token,
                    'sourceCity': '377'
                }
            });

            const url = this.apiPlaygroundUrl + this.destinationCitiesPath

            let cacheKey = 'outstationroundTrip'

            if (this.localStorage.getCacheItem(cacheKey) == null) {
                const res: any = await this.http.get(url, { params: params }).toPromise()
                this.localStorage.setCacheItem(cacheKey, res.data);
            }
            return [];

        } catch (error: any) {

            try {
                const errors = error.json().errors;
                if (errors instanceof Array) {
                    if (errors.length > 0) {
                        let error = errors[0];
                        if (error.errroCode == SavaariServerErrorcode.PartnerApiTokenError) {
                            this.tokenService.removeToken();
                        }
                    }
                }

                return [];

            } catch (e) {

                return [];

            }

        }
    }

    // Get 'source cities' list 
    async getSourceCities(): Promise<any[]> {

        try {

            let token = await this.tokenService.getToken();

            if (this.sourceCities.length != 0) {
                return this.sourceCities;
            }

            /*  params.set('tripType', this.tripType);
             if (this.tripType == 'local' && this.subTripType == '')
                 params.set('subTripType', '880');
             else
                 params.set('subTripType', this.subTripType); */
            const params = new HttpParams({
                fromObject: {
                    'tripType': 'outstation',
                    'subTripType': 'roundTrip',
                    'token': token
                }
            });

            const url = this.apiPlaygroundUrl + this.sourceCitiesPath;

            this.sourceCities = this.localStorage.getCacheItem("sourceCityCache");

            if (this.sourceCities == null || this.sourceCities.length == 0) {
                const res: any = await this.http.get(url, { params: params }).toPromise()
                this.sourceCities = res.data;
                this.localStorage.setCacheItem("sourceCityCache", this.sourceCities);
            }
            // Remove '-Alias' from city names
            this.sourceCities = this.sourceCities.map(city => {
                city.cityName = city.cityName.replace(/-Alias$/, '');
                return city;
            });
            return this.sourceCities;

        } catch (error: any) {

            try {
                const errors = error.json().errors;
                if (errors instanceof Array) {
                    if (errors.length > 0) {
                        let error = errors[0];
                        if (error.errroCode == SavaariServerErrorcode.PartnerApiTokenError) {
                            this.tokenService.removeToken();
                        }
                    }
                }

                return [];

            } catch (e) {

                return [];

            }
        }

    }

    async getAirportNames() {

        try {
            let token = await this.tokenService.getToken()
            const params = new HttpParams().set('token', token);


            const url = this.apiPlaygroundUrl + 'airport-list';
            this.temp = this.localStorage.getCacheItem("airportNamesCache");

            if (this.temp == null || this.temp.length == 0) {
                const res: any = await this.http.get(url, { params: params }).toPromise()
                this.temp = res.data;
                this.localStorage.setCacheItem("airportNamesCache", this.temp);
            }
            return this.temp;
        }
        catch (error: any) {

            try {
                const errors = error.json().errors;
                if (errors instanceof Array) {
                    if (errors.length > 0) {
                        let error = errors[0];
                        if (error.errroCode == SavaariServerErrorcode.PartnerApiTokenError) {
                            this.tokenService.removeToken();
                        }
                    }
                }

                return [];

            } catch (e) {

                return [];

            }
        }
    }

    //get 'dest cities for oneway existance'
    async getonewayexistance(cityid: any, cityname: any): Promise<any> {

        try {

            let token = await this.tokenService.getToken();
            if (cityid) {

                const params = new HttpParams({
                    fromObject: {
                        'tripType': 'outstation',
                        'subTripType': 'oneWay',
                        'token': token,
                        'sourceCity': cityid
                    }
                });

                const url = this.apiPlaygroundUrl + this.destinationCitiesPath

                let cacheKey = "outstationoneWay"

                this.destinationCities = this.localStorage.getCacheItem(cacheKey);

                if (this.destinationCities == null) {
                    this.destinationCities = this.localStorage.getCacheItem('outstationroundTrip')
                    this.localStorage.setCacheItem(cacheKey, (this.destinationCities));
                }

                if (this.destinationCities == null) {
                    const res: any = await this.http.get(url, { params: params }).toPromise()
                    this.destinationCities = res.data;
                    this.localStorage.setCacheItem(cacheKey, (this.destinationCities));
                }

                return this.destinationCities;

            }
            else {
                throw Error()
            }

        } catch (error: any) {

            try {
                const errors = error.json().errors;
                if (errors instanceof Array) {
                    if (errors.length > 0) {
                        let error = errors[0];
                        if (error.errroCode == SavaariServerErrorcode.PartnerApiTokenError) {
                            this.tokenService.removeToken();
                        }
                        if (error.errroCode == SavaariServerErrorcode.NoDestinationCityList) {
                            this.destinationCities = [];
                        }
                    }
                }
                return [];
            } catch (e) {
                return [];
            }
        }


    }


    // Get 'Destination cities' list 
    async getDestinationCities(): Promise<any[]> {


        try {

            let token = await this.tokenService.getToken();

            if (this.destinationCities.length != 0) {
                return this.destinationCities;
            }


            if (this.selectedSourceCity && this.selectedSourceCity.cityId) {

                if (!this.tripType && this.subTripType == 'roundTrip' || this.subTripType == 'oneWay') { this.tripType = 'outstation'; }
                const params = new HttpParams({
                    fromObject: {
                        'tripType': this.getTripType(),
                        'subTripType': this.getSubTripType(),
                        'token': token,
                        'sourceCity': this.selectedSourceCity.cityId
                    }
                });

                const url = this.apiPlaygroundUrl + this.destinationCitiesPath

                let cacheKey = ""
                if (this.tripType == "outstation" && this.subTripType == "roundTrip") {
                    cacheKey = this.tripType + this.subTripType
                } else {
                    cacheKey = this.tripType + this.subTripType;
                }

                this.destinationCities = this.localStorage.getCacheItem(cacheKey);

                if (this.destinationCities == null) {
                    const res: any = await this.http.get(url, { params: params }).toPromise()
                    this.destinationCities = res.data;
                    this.localStorage.setCacheItem(cacheKey, (this.destinationCities));
                }

                return this.destinationCities;


            } else {
                throw Error()
            }

        } catch (error: any) {
            try {
                const errors = error.json().errors;
                if (errors instanceof Array) {
                    if (errors.length > 0) {
                        let error = errors[0];
                        if (error.errroCode == SavaariServerErrorcode.PartnerApiTokenError) {
                            this.tokenService.removeToken();
                        }
                        if (error.errroCode == SavaariServerErrorcode.NoDestinationCityList) {
                            this.destinationCities = [];
                        }
                    }
                }
                return [];
            } catch (e) {
                return [];
            }

        }

    }

    async getEnquiryDetails(enquiry_id: any): Promise<any> {
        try {

            const api_url = this.ApiUrl_internal + this.enquiryApiUrl + '?enquiry_id=' + enquiry_id;
            console.log(api_url);
            const response = await this.http.get(api_url).toPromise();

            return response;

        } catch (error: any) {

            try {

                const errors = error.json().errors;
                if (errors instanceof Array) {
                    error.error = errors[0];
                }
                return Promise.reject(error);

            } catch (e) {
                return Promise.reject(e);

            }
        }
    }
    async check_sent_email(booking_id: any): Promise<any> {
        try {

            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj = new FormData();
            bodyObj.append('booking_id', booking_id);
            const api_url = this.apiPlaygroundUrl + this.email_check_ApiUrl;
            console.log(api_url);
            const response: any = await this.http.post(api_url, bodyObj, { headers: headers }).toPromise();

            return response.data;

        } catch (error: any) {

            try {

                const errors = error.json().errors;
                if (errors instanceof Array) {
                    error.error = errors[0];
                }
                return Promise.reject(error);

            } catch (e) {
                return Promise.reject(e);

            }
        }
    }
    async get_date_time(): Promise<string> {

        try {

            const url = this.apiPlaygroundUrl + this.date_time_path;
            const res: any = await this.http.get(url).toPromise();
            const body = res.data;
            return body;

        } catch (error) {
            return '';
        }

    }

    async isImageExists(url: string): Promise<boolean> {

        try {

            const res: any = await this.http.get(url).toPromise();
            const contentType = res.headers.get('Content-type');
            if (contentType == 'image/jpeg' || contentType == 'image/png' || contentType == 'image/jpeg; charset=UTF-8' || contentType == 'image/png; charset=UTF-8') {
                return true;
            } else {
                throw (new Error());
            }

        } catch (error) {
            return false;
        }

    }


    // Set customer google address - address,locality,latlong to service
    public setCustomerLocalityDetails(locality: any) {
        this.customerLocalityDetails = locality;
    }
    // Get saved customer google address - address,locality,latlong from service
    public getCustomerLocalityDetails() {
        return this.customerLocalityDetails;
    }

    // Set drop google address - address,locality,latlong to service
    public setCustomerDropLocalityDetails(locality: any) {
        this.dropLocalityDetails = locality;
    }
    // Get saved drop google address - address,locality,latlong from service
    public getCustomerDropLocalityDetails() {
        return this.dropLocalityDetails;
    }


    // Get 'Chardham cities' list
    /* async getChardhamCities(): Promise<any[]> {


        try {

            let token = await this.tokenService.getToken();

            if (this.chardhamCities != null) {
                return this.chardhamCities;
            }

            const url = this.apiPlaygroundUrl + this.chardhamCitiesPath

            let cacheKey = "chardhamCitiesList"

            this.chardhamCities = this.localStorage.getCacheItem(cacheKey);

            if (this.chardhamCities == null) {
                const res: any = await this.http.get(url, { params: { 'token': token } }).toPromise()
                this.chardhamCities = res.data;
                this.localStorage.setCacheItem(cacheKey, (this.chardhamCities));
            }
            //console.log(this.chardhamCities);
            return this.chardhamCities;

        } catch (error) {
            return [];
        }

    } */

    // Get saved chardham city from service
    public getchardhamCitiesList() {
        return this.chardhamCities;
    }

    // Set 'selected destination city if same as source city' to service
    public setSelectedLastDestinationCity(city: any) {
        this.selectedLastDestinationCity = city;
    }

    public getSelectedLastDestinationCity() {
        return this.selectedLastDestinationCity;
    }

    public setLastSelecteCityAppended(status: boolean) {
        this.lastDestinationCityAppended = status;
    }

    public getLastSelecteCityAppended() {
        return this.lastDestinationCityAppended;
    }

    async cancelBooking(booking: any, cancel_reason: string, cancel_comment: string): Promise<any> {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');

            const bodyObj = new FormData();
            bodyObj.append('booking_id', booking['booking_id']);
            bodyObj.append('reservation_id', booking['reservation_id']);
            bodyObj.append('reason', cancel_reason);
            bodyObj.append('comments', cancel_comment);
            bodyObj.append('booking_key', booking['booking_key']);
            bodyObj.append('booking_type', '1');

            const response = await this.http.post(this.cancelBookingApiUrl, bodyObj).toPromise();
            return response;

        } catch (error: any) {
            try {
                const errors = error.json().errors;
                if (errors instanceof Array) {
                    error.error = errors[0];
                }
                return Promise.reject(error);

            } catch (e) {
                return Promise.reject(e);
            }
        }
    }

    // Get booking data of booking id and email
    async bookingDetails(booking_id: any, accesskey: any, token: any): Promise<any> {
        try {
            const params = new HttpParams({
                fromObject: {
                    'booking_id': booking_id,
                    'accesskey': accesskey,
                    'token': token
                }
            });

            const res: any = await this.http.get(this.bookingEditUrl, { params: params }).toPromise();

            if (res.status === 204) {
                return Promise.reject(res)
            } else {
                const body = res;
                // const data = body.bookingDetails;
                // return data;
                return body;
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async updateBooking(booking_details: any, token: any, flag = 1): Promise<any> {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');

            const bodyObj = new HttpParams({
                fromObject: {
                    'token': token,
                    'system_token': await this.tokenService.getToken(),
                    'flag': flag.toString(),
                    'booking_id': booking_details.booking_id,
                    'accesskey': booking_details.accesskey,
                    'actual_name': booking_details.actual_name,
                    'payment_email': booking_details.payment_email,
                    'payment_phone_code': booking_details.payment_phone_code,
                    'payment_phone': booking_details.payment_phone,
                    'pick_up_time': booking_details.pick_up_time,
                    'pick_loc': booking_details.pick_loc,
                    'pickup_latitude': booking_details.pickup_latitude,
                    'pickup_longitude': booking_details.pickup_longitude,
                    'landmark': booking_details.landmark,
                    'custLatLong': booking_details.custLatLong,
                    'locality': booking_details.locality,
                    'custLocalityDetails': booking_details.custLocalityDetails
                }
            });

            const res: any = await this.http.post(this.bookingUpdateUrl, bodyObj, { headers: headers }).toPromise();
            if (res.status === 204) {
                return Promise.reject(res)
            } else {
                const body = res;
                // const data = body.bookingDetails;
                // return data;
                return body;
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }

    // Fetch get Top source cities list from the JSON file
    async getTopSourceCities(): Promise<any> {
        try {
            const response = [
                { "cityId": 377, "cityName": "Bangalore, Karnataka", "tier": null,"top_dest_ids": "153,1222,2754,4346,7878","cityOnly":"Bangalore", "stateOnly":"Karnataka" },
                { "cityId": 145, "cityName": "New Delhi", "tier": null,"top_dest_ids": "427,2532,3347,3736,3964","cityOnly":"New Delhi", "stateOnly":"New Delhi" },
                { "cityId": 114, "cityName": "Mumbai, Maharashtra", "tier": null,"top_dest_ids":"713,1913,1959,1993,2026" ,"cityOnly":"Mumbai", "stateOnly":"Maharashtra"  },
                { "cityId": 81, "cityName": "Chennai, TN", "tier": null,"top_dest_ids": "153,1076,2303,3293,4455","cityOnly":"Chennai", "stateOnly":"TN"  },
                { "cityId": 261, "cityName": "Pune, Maharashtra", "tier": null,"top_dest_ids": "1913,1914,1959,2026,4483" ,"cityOnly":"Pune", "stateOnly":"Maharashtra" },
                { "cityId": 223, "cityName": "Hyderabad, Telangana", "tier": null ,"top_dest_ids":"45,64,71,161,1076","cityOnly":"Hyderabad", "stateOnly":"Telangana"  },
                { "cityId": 48, "cityName": "Ahmedabad, Gujarat", "tier": null ,"top_dest_ids":"565,697,726,2653,4483","cityOnly":"Ahmedabad", "stateOnly":"Gujarat"  },
                { "cityId": 320, "cityName": "Kochi, Kerala", "tier": null ,"top_dest_ids":"2769,4368,4382,4484,9300","cityOnly":"Kochi", "stateOnly":"Kerala"  },
                { "cityId": 422, "cityName": "Chandigarh", "tier": null,"top_dest_ids":"863,884,2312,2393,4506" ,"cityOnly":"Chandigarh", "stateOnly":"Chandigarh"  },
                { "cityId": 36, "cityName": "Kolkata, WB", "tier": null ,"top_dest_ids": "1004,1832,4029,4165,4389,4616","cityOnly":"Kolkata", "stateOnly":"WB" }
            ];
            return response
        } catch (error: any) {
            try {
                const resultData = error.json();
                error.message = resultData.message;
                return Promise.reject(error)

            } catch (e) {
                return Promise.reject(error)
            }
        }
    }

    async bookingBillDetails(booking_id: any, booking_key: any, return_type: any): Promise<any> {
        try {
            const params = new HttpParams({
                fromObject: {
                    'booking_id': booking_id,
                    'booking_key': booking_key,
                    'bill_type': 'customer',
                    'return_type': return_type
                }
            });

            const res: any = await this.http.get(this.bookingBillUrl, { params: params }).toPromise();

            if (res.status === 204) {
                return Promise.reject(res)
            } else {
                const body = res;
                // const data = body.bookingDetails;
                // return data;
                return body;
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async bookingEmailDetails(booking_id: any, booking_key: any): Promise<any> {
        try {
            const params = new HttpParams({
                fromObject: {
                    'booking_id': booking_id,
                    'booking_key': booking_key
                }
            });

            const res: any = await this.http.get(this.ApiUrl_internal+'data/customer_booking_pdf.php', { params: params }).toPromise();

            if (res.status === 204) {
                return Promise.reject(res)
            } else {
                const body = res;
                // const data = body.bookingDetails;
                // return data;
                return body;
            }
        } catch (error) {
            return Promise.reject(error)
        }
    }
}
