import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConstants } from '../../environments/environment';
import { cittocityStaticContent } from '../homepage.utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class SeoContentService {

    private baseUrl: string = AppConstants.webcacheUrl;     // URL to the api of the user details and seo content
    private seoContentPath: string = AppConstants.seoContentPath;      // URL to the seo content fetching
    private bannerImagePath: string = AppConstants.bannerImagePath;      // URL to the banner fetching
    private seoCityListPath: string = AppConstants.seoCityListPath;      // URL to the seo city list fetching
    private contentSubject = new BehaviorSubject<any>(null);
    content$ = this.contentSubject.asObservable();

    constructor(private http: HttpClient) { }

    setContent(content: any) {
      this.contentSubject.next(content);
    }

    async getDomainSeoContent(domainName: string): Promise<any> {

        const params = new HttpParams({
            fromObject: {
                'domainName': domainName
            }
        });

        const url = this.baseUrl + this.seoContentPath

        try {
            const res = await this.http.get(url, { params: params }).toPromise();
            setTimeout(() => {
                window['prerenderReady'] = true;
                console.log('prerenderReady - ' + window['prerenderReady']);
            }, 2000);            
            const body = res;
            return body;
        } catch (error) {
            return false;
        }

    }

    // Fetch the seo content from the database
    async getSeoContent(stateName: string, sourceCity: string, destinationCity: string,
        seoType: number, carType: string,
        tripType: string, subTripType: string, locality: string, web_page_url: string ): Promise<any> {

        let params = new HttpParams({
            fromObject: {
                'stateName': stateName,
                'sourceCity': sourceCity,
                'destinationCity': destinationCity,
                'seoType': seoType.toString(),
                'carType': carType,
                'tripType':  tripType,
                'subTripType': subTripType,
                'locality': locality,
                'web_page_url': web_page_url
            }
        });

        for (const key of params.keys()) {
            if (params.get(key) == undefined) {
                params = params.delete(key, undefined);
            }
        }

        const url = this.baseUrl + this.seoContentPath;

            try {
                const res = await this.http.get(url, { params: params }).toPromise();
                setTimeout(() => {
                    window['prerenderReady'] = true;
                    console.log('prerenderReady - ' + window['prerenderReady']);
                }, 2000);
                const body: any = res;

                const content: { [key: string]: any; } = {};
                content['newUIFlag'] = body.newUIFlag;

                if(body.newUIFlag === 1)
                    {
                        if(body.newUIContent)
                            {
                                content['newUIContent'] = body.newUIContent;
                            }
                    }

                content['sourceDetail'] = body.sourceCity;

                let destinationCity = body.destinationCity
                if (destinationCity != undefined) {
                    content['destinationDetail'] = destinationCity;
                    content['destinationCity'] = destinationCity.cityName;
                }
                if (body.sourceCity != undefined) {
                    content['sourceCity'] = body.sourceCity.cityName;
                }
                if (body.locality != undefined) {
                    content['locality'] = body.locality;
                }
                if (body.stateName != undefined) {
                    content['stateName'] = body.stateName;
                }
                content['seo_content'] = body.seo_content;
                content['tripType'] = body.tripType;
                content['subTripType'] = body.subTripType;
                content['description'] = body.description;
                content['title'] = body.title;
                content['keywords'] = body.keywords;
                content['h1Header'] = body.h1Header;
                content['banner_images'] = body.banner_images;
                return content;
            } catch (error: any) {
                const resultData = error.error;
                error.message = resultData.message;
                return Promise.reject(error);
            }

    }



    // getSeoRevamp(): Observable<SeoContent>{
    //     const url = 'https://api.betasavaari.com/seo_content.php?test_url=https://www.betasavaari.com/bangalore/bangalore-to-mysore-cabs';
        
    //     try{
    //         setTimeout(() => {
    //             window['prerenderReady'] = true;
    //             console.log('prerenderReady - ' + window['prerenderReady']);
    //         }, 2000);
    //         const res = this.http.get<SeoContent>(url);
    //         return res;
    //     }
    //     catch(error: any){
    //         const resultData = error.error;
    //         error.message = resultData.message;
    //         return error;
    //     }
        
    // }

    getNewSeoContent(stateName: string, sourceCity: string, destinationCity: string,
        seoType: number, carType: string, tripType: string, subTripType: string, locality: string): Observable<any> {
        let params = new HttpParams({
            fromObject: {
                'stateName': stateName,
                'sourceCity': sourceCity,
                'destinationCity': destinationCity,
                'seoType': seoType.toString(),
                'carType': carType,
                'tripType': seoType.toString() == '7' ? 'local' : tripType,
                'subTripType': subTripType,
                'locality': locality
            }
        });

        for (const key of params.keys()) {
            if (params.get(key) == undefined) {
                params = params.delete(key, undefined);
            }
        }

        const url = this.baseUrl + AppConstants.newSeoContentPath;
        setTimeout(() => {
            window['prerenderReady'] = true;
            console.log('prerenderReady - ' + window['prerenderReady']);
        }, 2000);
        return this.http.get(url, { params: params });

    }

    async getcitytocitySeoContent(sourceCity: string, destinationCity: string,
        seoType: number,
        tripType: string, subTripType: string): Promise<any> {

        const params = new HttpParams({
            fromObject: {
                'sourceCity': sourceCity,
                'destinationCity': destinationCity,
                'seoType': seoType.toString(),
                'tripType': tripType,
                'subTripType': subTripType,
            }
        });

        const url = this.baseUrl + this.seoContentPath

        try {
            const res = await this.http.get(url, { params: params }).toPromise();
            setTimeout(() => {
                window['prerenderReady'] = true;
                console.log('prerenderReady - ' + window['prerenderReady']);
            }, 2000);
            const body: any = res;
            return this.checkDataExsits(body, sourceCity, destinationCity);
        } catch (error) {
            return this.checkDataExsits({}, sourceCity, destinationCity);
        }
    }

    checkDataExsits(body: any, sourceCity: string, destinationCity: string) {
        body.fromCity = (body.fromCity) ? body.fromCity : sourceCity;
        body.toCity = (body.toCity) ? body.toCity : destinationCity;
        body.bannerImageTitle = (body.bannerImageTitle) ? body.bannerImageTitle : cittocityStaticContent.bannerImage;
        body.bannerImageAlt = (body.bannerImageAlt) ? body.bannerImage : this.replaceSourceDestination(cittocityStaticContent.bannerImageAlt, sourceCity, destinationCity);
        body.byRoadMapImageTitle = (body.byRoadMapImageTitle) ? body.byRoadMapImageTitle : cittocityStaticContent.mapImage;
        body.byRoadMapImageAlt = (body.byRoadMapImageAlt) ? body.byRoadMapImageAlt : this.replaceSourceDestination(cittocityStaticContent.mapImageAlt, sourceCity, destinationCity);
        body.h1Header = (body.h1Header) ? body.h1Header : this.replaceSourceDestination(cittocityStaticContent.h1Header, sourceCity, destinationCity);
        body.overviewHeader = (body.overviewHeader) ? body.overviewHeader : this.replaceSourceDestination(cittocityStaticContent.overviewHeader, sourceCity, destinationCity);
        body.overviewContent = (body.overviewContent) ? body.overviewContent : this.replaceSourceDestination(cittocityStaticContent.overviewContent, sourceCity, destinationCity);
        body.thingstodoTitle = (body.thingstodoTitle) ? body.thingstodoTitle : this.replaceSourceDestination(cittocityStaticContent.thingstodoTitle, sourceCity, destinationCity);
        if (body.byRoadContent && body.byRoadContent != '') {
            body.byRoadTitle = '';
        } else {
            body.byRoadTitle = (body.byRoadTitle) ? body.byRoadTitle : this.replaceSourceDestination(cittocityStaticContent.byRoadTitle, sourceCity, destinationCity);
        }

        body.byRoadContent = (body.byRoadContent) ? body.byRoadContent : this.replaceSourceDestination(cittocityStaticContent.byRoadContent, sourceCity, destinationCity);
        body.metaTitle = (body.metaTitle) ? body.metaTitle : this.replaceSourceDestination(cittocityStaticContent.metaTitle, this.capitalFirstLetter(sourceCity), this.capitalFirstLetter(destinationCity));
        body.metaDescription = (body.metaDescription) ? body.metaDescription : this.replaceSourceDestination(cittocityStaticContent.metaDescription, this.capitalFirstLetter(sourceCity), this.capitalFirstLetter(destinationCity));
        body.metaKeywords = (body.metaKeywords) ? body.metaKeywords : this.replaceSourceDestination(cittocityStaticContent.metaKeywords, sourceCity, destinationCity);
        // body.relatedLinks = body.relatedLinks.slice(0, 5);
        body.thingsTodo = body.thingsTodo.slice(0, 6);
        return body;
    }


    replaceSourceDestination(data: string, sourceCity: string, destinationCity: string) {
        const sourceToDestination = sourceCity + " to " + destinationCity;
        let replaceString = data.replace(new RegExp('{A-B}', 'gi'), sourceToDestination);
        replaceString = replaceString.replace(new RegExp('{B-}', 'gi'), destinationCity);
        replaceString = replaceString.replace(new RegExp('{A-}', 'gi'), sourceCity);
        return replaceString;
    }


    getBannerImagesList(sourceCity: string, destinationCity: string, seoType: number): Observable<any> {

        const params = new HttpParams({
            fromObject: {
                'sourceCity': sourceCity,
                'destinationCity': destinationCity,
                'seoType': seoType.toString()
            }
        });

        const url = this.baseUrl + this.bannerImagePath;
        return this.http.get(url, { params: params }).pipe(
            map(res => res));
        // .catch((error: any) => Observable.throw(error.json().error || 'Server error'));
    }


    async getSeoContentCityList(seoType: number): Promise<any> {


        const params = new HttpParams({
            fromObject: {
                'seoType': seoType.toString()
            }
        });



        const url = this.baseUrl + this.seoCityListPath


        try {

            const res = await this.http.get(url, { params: params }).toPromise();
            setTimeout(() => {
                window['prerenderReady'] = true;
                console.log('prerenderReady - ' + window['prerenderReady']);
            }, 2000);
            const body = res;
            return body;


        } catch (error) {
            return Promise.reject(error);

        }

    }

    async gettravelguideSeoContent(sourceCity: string, seoType: number, tripType: string, subTripType: string): Promise<any> {

        const params = new HttpParams({
            fromObject: {
                'sourceCity': sourceCity,
                'seoType': seoType.toString(),
                'tripType': tripType,
                'subTripType': subTripType
            }
        });

        const url = this.baseUrl + this.seoContentPath

        try {

            const res = await this.http.get(url, { params: params }).toPromise();
            setTimeout(() => {
                window['prerenderReady'] = true;
                console.log('prerenderReady - ' + window['prerenderReady']);
            }, 2000);
            const body = res;
            return body;
            //const body = res.json();
            //return this.checkDataExsits(body, sourceCity, destinationCity);


        } catch (error) {
            //return this.checkDataExsits({}, sourceCity, destinationCity);
            return Promise.reject(error);

        }

    }

    capitalFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
}
