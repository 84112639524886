<!-- Start of Header -->
<header id='sv_header' class="w-100 p-2 px-md-0">
  <div class="row align-items-center justify-content-between container mx-auto px-0">
    <div>
      <a *ngIf="!ixigoFlag && !mahindraFlag" routerLink="/" (click)='triptypedata()'>
        <img alt="Savaari" src="{{logo}}" [width]='!mobileMode ?"142": ( ixigoFlag ? "108" : "108" ) '
          [height]='!mobileMode ? "49": (ixigoFlag ? "53" : "36") ' />
      </a>
      <a *ngIf="!ixigoFlag && mahindraFlag && !mobileMode" routerLink="/" style="position: absolute; top: 0;">
        <img alt="Savaari" src="/assets/ClubM_logo1.png" [width]='170 ' [height]='80' />
      </a>
      <!-- <a *ngIf="!ixigoFlag && mahindraFlag && !mobileMode" routerLink="/" (click)='triptypedata()'>
        <img alt="Savaari" src="{{logo}}" [width]='!mobileMode ?"142": ( ixigoFlag ? "108" : "108" ) '
          [height]='!mobileMode ? "49": (ixigoFlag ? "53" : "36") ' />
      </a> -->

      <a *ngIf="!ixigoFlag && mahindraFlag  && mobileMode" routerLink="/" style="position: absolute;
      top: 0px;">
        <img alt="Savaari" src="/assets/ClubM_logo1.png" [width]='120' [height]='65' />
      </a>

      <a *ngIf="!ixigoFlag && mahindraFlag && mobileMode" routerLink="/" style="position: absolute;
      right: 18px;
      top: 15px;" (click)='triptypedata()'>
        <img alt="Savaari" src="{{logo}}" [width]='!mobileMode ?"142": ( ixigoFlag ? "108" : "108" ) '
          [height]='!mobileMode ? "49": (ixigoFlag ? "53" : "36") ' />
      </a>

      <a *ngIf="!ixigoFlag && mahindraFlag && !mobileMode" routerLink="/" style="position: absolute; right: 150px; "
        (click)='triptypedata()'>
        <img alt="Savaari" src="{{logo}}" [width]='!mobileMode ?"142": ( ixigoFlag ? "108" : "108" ) '
          [height]='!mobileMode ? "49": (ixigoFlag ? "53" : "36") ' />
      </a>
      <!-- <a *ngIf="!ixigoFlag && mahindraFlag && !mobileMode" routerLink="/" style="position: absolute; right: 150px; top: 0;">
        <img alt="Savaari" src="/assets/ClubM_logo1.png" [width]='170 ' [height]='80' />
      </a> -->
      <a *ngIf="ixigoFlag && ixigoColor == '#EC5B24'" href="/ixigo_exit.php" (click)='triptypedata()'>
        <img alt="Savaari" src="{{logo}}" [width]='!mobileMode ?"142": ( ixigoFlag ? "108" : "108" ) '
          [height]='!mobileMode ? "49": (ixigoFlag ? "53" : "36") ' />
      </a>
      <a *ngIf="ixigoFlag && ixigoColor == '#1556BA'" href="javascript:void(0);">
        <img src="{{logo}}" [width]='!mobileMode ?"142": ( ixigoFlag ? "108" : "108" ) '
          [height]='!mobileMode ? "49": (ixigoFlag ? "53" : "36") ' />
      </a>
    </div>
    <div *ngIf="!ixigoFlag && !mahindraFlag">
      <a href="tel:9045450000" *ngIf="mobileMode && showContactUs" (click)="hideMenu('hide')">
        <!-- <div class="bg-cc_support m-auto"></div> -->
        <div class="cc-phone-num">
          <i class="pi pi-phone"></i><span>9045450000</span>
        </div>
      </a>
      <div *ngIf="!mobileMode && showContactUs">
        <div alt="Contact Us: +919045450000 " style=" width: 200px;
        height: 29px;
        background: url('./assets/img/css_sprites.png') -10px -10px no-repeat;" class=" d-block m-auto"></div>
      </div>
    </div>
    <div>
      <div class="row mx-0 justify-content-end" *ngIf="!mahindraFlag">
        <a *ngIf="!thirdPartyApp" href="/mobile-app" target="_blank" class="col-auto user_login px-0">
          <!-- <div class="bg-enquiry m-auto"></div> -->
          <div style="width: 43px;
          height: 30px;
          background: url(./assets/img/css_sprites.png) -10px -67px;" class=" m-auto"></div>
          <span [class]="{'enquiry': true, 'enquiry-login': (userMenu && mobileMode)}">Download App</span>
        </a>
        <a href="https://www.b2bcab.in/" *ngIf="!thirdPartyApp && !mobileMode" target="_blank"
          class="col-auto user_login pl-2 pr-0">
          <div style="width: 30px; height: 30px;
          background: url('./assets/img/css_sprites.png') -164px -59px;" class="m-auto"></div>
          <span class="sign-in">Travel Agent?</span>
        </a>
        <a *ngIf="!mobileMode" href="https://www.savaari.com/blog/" target="_blank" class="col-auto pl-2 pr-0"
          style="text-decoration:none">
          <div style="width: 40px;
          height: 30px;
          background: url(./assets/img/css_sprites.png) -55px -67px;" class="m-auto"></div>
          <span class="savaari-blog-text">Savaari Blog</span>
        </a>
        <span (click)='openOtpDialog()' *ngIf="userLogin && !thirdPartyApp"
          class="col-auto user_login pl-1 pl-md-2 pr-0" style="cursor: pointer;">
          <div style="width: 30px; height: 30px;
           background: url('./assets/img/css_sprites.png') -114px -59px;" class="m-auto"></div>
          <span class="sign-in">Login</span>
        </span>
        <div (click)='toggle()' *ngIf="userMenu && !ixigoFlag" class="col-auto user_login pl-1 pl-md-2 pr-0">
          <div style="width: 30px; height: 30px;
           background: url('./assets/img/css_sprites.png') -114px -59px;" class="m-auto"></div>
          <span class="sign-in username">Hi, {{username }}</span>
        </div>
        <div *ngIf="ixigoFlag && !(ixigoColor == '#1556BA' && homepage)"
          style=" border-radius: 50%; width: 53px; height: 53px;"
          [ngStyle]="{'background': ixigoFlag ? ixigoColor : ''}" (click)="goToIxigo()">
          <div class="mx-auto" style="width: 24px;height: 15px; margin-top: 12px;
          background: url(seo-icons.svg) no-repeat;
          background-position: -80px -28px;"></div>
          <div class="text-center" style="font-size: 11px;color: white;">Back</div>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- End of Header -->

<div class="position-relative" *ngIf="showBanner">
  <div *ngIf="!mobileMode" class="position-absolute w-100" style="z-index: 2;">
    <img src="/assets/img/homepage/june-banners_desktop_without-text.webp" width="100%" height="240"
      alt="Savaari main Banner" [ngStyle]="{'margin-top': SeoFlag ? '30px' : '0px'}">


    <ng-container *ngIf="isLoading">
      <ng-container *ngIf="bannerOverHeadingFlag; else loading">
        <div class="text-overlay">
          <h1>{{ bannerOverHeading }}</h1>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #loading>
      <div class="text-overlay">
        <h4>Travel Begins with Savaari</h4>
      </div>
    </ng-template>


  </div>
  <div *ngIf="mobileMode" class="position-absolute w-100" style="z-index: 2;">
    <img *ngIf="!ixigoFlag" src="assets/img/homepage/june-banners_mobile_without-text.webp" width="100%" height="144"
      alt="Savaari Main Banner" [ngStyle]="{'margin-top': SeoFlag ? '30px' : '0px'}">
    <img *ngIf="ixigoFlag && ixigoColor == '#EC5B24'" src="assets/img/homepage/ixigo-banners_mobile_flight.webp"
      width="100%" height="144" alt="Savaari Main Banner" [ngStyle]="{'margin-top': SeoFlag ? '30px' : '0px'}">
    <img *ngIf="ixigoFlag && ixigoColor == '#1556BA'" src="assets/img/homepage/ixigo-banners_mobile_train.webp"
      width="100%" height="144" alt="Savaari Main Banner" [ngStyle]="{'margin-top': SeoFlag ? '30px' : '0px'}">

    <ng-container *ngIf="isLoading">
      <ng-container *ngIf="bannerOverHeadingFlag; else loading">
        <div class="text-overlay">
          <h1>{{ bannerOverHeading }}</h1>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #loading>
      <div class="text-overlay">
        <h4>Travel Begins with Savaari</h4>
      </div>
    </ng-template>

  </div>
</div>

<!-- <div class="savaariLoaderHolder" *ngIf="seoContentLoader">
  <div class="savaariLoder"></div>
</div> -->
<!-- <div class="loginLayer" *ngIf="showUserManagementContainer"></div>
<div class="loginContentHolder" *ngIf="showUserManagementContainer">
  <div class="loginContent">
    <div class="headLayer">
      <div class="m-0 float-left" style="font-weight: 500;" *ngIf="loginSavaari || resetPassword">Login</div>
      <div class="m-0 float-left" style="font-weight: 500;" *ngIf="registerSavaari">Register</div>
      <div class="float-right cursor-pointer" (click)='hideLogin()'>
        <span aria-hidden="true">&times;</span>
      </div>
    </div>
    <div class="mainContentBlock" *ngIf="loginSavaari">
      <form [formGroup]="loginForm">
        <input type="email" placeholder="Email" style="border-radius: 8px;box-shadow: 0 4px 8px #01010133;" required class="form-control margin-top-10px"
          formControlName="userEmail" />
        <div class="text-danger"
          *ngIf="loginFormControl['userEmail'].status == 'INVALID' && (loginFormControl['userEmail'].dirty || loginFormControl['userEmail'].touched)">
          <small *ngIf="loginFormControl['userEmail'].errors?.required">
            Please enter your email address.
          </small>
          <small *ngIf="loginFormControl['userEmail'].errors?.pattern">
            Please enter valid email address.
          </small>
        </div>
        <input type="password" placeholder="Password" style="border-radius: 8px;box-shadow: 0 4px 8px #01010133;" class="form-control margin-top-20px" required
          formControlName="userPassword" />
        <div class="text-danger"
          *ngIf="loginFormControl['userPassword'].status == 'INVALID' && (loginFormControl['userPassword'].dirty || loginFormControl['userPassword'].touched)">
          <small *ngIf="loginFormControl['userPassword'].errors?.required">
            Please enter your password.
          </small>
          <small *ngIf="loginFormControl['userPassword'].errors?.minlength">
            Please enter atleast 6 characters.
          </small>
        </div>
        <div class=" margin-top-10px float-left w-100">
          <a (click)="resetPasswordBlock()" class="float-right forgot-password">
            Forgot Password?
          </a>
        </div>
        <div class="float-left w-100">
          <button type="button" class="theme-button margin-left-5px" [disabled]="!loginForm.valid"
            (click)="loginUser()" style="border-radius: 6px;">
            <div class=" hori-vertical-center">
              <span class="">LOGIN</span>
              <div class="padding-left-10px progressLoaderHolder" *ngIf="loginstatus">
                <div class="savaariLoder progressLoader"></div>
              </div>
            </div>
          </button>
          <button type="button" class="theme-button-register" (click)="registerSavaariBlock()" style="border-radius: 6px;">REGISTER</button>
        </div>
        <div [class]="{'text-right': true, 'errorLog': !successLog, 'validation-success': successLog}" class="errorLog">
          <div *ngIf="errorLog">{{errorMessage}}</div>
        </div>
      </form>

      <div class="progressLayer" *ngIf="loginstatus"></div>
    </div>



    <div class="mainContentBlock" *ngIf="resetPassword">
      <form [formGroup]="forgotPasswordForm">
        <input type="email" placeholder="Email" style="border-radius: 8px;box-shadow: 0 4px 8px #01010133;" class="form-control margin-top-10px" required
          formControlName="userEmail" />
        <div class="text-danger"
          *ngIf="forgotPasswordFormControl['userEmail'].status == 'INVALID' && (forgotPasswordFormControl['userEmail'].dirty || forgotPasswordFormControl['userEmail'].touched)">
          <small *ngIf="forgotPasswordFormControl['userEmail'].errors?.required">
            Please enter your email address.
          </small>
          <small *ngIf="forgotPasswordFormControl['userEmail'].errors?.pattern">
            Please enter valid email address.
          </small>
        </div>
        <div class="float-left w-100">
          <button type="button" class="theme-button margin-left-5px forgot-password-button" style="border-radius: 6px;" (click)="forgotPassword()"
            [disabled]="!forgotPasswordForm.valid">
            <div class=" hori-vertical-center">
              <span class="">RESET PASSWORD</span>
              <div class="padding-left-10px progressLoaderHolder" *ngIf="forgotPasswordStatus">
                <div class="savaariLoder progressLoader"></div>
              </div>
            </div>
          </button>
          <button type="button" class="theme-button-register" style="border-radius: 6px;" (click)="cancel()">CANCEL</button>
        </div>
        <div class="errorLog">
          <div *ngIf="forgotPasswordErrorLog">{{forgotPasswordErrorMessage}}</div>
        </div>
      </form>
    </div>


    <div class="mainContentBlock" *ngIf="registerSavaari">
      <form [formGroup]="registrationForm">
        <input type="text" placeholder="Name" class="form-control margin-top-10px" style="border-radius: 8px;box-shadow: 0 4px 8px #01010133;" formControlName="userName"
          required />
        <div class="text-danger"
          *ngIf="registerFormControl['userName'].status == 'INVALID' && (registerFormControl['userName'].dirty || registerFormControl['userName'].touched)">
          <small *ngIf="registerFormControl['userName'].errors?.required">
            Please enter your name.
          </small>
        </div>


        <div class="input-group margin-top-20px">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1" style="border-radius: 8px;box-shadow: 0 4px 8px #01010133; font-size: 12px;">+91</span>
          </div>
          <input type="number" placeholder="Enter your mobile number" style="border-radius: 8px;box-shadow: 0 4px 8px #01010133;" class="form-control" required
            formControlName="userPhone" maxlength="10" aria-describedby="basic-addon1" />
        </div>
        <div class="text-danger"
          *ngIf="registerFormControl['userPhone'].status == 'INVALID' && (registerFormControl['userPhone'].dirty || registerFormControl['userPhone'].touched)">
          <small *ngIf="registerFormControl['userPhone'].errors?.required">
            Plesae enter your mobile number.
          </small>
          <small *ngIf="registerFormControl['userPhone'].errors?.pattern">
            Please enter valid mobile number.
          </small>
        </div>

        <input type="email" placeholder="Email" style="border-radius: 8px;box-shadow: 0 4px 8px #01010133;" class="form-control margin-top-20px" formControlName="userEmail"
          required />
        <div class="text-danger"
          *ngIf="registerFormControl['userEmail'].status == 'INVALID' && (registerFormControl['userEmail'].dirty || registerFormControl['userEmail'].touched)">
          <small *ngIf="registerFormControl['userEmail'].errors?.required">
            Please enter your email address.
          </small>
          <small *ngIf="registerFormControl['userEmail'].errors?.pattern">
            Please enter valid email address.
          </small>
        </div>

        <input type="password" placeholder="Password" style="border-radius: 8px;box-shadow: 0 4px 8px #01010133;" class="form-control margin-top-20px" required
          formControlName="userPassword" />
        <div class="text-danger"
          *ngIf="registerFormControl['userPassword'].status == 'INVALID' && (registerFormControl['userPassword'].dirty || registerFormControl['userPassword'].touched)">
          <small *ngIf="registerFormControl['userPassword'].errors?.required">
            Please enter your password.
          </small>
          <small *ngIf="registerFormControl['userPassword'].errors?.minlength">
            Please enter atleast 6 characters.
          </small>
        </div>

        <input type="password" placeholder="Re-enter Password" style="border-radius: 8px;box-shadow: 0 4px 8px #01010133;" class="form-control margin-top-20px" required
          formControlName="retypePassword" />
        <div class="text-danger"
          *ngIf="registerFormControl['retypePassword'].status == 'INVALID' && (registerFormControl['retypePassword'].dirty || registerFormControl['retypePassword'].touched)">
          <small *ngIf="registerFormControl['retypePassword'].errors?.required">
            Please retype your password.
          </small>
        </div>
        <div class="text-danger"
          *ngIf="registerFormControl['retypePassword'].status == 'VALID' && (!registrationForm.errors?.bothSame && registrationForm.errors != null )">
          <small>
            Password not matching.
          </small>
        </div>
        <div class="float-left w-100">
          <button type="button" class="theme-button margin-left-5px" style="border-radius: 6px;" (click)="registerUsers()"
            [disabled]="!registrationForm.valid">
            <div class=" hori-vertical-center">
              <span class="">REGISTER</span>
              <div class=" padding-left-10px progressLoaderHolder" *ngIf="registerstatus">
                <div class="savaariLoder progressLoader"></div>
              </div>
            </div>
          </button>
          <button type="button" class="theme-button-register" style="border-radius: 6px;" (click)="cancel()">CANCEL</button>
        </div>
        <div class="errorLog">
          <div *ngIf="registerErrorLog">{{registerErrorMessage}}</div>
        </div>
      </form>
      <div class="progressLayer" *ngIf="registerstatus"></div>
    </div>
  </div>
</div> -->