import { Component, OnInit, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { UserManagementService } from '../services/usermanagement.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { SeoContentService } from '../services/seoContent.service';
import { AppConstants } from '../../environments/environment';
import { MessageService } from '../services/message.service';
import { StorageService } from '../services/storage.service';
import { BookingSessionService } from '../services/booking.service';
import { AuthenticationService } from '../services/otp.service';
import { OtpComponent } from '../app-dialog/otp/otp.component';
import { SEOUtils } from '../homepage.utils';
import { filter } from 'rxjs/operators';

interface UrlSegment {
  path: string;
  parameters: {};
}

// Password and reset password match validation
export const passwordMatchValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('userPassword');
  const confirmPassword = control.get('retypePassword');
  return password && confirmPassword && password.value === confirmPassword.value ? null : { bothSame: false };
};

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit,AfterViewInit {

  username = '';
  showUserManagementContainer = false;
  userLogin = true;
  userMenu = false;
  loginSavaari = true;
  resetPassword = false;
  registerSavaari = false;
  mobile_number = 0;
  logo = '/assets/img/logo.png';
  signin = '/assets/img/signin.png';
  visible = false;
  thirdPartyApp = false;
  mobileMode = false;
  showContactUs: boolean = true;
  showBanner = false;
  SeoFlag = false;
  @Output() open: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  loginData: any;
  bannerOverHeading: string = '';
  bannerOverHeadingFlag: boolean = false;
  defaultText: boolean = false;
  urlSegments: UrlSegment[] = [];
  tripType!: string;
  subTripType!: string;
  isLoading = false;
  error404: boolean = false;

  setUserManagementVisibility(visible: boolean) {

    this.showUserManagementContainer = visible;

    /* if (this.showUserManagementContainer) {
      const contanierHolder = <HTMLElement>document.querySelector('.mat-sidenav-content');
      contanierHolder.style.cssText = 'overflow: hidden !important';

    } else {
      const contanierHolder = <HTMLElement>document.querySelector('.mat-sidenav-content');
      contanierHolder.style.cssText = 'overflow: auto !important';

    } */
  }

  /* REGISTRATION FORM DECLARATION AND RELATED VARIABLES */
  // registrationForm = new FormGroup({
  //   userName: new FormControl('', Validators.required),
  //   userISDCode: new FormControl('', Validators.required),
  //   userPhone: new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]),
  //   userEmail: new FormControl('', [Validators.required, Validators.pattern(AppConstants.emailPattern)]),
  //   userPassword: new FormControl('', [Validators.required, Validators.minLength(6)]),
  //   retypePassword: new FormControl('', Validators.required)
  // }, { validators: passwordMatchValidator });
  // registerstatus = false;
  // registerErrorLog = false;
  // registerErrorMessage = AppConstants.commonErrorMessage;

  /* LOGIN FORM DECLARATION AND RELATED VARIABLES */
  // loginForm = new FormGroup({
  //   userEmail: new FormControl('', [Validators.required, Validators.pattern(AppConstants.emailPattern)]),
  //   userPassword: new FormControl('', [Validators.required, Validators.minLength(6)])
  // });
  loginstatus = false;
  errorLog = false;
  successLog = false;
  errorMessage = AppConstants.commonErrorMessage;
  subscription;

  /* FORGOT PASSWORD FORM DECLARATION AND RELATED VARIABLES */
  forgotPasswordForm = new FormGroup({
    userEmail: new FormControl('', [Validators.required, Validators.pattern(AppConstants.emailPattern)])
  });
  forgotPasswordStatus = false;
  forgotPasswordErrorLog = false;
  forgotPasswordErrorMessage: any = false;
  ixigoFlag: any = false
  ixigoColor: any = ''
  homepage: any = true;
  mahindraFlag: any = false;
  mahindraColor: any = '#734b29'
  async ngOnInit() {
    this.authService.loginStatus$.subscribe((data) => {
      this.loginData = data;
      this.loginwithOTP()
    });

    let page = {
      utm_source :"",
      utm_medium :"" ,
      utm_campaign :"" ,      
      utm_keyword :""      
    };
    this.route.queryParams.subscribe(params => {     
      page.utm_source = params['utm_source'] ?  params['utm_source'] : ''; 
      page.utm_medium = params['utm_medium'] ?  params['utm_medium'] : ''; 
      page.utm_campaign = params['utm_campaign'] ?  params['utm_campaign'] : ''; 
      page.utm_keyword = params['utm_keyword'] ?  params['utm_keyword'] : '';  

  });

    let ct_data = {
      page_url :"",
      device_type :"",
      utm_source :page.utm_source,
      utm_medium :page.utm_medium ,
      utm_campaign :page.utm_campaign ,      
      utm_keyword :page.utm_keyword,
      event_date: new Date(),
    };
    let page_data = {
      page_url :"",
      device_type :"",
      utm_source :page.utm_source,
      utm_medium :page.utm_medium ,
      utm_campaign :page.utm_campaign ,      
      utm_keyword :page.utm_keyword,
    };
    
    ct_data.page_url = window.location.pathname; 
    const ctwindowWidth = window.innerWidth;
    if (ctwindowWidth <= 710) {
      ct_data.device_type = 'mobile';
    } else {
      ct_data.device_type = 'desktop';
    }
    page_data.page_url = window.location.pathname; 
    const windowWidth = window.innerWidth;
    if (windowWidth <= 710) {
      page_data.device_type = 'mobile';
    } else {
      page_data.device_type = 'desktop';
    }
    // this.localStorage.save_analytics_data('page-load-pre', page_data);
    this.localStorage.save_CT_data('page-load-pre', ct_data);
    // this.seoContentService.getSeoRevamp()
    // .subscribe(data => {
    //   this.bannerOverHeading = data.banner_over_heading;
    //   if(data)
    //     this.bannerOverHeadingFlag = true;
    // });

    // const urlSegments: any = this.route.parent!.snapshot.url;
    // if (urlSegments.length > 0 && urlSegments.length <= 2) {
    //   const pageDetails = SEOUtils.getSEOPageDetails(urlSegments);
    //   console.log(pageDetails)
    // }

    // this.tripType = this.bookingService.getTripType()

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      this.updateUrlSegments();
    });

    // Initial URL segments extraction
    // this.updateUrlSegments();



    // if (this.localStorage.getItem('NewUIFlag') == '1') {
    //   this.bannerOverHeadingFlag = true;
    //   this.bannerOverHeading = this.localStorage.getItem('bannerOverHeading');
    // }

    if (this.localStorage.checkPlatform()) {
      if (window.innerWidth <= 710) {
        this.mobileMode = true;
      }
    }
    if (this.localStorage.getItem('ixigoFlag') == '1') {
      this.ixigoFlag = true;
      this.thirdPartyApp = true;
      this.ixigoColor = this.localStorage.getItem('ixigoColor');

      if (this.ixigoColor == '#EC5B24')
        this.logo = "./assets/ixigo_flights_logo_v2.png"
      if (this.ixigoColor == '#1556BA')
        this.logo = "./assets/ixigo_trains_logo_v2.png"
    }
    if (this.localStorage.getItem('mahindraFlag') == '1') {
      this.mahindraFlag = true;
    }

    if ((this.localStorage.getItem('InAppSource') === 'PhonePe' && this.localStorage.getItem('PhonePeUserDetails') === '') ||
      (this.localStorage.getItem('InAppSourcePaytm') === 'Paytm' && this.localStorage.getItem('PaytmUserDetails') === '')) {
      this.userLogin = false;
    }

    if (this.localStorage.getItem('InAppSource') === 'PhonePe' || this.localStorage.getItem('InAppSourcePaytm') === 'Paytm') {
      this.thirdPartyApp = true;
    }

    if ((this.localStorage.getItem('InAppSource') == 'PhonePe' || this.localStorage.getItem('InAppSourcePaytm') == 'Paytm') && this.localStorage.getItem('OnlyAirportTab') == 'OnlyAirportTab') {
      this.logo = "./assets/img/savaari-airport-cabs-logo.png"
    }

    try {
      /* if(this.localStorage.getItem('PhonePeLoginFlag') === null && this.localStorage.getItem('InAppSource') === 'PhonePe' && this.localStorage.getItem('PhonePeUserDetails') === ''){
        this.localStorage.setItem('PhonePeLoginFlag', 1);
        this.setUserManagementVisibility(true);        
      } */
      await this.usermanagement.autologin()
      this.message.sendMessage("user");

    } catch (error) {
      console.log(error);
    }

  }

  private async updateUrlSegments(): Promise<void> {
    const newUrlSegments: UrlSegment[] = [];
    let route = this.route.root;

    while (route.firstChild) {
      route = route.firstChild;
      newUrlSegments.push(...route.snapshot.url.map(segment => ({
        path: segment.path,
        parameters: {}
      })));
    }

    // setTimeout(() => {
    //   this.isLoading = true; 
    // }, 1000);

    // if (newUrlSegments.length > 0 && JSON.stringify(newUrlSegments) !== JSON.stringify(this.urlSegments)) {

    this.urlSegments = newUrlSegments;
    console.log('URL Segments updated:', this.urlSegments);

    // if (this.urlSegments.length > 0 && this.urlSegments.length <= 2) {
    const pageDetails = SEOUtils.getSEOPageDetails(this.urlSegments);
    console.log(pageDetails);
    const webURL = window.location.pathname;

    if (pageDetails['tripType'] == 'outstation') {
      this.subTripType = 'oneWay';
    }
    if (pageDetails['tripType'] == "local") {
      this.subTripType = '880';
    }

    if (pageDetails['tripType'] == "airport") {
      this.subTripType = 'pick_airport';
    }

    if (this.subTripType == '') {
      this.subTripType = '880';
    }
    if(pageDetails['seoType'] == 404)
    {
      this.showBanner = false;
      this.error404 = true;
    }
    if(pageDetails['seoType'] != 404)
      this.error404 = false;
    
    try {
      const content = await this.seoContentService.getSeoContent(
        pageDetails['stateName'],
        pageDetails['sourceCity'],
        pageDetails['destinationCity'],
        pageDetails['seoType'],
        pageDetails['cabType'],
        pageDetails['tripType'],
        this.subTripType,
        pageDetails['locality'],
        webURL
      );

      console.log(content);
      this.seoContentService.setContent(content);
      if (content.newUIFlag === 1) {
        this.isLoading = true; 
        this.bannerOverHeadingFlag = true;
        this.bannerOverHeading = content.newUIContent.banner_over_heading;
        this.localStorage.setItem('bannerOverHeading', this.bannerOverHeading);
        this.localStorage.setItem('showBanner', this.isLoading);
      }
      else {
        this.isLoading = true; 
        this.bannerOverHeadingFlag = false;
        this.localStorage.setItem('bannerOverHeading', 'travel begins with savaari');
        this.localStorage.setItem('showBanner', this.isLoading);
      }
    } catch (error) {
      this.isLoading = true; 
      this.bannerOverHeadingFlag = false;
      console.error('Error fetching SEO content:', error);
      this.localStorage.removeItem('bannerOverHeading');
      this.localStorage.setItem('showBanner', this.isLoading);
    }

    // }
    // } 
  }


  defaultState() {
    this.username = '';
    this.userLogin = true;
    this.userMenu = false;
    this.loginstatus = false;
    this.errorLog = false;
    this.errorMessage = AppConstants.commonErrorMessage;
    // this.forgotPasswordStatus = false;
    // this.forgotPasswordErrorLog = false;
    // this.forgotPasswordErrorMessage = false;
    // this.registerstatus = false;
    // this.registerErrorLog = false;
    // this.registerErrorMessage = AppConstants.commonErrorMessage;
  }

  constructor(private usermanagement: UserManagementService,
    private router: Router,
    private message: MessageService,
    private localStorage: StorageService,
    private bookingService: BookingSessionService,
    private authService: AuthenticationService,
    private modifiyDialog: MatDialog,
    private seoContentService: SeoContentService,
    private route: ActivatedRoute,

  ) {
    this.authService.emailLogin$.subscribe((data) => {
      this.loginData = data;
      this.showLogin()
    });

    router.events.subscribe(() => {
      this.mobile_number = this.localStorage.getItem('mobile_number_enabled');
      console.log(this.mobile_number);
      if ((/select_cars/i).test(window.location.pathname) || (/booking/i).test(window.location.pathname) || (/confirmation/i).test(window.location.pathname)) {
        this.homepage = false;
      } else {
        this.homepage = true;
      }
    });

    this.subscription = this.message.getMessage().subscribe(response => {

      if (response && response.res && response.res == "user") {

        try {

          const loggedUserDetail = JSON.parse(this.localStorage.getItem('loggedUserDetail'));
          // this.username = loggedUserDetail.firstname;
          // this.userLogin = (this.username) ? false : true;
          // this.userMenu = (this.username) ? true : false;

          this.username = (loggedUserDetail.firstname) ? loggedUserDetail.firstname : loggedUserDetail.mobileno;
          this.userLogin = (this.username) ? false : true;
          this.userMenu = (this.username) ? true : false;


        } catch (error) {

          this.defaultState()

        }

      } else if (response && response.res && response.res == "close-menu") {
        this.visible = !this.visible;
      }

    });
  }
  ngAfterViewInit(){
    if (this.localStorage.getItem('showBanner') === 'true') {
      setTimeout(() => {
        this.isLoading = true; 
        if(this.localStorage.getItem('bannerOverHeading')) {
          this.bannerOverHeadingFlag = true;
          this.bannerOverHeading = this.localStorage.getItem('bannerOverHeading');
        }
      }, 1000);
    }
  }

  // get loginFormControl() {
  //   return this.loginForm.controls;
  // }

  // get forgotPasswordFormControl() {
  //   return this.forgotPasswordForm.controls;
  // }

  // get registerFormControl() {
  //   return this.registrationForm.controls;
  // }

  toggle() {
    this.visible = !this.visible;
    if (this.visible) {
      this.open.emit(null);
    } else {
      this.close.emit(null);
    }
  }

  triptypedata() {
    this.hideMenu('hide');

  }

  hideMenu(performAction: string) {
    if (performAction == 'hide' && this.visible) {
      this.toggle();
    }

  }
  // Show login popup
  showLogin() {
    this.setUserManagementVisibility(true)

  }

  openOtpDialog() {
    const dialogRef = this.modifiyDialog.open(OtpComponent, {
      disableClose: true,
      height: '14rem',
      width: '32rem',
      panelClass: 'custom-dialog-container',
      data: 'modify'
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle the result from the OTP component if needed
      let otpDetails = JSON.parse(this.localStorage.getItem("loggedUserDetail"));

      let page_data = {
        event_date: new Date(),
        mobile_no: otpDetails?.mobileno,
        otp_verified: true
      }
      console.log('Dialog result:', result);
      if (result == 1) {
        setTimeout(() => {
          // let last_event = this.localStorage.getItem('all-info');
          // console.log(last_event);
          // if (last_event != '') {
          //   page_data = Object.assign(JSON.parse(last_event), page_data)
          //   this.localStorage.setItem('all-info', JSON.stringify(page_data))
          // }
          this.localStorage.save_CT_data('verify-otp', page_data);
        }, 2000);
      }
      else if (result == 2) {
        alert("Please enter correct OTP");
      }
    });
  }

  // Hide login popup
  // hideLogin() {
  //   this.setUserManagementVisibility(false)
  //   this.loginSavaari = true;
  //   this.resetPassword = false;
  //   this.registerSavaari = false;
  //   this.errorLog = false;
  //   this.registerErrorLog = false;
  //   this.forgotPasswordErrorLog = false;
  //   this.registrationForm.reset();
  //   this.loginForm.reset();
  //   this.forgotPasswordForm.reset();
  // }

  // Show reset password block
  // resetPasswordBlock() {
  //   this.loginSavaari = false;
  //   this.resetPassword = true;
  //   this.registerSavaari = false;
  // }

  // Show registeration form block
  // registerSavaariBlock() {
  //   this.loginSavaari = false;
  //   this.resetPassword = false;
  //   this.registerSavaari = true;
  // }

  // Cancel registeration form block and go back to login block
  // cancel() {
  //   this.loginSavaari = true;
  //   this.resetPassword = false;
  //   this.registerSavaari = false;
  //   this.registrationForm.reset();
  // }

  // Validate and register new user
  // async registerUsers() {
  //   this.errorLog = false;
  //   this.registerErrorLog = false;
  //   this.registerstatus = true;

  //   try {
  //     let host = '';
  //     if (this.localStorage.checkPlatform()) {
  //       host = window.location.host;
  //     }
  //     const routePath = this.router.url.split('/')[1];
  //     const referrer = host + '/' + routePath;
  //     await this.usermanagement.registerUser(this.registrationForm.value, referrer)
  //     await this.loginToSavaari(this.registrationForm.value);

  //     this.setUserManagementVisibility(false)
  //     this.loginSavaari = true;
  //     this.resetPassword = false;
  //     this.registerSavaari = false;
  //     this.errorLog = false;
  //     this.registerErrorLog = false;
  //     this.forgotPasswordErrorLog = false;
  //     this.registrationForm.reset();
  //     this.loginForm.reset();
  //     this.forgotPasswordForm.reset();
  //   } catch (error: any) {

  //     if (error.message) {
  //       this.registerErrorMessage = error.message;

  //     } else {
  //       this.registerErrorMessage = AppConstants.commonErrorMessage;
  //     }
  //     this.registerErrorLog = true;
  //     this.registerstatus = false;
  //   }
  // }

  // Validate and login the user
  // loginUser() {
  //   this.loginstatus = true;
  //   this.loginToSavaari(this.loginForm.value)
  // }

  async loginwithOTP() {
    try {
      // await this.usermanagement.autologin()
      this.loginstatus = true;
      this.userLogin = false;
      this.userMenu = true;
      this.loginSavaari = true;
      this.setUserManagementVisibility(false)

      this.message.sendMessage("user");

    } catch (error: any) {

      if (error.message) {
        this.errorMessage = error.message;

      } else {
        this.errorMessage = AppConstants.commonErrorMessage;
      }
      this.errorLog = true;
      this.successLog = false;
      this.loginstatus = false;
    }
  }

  // async loginToSavaari(loginData: any) {
  //   try {

  //     await this.usermanagement.loginUser(loginData.userEmail, loginData.userPassword)
  //     this.userLogin = false;
  //     this.userMenu = true;
  //     this.setUserManagementVisibility(false)

  //     this.message.sendMessage("user");

  //   } catch (error: any) {

  //     if (error.message) {
  //       this.errorMessage = error.message;

  //     } else {
  //       this.errorMessage = AppConstants.commonErrorMessage;
  //     }
  //     this.errorLog = true;
  //     this.successLog = false;
  //     this.loginstatus = false;
  //   }

  // }


  // Validate form and call the forgot password api to send the mail and message
  // async forgotPassword() {
  //   this.forgotPasswordStatus = true;

  //   try {
  //     await this.usermanagement.forgotPassword(this.forgotPasswordForm.controls["userEmail"].value);
  //     this.loginSavaari = true;
  //     this.resetPassword = false;
  //     this.registerSavaari = false;
  //     this.forgotPasswordForm.reset();
  //     this.forgotPasswordStatus = false;
  //     this.errorMessage = 'Password is sent to your email account.';
  //     this.errorLog = true;
  //     this.successLog = true;
  //     this.loginForm.reset();

  //   } catch (error: any) {

  //     if (error.message) {
  //       this.forgotPasswordErrorMessage = error.message;

  //     } else {
  //       this.forgotPasswordErrorMessage = AppConstants.commonErrorMessage;

  //     }
  //     this.forgotPasswordErrorLog = true;
  //     this.forgotPasswordStatus = false;

  //   }

  // }

  ngDoCheck() {
    this.showBanner = false;
    let urlDetails = this.router.url;
    let urlPath = '';
    if (this.localStorage.checkPlatform()) {
      urlPath = window.location.pathname;
      if ((urlPath == '/' || urlPath.includes('ixigo')) && !this.error404) {
        this.showBanner = true;
      }
    }

    console.log(this.showBanner)
    if ((this.bookingService.getTripType() == 'local') && (urlDetails.includes('select_cars') || urlDetails.includes('booking'))) {
      this.showContactUs = true;
    } else {
      this.showContactUs = true;
    }
    let segement = urlPath.split('/')
    console.log(segement.length)
    if (segement.length == 2 && (
      (segement[1].match(/^([a-zA-Z0-9_-]+)-cabs$/) && segement[1] !== 'one-way-cabs') ||
      segement[1] === 'bmw-car-rental' ||
      segement[1] === 'mercedes-car-rental' ||
      segement[1] === 'indigo' ||
      segement[1] === 'airport-taxi' ||
      segement[1] === 'outstation-cabs' ||
      segement[1].match(/^([a-zA-Z0-9_-]+)-airport-to-([a-zA-Z0-9_-]+)$/) ||
      segement[1] === 'local' ||
      segement[1] === 'outstation' ||
      segement[1] === 'car-rentals' || segement[1] === 'char-dham-yatra-car-rental-package'
    ) && !this.error404
    ) {
      this.showBanner = true;
      this.SeoFlag = true;
    } else if (segement.length == 3 && (segement[2] === 'cabs' ||
      segement[2].match(/^tempo-traveller-([a-zA-Z0-9_-]+)$/) ||
      segement[2].match(/^([a-zA-Z0-9_-]+)-cabs$/) ||
      segement[2] === 'wedding-car-rental' ||
      segement[2].match(/^([a-zA-Z0-9_-]+)-taxi-booking$/) ||
      segement[2].match(/^economy-taxi-hire/) ||
      segement[2].match(/^local-([a-zA-Z0-9_-]+)-darshan-cabs/) ||
      segement[2] === 'airport-taxi' ||
      segement[2] === 'tourist-cabs' ||
      segement[2] === 'weekend-cab-packages' ||
      segement[2] === 'pilgrimage-cab-packages' ||
      segement[2] === 'sightseeing-packages' ||
      segement[2] === 'minibus' ||
      segement[2].match(/^cabs-in-([a-zA-Z0-9_-]+)$/) ||
      segement[2].match(/^([a-zA-Z0-9_-]+)-cabs-([a-zA-Z0-9_-]+)$/) ||
      segement[2].match(/^([a-zA-Z0-9_-]+)-to-([a-zA-Z0-9_-]+)$/) ||
      segement[2].match(/^([a-zA-Z0-9_-]+)-airport-to-([a-zA-Z0-9_-]+)$/) ||
      segement[2].match(/^things-to-do-in-([a-zA-Z0-9_-]+)$/) ||
      segement[2].match(/^([a-zA-Z0-9_-]+)-to-([a-zA-Z0-9_-]+)-cabs$/) ||
      segement[2].match(/^([a-zA-Z0-9_-]+)-tour-package$/) ||
      segement[2].match(/^([a-zA-Z0-9_-]+)-to-([a-zA-Z0-9_-]+)-one-way-cabs$/) ||
      segement[2] === 'outstation-cabs' ||
      segement[2] === 'book-taxi' ||
      segement[2] === 'car-rental' ||
      (segement[1] === 'outstation-taxi' && segement[2] === 'car-rentals') ||
      (segement[1] === 'luxury' && segement[2] === 'car-rentals')
    ) && !this.error404
    ) {
      this.showBanner = true;
      this.SeoFlag = true;
    }
    console.log(this.showBanner)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.message.clearMessage();
  }

  /* showDownloadApp() {
    const dialogRef = this.downloadAppDialog.open(DownloadAppComponent);

    dialogRef.afterClosed().subscribe(result => {

    });
  } */

  /* showWriteToUs() {
    const dialogRef = this.downloadAppDialog.open(WriteToUsComponent, {
      'width': '400px'
    });

    dialogRef.afterClosed().subscribe(result => {

    });

  } */

  goToIxigo() {
    try {
      if ((/select_cars/i).test(window.location.pathname) || (/booking/i).test(window.location.pathname)) {
        if ((/confirmation/i).test(window.location.pathname)) {
          if (this.ixigoColor == '#1556BA')
            window.location.href = '/';
          else
            window.location.href = '/ixigo_exit.php';
        }
        else {
          history.back();
        }
      } else {
        window.location.href = '/ixigo_exit.php';
      }
    } catch (error) {
      window.location.href = '/ixigo_exit.php';
    }
  }

}
