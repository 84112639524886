import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { MessageService } from '../services/message.service';
import { Router } from '@angular/router';
import { StorageService } from '../services/storage.service';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  menuItemList = [
    { routerLink: 'booking-details', name: 'Bookings' },
    { routerLink: 'account', name: 'Account Settings' },
    { routerLink: 'logout', name: 'Log Out' }
  ];
  phonePeFlag: any = '';
  paytmFlag: any = '';  


  @Output() close: EventEmitter<any> = new EventEmitter();
  constructor(private message: MessageService, private router: Router,
    private localStorage: StorageService) {

  }

  ngOnInit() {
    this.phonePeFlag = this.localStorage.getItem('InAppSource');
    if (this.phonePeFlag=='PhonePe') {
      this.menuItemList = [
        { routerLink: 'booking-details', name: 'Bookings' }
      ];
    }
    this.paytmFlag = this.localStorage.getItem('InAppSourcePaytm');
    if (this.paytmFlag == 'Paytm') {
      this.menuItemList = [
        { routerLink: 'booking-details', name: 'Bookings' }
      ];
    }
    
    if (this.localStorage.getItem('ixigoFlag') == '1') {
      this.menuItemList = [
        { routerLink: 'booking-details', name: 'Bookings' }
      ];
    }
  }

  // Right menu logout routing functionality
  didSelect(routerlink: string) {
    this.close.emit(null);
    if (routerlink === 'logout') {
      this.localStorage.removeItem('loggedUserDetail');
      this.localStorage.removeItem('loginUserToken');
      this.message.sendMessage("user");
      this.router.navigate(['']);
    }
    this.message.sendMessage('close-menu');

  }

  ngOnDestroy() {
    this.message.clearMessage();
  }
}
