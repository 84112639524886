import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConstants } from '../../environments/environment';
import { StorageService } from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class TokenService {

    private apiPlaygroundUrl: string = AppConstants.apiPlaygroundUrl;       // URL to the API PLAYGROUND    
    private apiKey: string = AppConstants.apiKey;   // API key to fetch the token for API PLAYGROUND
    private appId: string = AppConstants.appId;   // APP Id to fetch the token for API PLAYGROUND
    private tokenPath: string = AppConstants.tokenPath;     // URL to the token fetching in the API PLAYGROUND (of savaari team)
    private token: string;


    constructor(private http: HttpClient, private localStorage: StorageService) {
        this.token = this.localStorage.getItem('SavaariToken');
    }

    public removeToken() {
        this.localStorage.removeItem('SavaariToken');
        this.token = '';
    }

    // Get 'token from api playground'
    async getToken(): Promise<string> {
        if (this.token == null || this.token == '') {
            try {
                const url = this.apiPlaygroundUrl + this.tokenPath
                const params: HttpParams = new HttpParams({
                    fromObject: {
                        'apiKey': this.apiKey,
                        'appId': this.appId
                    }
                });

                const res: any = await this.http.get(url, { params: params }).toPromise();
                const body = res;
                this.token = body.data.token;
                this.localStorage.setItem('SavaariToken', this.token);
            }
            catch (error) {
            }
        }
        return this.token

    }
}
