
export enum SEOTypes {
    CityCarRentals = 1, /* outstation rental for one full day http://www.savaari.com/bangalore/car-rental */
    CityBookTaxi = 2, /* Local rental for half day http://www.savaari.com/bangalore/book-taxi*/
    CityOutstation = 3, /* Rental for outstation from a city http://www.savaari.com/bangalore/outstation-cabs*/
    SourceToDestinationCabs = 4, /* Rental for particular outstation from a city http://www.savaari.com/bangalore/bangalore-to-mysore-cabs*/
    LocalityAirportTaxi = 5, /* Rental for airport from local area http://www.savaari.com/bangalore/vijayanagar-to-airport-taxi*/
    LocalityCabs = 6, /* Rental for one local area within a city http://www.savaari.com/bangalore/vijayanagar-cabs*/
    AirportTaxi = 7, /* Rental for airport pick up and drop http://www.savaari.com/bangalore/airport-taxi*/
    TourPackages = 8, /* Tour packages for city http://www.savaari.com/bangalore/tour-packages*/
    AirportCarTypeCab = 9, /* http://www.savaari.com/bangalore/airport-indica-cabs */
    CarTypeCabs = 10, /* Rental for specific cab types like 'indica' within a city http://www.savaari.com/bangalore/indica-cabs-bangalore*/
    AirportToOtherCities = 11, /* Rental for other cities from airpost of a city http://www.savaari.com/bangalore/bangalore-airport-to-mysore*/
    CarTypeTaxiBooking = 12, /* Rental for specific cab types like 'indica' within a city http://www.savaari.com/bangalore/indica-taxi-booking*/
    OnewayCabs = 13,
    TouristCabs = 14,
    EconomyTaxi = 15,
    PilgrimageTaxi = 16,
    Sightseeing = 17,
    Weekend = 18,
    CabsInCity = 19, /* http://www.savaari.com/bangalore/cabs-in-bangalore */
    LocalSourceDarshanCabs = 20, /*https://www.savaari.com/mysore/local-mysore-darshan-cabs */
    SourceToDestination = 21,    /* http://www.savaari.com/bangalore/bangalore-to-mysore */
    StateCabs = 22,             /*  https://www.savaari.com/karnataka-cabs  */
    TravelGuide = 23,            /* www.savaari.com/bangalore/travel-guid  */
    TempoTraveller = 24,         /* www.savaari.com/bangalore/tempo-traveller-bangalore  */
    ResortCabs = 25,             /* https://www.savaari.com/resort-name/cabs */
    WeddingCabs = 26, 	/* https://www.savaari.com/city_name/wedding-car-rental */
    TourpackageCabs = 27, 	/* https://www.savaari.com/<from_city>/<from_city>-to-<to_city>-tour-package */
    CorporateCarRentalAgency = 28, /* https://savaari.com/corporate-car-rental */
    miniBus = 29, /* https://savaari.com//bangalore/minibus */
  
    PageNotFound = 404,      /* https://www.savaari.com/djfkjdfjdjdfj */
    OutStation = 500,
    Local = 501,
    OutStationCarRentals = 502,
    LocalCarRentals = 503,
    FullDayCabs = 600,
    HalfDayCabs = 601,
    PartnerBooking = 602,   /* https://www.savaari.com/indigo */
    CityCabType = 603,      /* https://www.savaari.com/city-cab-type */
    TouristCabsPage = 604,   /* https://www.savaari.com/tourist-cabs */
    DarshanCabsPage = 605,   /* https://www.savaari.com/darshan-cabs */
    AirportToOtherCitiesPage = 606, /*https://www.savaari.com/cityairport_to_othercity  */
    CabsTypesPage = 607,         /* https://www.savaari.com/cab-types  */
    LocalAreaCabsPage = 608,     /* https://www.savaari.com/local-area-cabs   */
    luxuryCarRental = 54,    /* https://www.savaari.com/luxury-car-rental  */
    luxuryCarRentalMercedes = 56,     /* https://www.savaari.com/mercedes-car-rental  */
    luxuryCarRentalBMW = 57,     /* https://www.savaari.com/bmw-car-rental  */
    CharDham = 58
  
  }


  
export class SEOUtils {

    public static getSEOPageDetails(urlSegments: string | any[]) {
        const pageDetails:any = {};
        let tripType = 'outstation';
        let seoType = SEOTypes.OutStation;
        let sourceCity = null;
        let destinationCity = null;
        let cabType = null;
        let locality = null;
        let stateName = null;
  
  
        if (urlSegments.length === 1) {
            const firstComponant = urlSegments[0]['path'];
            if (firstComponant === 'outstation') {
                tripType = 'outstation';
                seoType = SEOTypes.OutStation;
            } else if (firstComponant === 'local') {
                tripType = 'local';
                seoType = SEOTypes.Local;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)-airport-to-([a-zA-Z0-9_-]+)$/)) {
                // source-airport-to-destination
                tripType = 'outstation';
                seoType = SEOTypes.AirportToOtherCities;
                const componants = firstComponant.split('-airport-to-');
                sourceCity = componants[0];
                destinationCity = componants[1];
            } else if (firstComponant == 'book-taxi') {
                tripType = '';
                seoType = SEOTypes.CityBookTaxi;
            } else if (firstComponant == 'outstation-cabs') {
                tripType = '';
                seoType = SEOTypes.CityOutstation;
            } else if (firstComponant == 'car-rental') {
                tripType = '';
                seoType = SEOTypes.CityCarRentals;
            } else if (firstComponant == 'airport-taxi') {
                tripType = 'airport';
                seoType = SEOTypes.AirportTaxi;
            } else if (firstComponant == 'full-day-cabs') {
                tripType = '';
                seoType = SEOTypes.FullDayCabs;
            } else if (firstComponant == 'half-day-cabs') {
                tripType = '';
                seoType = SEOTypes.HalfDayCabs;
            } else if (firstComponant == 'indigo') {
                tripType = 'local';
                seoType = SEOTypes.PartnerBooking;
            } else if (firstComponant == 'city-cab-type') {
                tripType = '';
                seoType = SEOTypes.CityCabType;
            } else if (firstComponant == 'tourist-cabs') {
                tripType = '';
                seoType = SEOTypes.TouristCabsPage;
            } else if (firstComponant == 'darshan-cabs') {
                tripType = '';
                seoType = SEOTypes.DarshanCabsPage;
            } else if (firstComponant == 'cityairport-to-othercity') {
                tripType = '';
                seoType = SEOTypes.AirportToOtherCitiesPage; 
            } else if (firstComponant == 'cab-types'){
                tripType = '';
                seoType = SEOTypes.CabsTypesPage; 
            } else if (firstComponant == 'local-area-cabs') {
                tripType = '';
                seoType = SEOTypes.LocalAreaCabsPage;
            } else if (firstComponant == 'corporate-car-rental') {
                tripType = 'outstation';
                seoType = SEOTypes.CorporateCarRentalAgency;
            } else if (firstComponant == 'mercedes-car-rental') {
              tripType = 'outstation';
              seoType = SEOTypes.luxuryCarRentalMercedes;
            } else if (firstComponant == 'bmw-car-rental') {
              tripType = 'outstation';
              seoType = SEOTypes.luxuryCarRentalBMW;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)-cabs$/)) {
                const componants = firstComponant.split('-cabs');
                stateName = componants[0];
                tripType = 'outstation';
                seoType = SEOTypes.StateCabs;
            } else if (firstComponant === 'car-rentals') {
                tripType = 'local';
                seoType = SEOTypes.LocalCarRentals;
            } else if (firstComponant === 'char-dham-yatra-car-rental-package') {
                tripType = 'outstation';
                seoType = SEOTypes.CharDham;
            } else{
                tripType = 'outstation';
                seoType = SEOTypes.PageNotFound;
            }
        } else if (urlSegments.length === 2) {
            const firstComponant = urlSegments[0]['path'];
            const secondComponant = urlSegments[1]['path'];
  
            if (firstComponant === 'outstation-taxi' && secondComponant === 'car-rentals') {
                tripType = 'outstation';
                seoType = SEOTypes.OutStationCarRentals;
            }else if (firstComponant == 'luxury' && secondComponant === 'car-rental') {
              tripType = 'outstation';
              seoType = SEOTypes.luxuryCarRental;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant === 'car-rental') {
                // source/car-rental
                tripType = 'outstation';
                seoType = SEOTypes.CityCarRentals;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant === 'book-taxi') {
                // source/book-taxi
                tripType = 'outstation';
                seoType = SEOTypes.CityBookTaxi;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant === 'minibus') {
                // source/minibus
                tripType = 'outstation';
                seoType = SEOTypes.miniBus;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant === 'outstation-cabs') {
                // source/outstation-cabs
                tripType = 'outstation';
                seoType = SEOTypes.CityOutstation;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^([a-zA-Z0-9_-]+)-to-([a-zA-Z0-9_-]+)-one-way-cabs$/)) {
                // source/source-to-destination-one-way-cabs
                tripType = 'outstation';
                seoType = SEOTypes.OnewayCabs;
                sourceCity = firstComponant;
                destinationCity = secondComponant.split('-to-')[1].split('-one-way-cabs')[0];
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^([a-zA-Z0-9_-]+)-tour-package$/)) {
                // <from_city>/<from_city>-to-<to_city>-tour-package
                tripType = 'outstation';
                seoType = SEOTypes.TourpackageCabs;
                sourceCity = firstComponant;
                destinationCity = secondComponant.split('-to-')[1].split('-tour-package')[0];
                
            }  else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^([a-zA-Z0-9_-]+)-to-([a-zA-Z0-9_-]+)-cabs$/)) {
                // source/source-to-destination-cabs
                tripType = 'outstation';
                seoType = SEOTypes.SourceToDestinationCabs;
                sourceCity = firstComponant;
                destinationCity = secondComponant.split('-to-')[1].split('-cabs')[0];
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^things-to-do-in-([a-zA-Z0-9_-]+)$/)) {
                // bangalore/travel-guide
                tripType = 'outstation';
                seoType = SEOTypes.TravelGuide;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^([a-zA-Z0-9_-]+)-airport-to-([a-zA-Z0-9_-]+)$/)) {
                // source/source-airport-to-destination
                tripType = 'outstation';
                seoType = SEOTypes.AirportToOtherCities;
                sourceCity = firstComponant;
                destinationCity = secondComponant.split('-airport-to-')[1];
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^([a-zA-Z0-9_-]+)-to-([a-zA-Z0-9_-]+)$/)) {
                // source/source-to-destination
                tripType = 'outstation';
                seoType = SEOTypes.SourceToDestination;
                sourceCity = firstComponant;
                destinationCity = secondComponant.split('-to-')[1];
  
            }
            else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^([a-zA-Z0-9_-]+)-cabs-([a-zA-Z0-9_-]+)$/)) {
                // source/innova-cabs-source
                tripType = 'outstation';
                seoType = SEOTypes.CarTypeCabs;
                sourceCity = firstComponant;
                cabType = secondComponant.split('-cabs-')[0];
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^cabs-in-([a-zA-Z0-9_-]+)$/)) {
                // source/cabs-in-source
                tripType = 'outstation';
                seoType = SEOTypes.CabsInCity;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant === 'sightseeing-packages') {
                // source/sightseeing-packages
                tripType = 'local';
                seoType = SEOTypes.Sightseeing;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant === 'pilgrimage-cab-packages') {
                // source/pilgrimage-cab-packages
                tripType = 'local';
                seoType = SEOTypes.PilgrimageTaxi;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant === 'weekend-cab-packages') {
                // source/weekend-cab-packages
                tripType = 'local';
                seoType = SEOTypes.Weekend;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant === 'tourist-cabs') {
                // destination/tourist-cabs
                tripType = 'local';
                seoType = SEOTypes.TouristCabs;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant === 'airport-taxi') {
                // source/airport-taxi
                tripType = 'airport';
                seoType = SEOTypes.AirportTaxi;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^local-([a-zA-Z0-9_-]+)-darshan-cabs/)) {
                // source/local-source-darshan-cabs
                tripType = 'local';
                seoType = SEOTypes.LocalSourceDarshanCabs;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^economy-taxi-hire/)) {
                // source/economy-taxi-hire
                tripType = 'local';
                seoType = SEOTypes.EconomyTaxi;
                sourceCity = firstComponant;
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^([a-zA-Z0-9_-]+)-taxi-booking$/)) {
                // carname/carname-taxi-booking
                tripType = 'outstation';
                seoType = SEOTypes.CarTypeTaxiBooking;
                cabType = secondComponant.split('-taxi-')[0];
                sourceCity = 'bangalore';
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant === 'wedding-car-rental') {
                // <from_city>/wedding-car-rental
                tripType = 'local';
                seoType = SEOTypes.WeddingCabs;
                sourceCity = firstComponant;
            }else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^([a-zA-Z0-9_-]+)-cabs$/)) {
                // bangalore/domlur-cabs
                tripType = 'outstation';
                seoType = SEOTypes.LocalityCabs;
                sourceCity = firstComponant;
                locality = secondComponant.split('-cabs')[0];
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant.match(/^tempo-traveller-([a-zA-Z0-9_-]+)$/)) {
                // source/tempo-travelle-in-source
                tripType = 'outstation';
                seoType = SEOTypes.TempoTraveller;
                sourceCity = firstComponant;
                cabType = 'tempo-traveller';
            } else if (firstComponant.match(/^([a-zA-Z0-9_-]+)$/) && secondComponant === 'cabs') {
                // resort-name/cabs
                tripType = 'outstation';
                seoType = SEOTypes.ResortCabs;
                sourceCity = firstComponant;
            }else {
                tripType = 'outstation';
                seoType = SEOTypes.PageNotFound;
            }
  
        }
       
        pageDetails['tripType'] = tripType;
        pageDetails['seoType'] = seoType;
  
        if (sourceCity != null) {
            pageDetails['sourceCity'] = sourceCity;
        }
  
        if (destinationCity != null) {
            pageDetails['destinationCity'] = destinationCity;
        }
  
        if (cabType != null) {
            pageDetails['cabType'] = cabType;
        }
  
        if (locality != null) {
            pageDetails['locality'] = locality;
        }
  
        if (stateName != null){
            pageDetails['stateName'] = stateName;
        }
  
        return pageDetails;
    }
  
  }

  export class cittocityStaticContent {
    public static bannerImage = 'savaari-default.jpg';
    public static bannerImagePath = './assets/cityToCity/bannerImage/';
    public static desktopBannerImagePath = './assets/cityToCity/bannerImage/desktop/';
    public static bannerImageAlt = 'Travel to {B-}';
    public static mapImage = 'default-distance.jpg';
    public static mapImagePath = './assets/cityToCity/mapImage/';
    public static mapImageAlt = '{A-B} by Road';
    public static h1Header = 'Travel From {A-B}';
    public static overviewHeader = '{B-} Overview';
    public static overviewContent = "No city is too big or too small. We know travellers and wanderers like us are everywhere. You live near Khajuraho, you live near Aleppey, and you live near Alibag and near Tranquebar. We want you to visit them all. Pack your bags every weekend and explore everything there is to see around you. We love that you're free to stop to breathe in clean air, learn about cultures and taste local food when you travel by cabs. We love that these wholesome experiences make travelling better and enrich our lives. We live for the surprises we find on road trips. Trust us when we say: The best time to travel is now; and Travel Begins With Savaari.";
    public static thingstodoTitle = 'Things to do in {B-}';
    public static byRoadContent = "The distance from {A-B} is ideal for a road trip that is best explored with a chauffeur-driven cab. You may halt at your convenience anytime, anywhere and enjoy your short break. With Savaari’s user-friendly website," +
      " you can book taxi online in a few simple steps and avail a hassle free car rental service. Hiring a cab ensures that {A-B} Distance will be covered in optimal time. With Savaari's chauffeur driven cars, you don’t have to bother about refueling, " +
      "dangerous terrain or some pesky truck trying to overtake you from the wrong side. Push your seats back, roll down the window and enjoy the beautiful sceneries while the {A-B} distance is being covered by car.";
    public static byRoadTitle = '{A-B} By Road';
    public static metaTitle = 'Travel from {A-B} - Distance, Duration & Itinerary by Road';
    public static metaDescription = 'The {A-B} distance can be covered comfortably with our premium service when you book a Savaari cab. Choose us when you travel from {A-B} by road.';
    public static metaKeywords = '{A-B}, {A-B} Distance, Distance from {A-B}, {A-} {B-} Distance, {A-B} by Road, {A-B} Tour, {A-B} Tourism, {A-B} Tour Package';
  }

  export class outstationLocalSeoContent {
    public static Title = "Reliable & Safe Cabs and Taxi Services at Affordable Prices";
    public static Keywords = "Car Rental , Car Hire, Taxi Service, Cab Service, Cab Hire, Taxi Hire ,Cab Rental, Taxi Booking, Rent A Car, Car Rental India, Online Cab Booking, Taxi Cab , Car Rental Service, Online Taxi Booking, Local Taxi Service, Cheap Car Rental, Cab , Taxi , Car Rental, Car Hire Services , Car Rentals India, Taxi Booking India, Cab Booking India Car For Hire,  Taxi Services, Online Car Rentals , Book A Taxi  , Book A Cab, Car Rentals Agency India, Rental Cars In India, Car Hire India, India Car Hire, Car Hire In India, India Car Rentals, Car Rent In India, India Rental Cars, India Cabs, Rent Car In India, Car Rental India, India Car Rental, Rent A Car India, Car Rental In India, Rent A Car In India, India Car Rental Company, Corporate Car Rental India, City Cabs India, Car Rental Company In India";
    public static Description = "India's premium cab & taxi provider with fares starting at just ₹9/km. We offer both Roundtrip & One-way cabs with experienced drivers and clean cars.";
  }
  export class outstationTaxiCarRentalsSeoContent {
    public static Title = "20% Off on Reliable & Safe Outstation Cabs With Driver";
    public static Keywords = "Car Rental , Car Hire, Taxi Service, Cab Service, Cab Hire, Taxi Hire ,Cab Rental, Taxi Booking, Rent A Car, Car Rental India, Online Cab Booking, Taxi Cab , Car Rental Service, Online Taxi Booking, Local Taxi Service, Cheap Car Rental, Cab , Taxi , Car Rental, Car Hire Services , Car Rentals India, Taxi Booking India, Cab Booking India Car For Hire,  Taxi Services, Online Car Rentals , Book A Taxi  , Book A Cab, Car Rentals Agency India, Rental Cars In India, Car Hire India, India Car Hire, Car Hire In India, India Car Rentals, Car Rent In India, India Rental Cars, India Cabs, Rent Car In India, Car Rental India, India Car Rental, Rent A Car India, Car Rental In India, Rent A Car In India, India Car Rental Company, Corporate Car Rental India, City Cabs India, Car Rental Company In India";
    public static Description = "Book outstation cabs in around 2K plus cities with professional drivers starting at ₹9/km. Choose your outstation cab as per budget and requirement";
  }
  export class LocalTaxiCarRentalSeoContent {
    public static Title = "Book cabs nearby across India| Cab Booking at best prices";
    public static Keywords = "Car Rental , Car Hire, Taxi Service, Cab Service, Cab Hire, Taxi Hire ,Cab Rental, Taxi Booking, Rent A Car, Car Rental India, Online Cab Booking, Taxi Cab , Car Rental Service, Online Taxi Booking, Local Taxi Service, Cheap Car Rental, Cab , Taxi , Car Rental, Car Hire Services , Car Rentals India, Taxi Booking India, Cab Booking India Car For Hire,  Taxi Services, Online Car Rentals , Book A Taxi  , Book A Cab, Car Rentals Agency India, Rental Cars In India, Car Hire India, India Car Hire, Car Hire In India, India Car Rentals, Car Rent In India, India Rental Cars, India Cabs, Rent Car In India, Car Rental India, India Car Rental, Rent A Car India, Car Rental In India, Rent A Car In India, India Car Rental Company, Corporate Car Rental India, City Cabs India, Car Rental Company In India";
    public static Description = "Book taxi service near you from India's largest car rental services with fares starting at just  ₹9/km. Choose a car of your comfort & enjoy a hassle-free journey with Savaari's guaranteed service promises.";
  }
  export class CharDhamSeoContent {
    public static Title = "Char Dham Yatra Package 2024 Starting at \u20B94600";
    public static Keywords = "";
    public static Description =  "Savaari offers the best deals for Char dham yatra, starting at \u20B94600 per day. Book most convenient & safety cab in the city.";
  }
