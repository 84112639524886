import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomPreloading } from './custom-preloading';

const routes: Routes = [
  {
    path: 'press-and-media-coverage',
    loadChildren: () => import('./info/press-release/press-release.module').then(m => m.PressReleaseModule)
  },
  {
    path: 'reviews',
    loadChildren: () => import('./info/reviews/reviews.module').then(m => m.ReviewsModule)
  },
  {
    path: 'booking',
    loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule)
  },
  {
    path: 'populate',
    loadChildren: () => import('./populate/populate.module').then(m => m.PopulateModule)
  },
  {
    path: 'unsubscribe/:bookingid/:mobileno',
    loadChildren: () => import('./info/unsubscribe/unsubscribe.module').then(m => m.UnsubscribeModule)
  },
  {
    path: 'feedback/:bookingid/:mobileno',
    loadChildren: () => import('./booking-feedback/booking-feedback.module').then(m => m.BookingFeedbackModule)
  },
  {
    path: 'one-way-cabs',
    loadChildren: () => import('./one-way-cabs/one-way-cabs.module').then(m => m.OneWayCabsModule)
  },
  {
    path: 'sitemap',
    loadChildren: () => import('./info/sitemap/sitemap.module').then(m => m.SitemapModule)
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./info/contact-us/contact-us.module').then(m => m.ContactUsModule)
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./info/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
  },
  {
    path: 'careers',
    loadChildren: () => import('./info/careers/careers.module').then(m => m.CareersModule)
  },
  {
    path: 'TnC-local-trip',
    loadChildren: () => import('./info/terms-local/terms-local.module').then(m => m.TermsLocalModule)
  },
  {
    path: 'TnC_local_trip_metros',
    loadChildren: () => import('./info/terms-local/terms-local.module').then(m => m.TermsLocalModule)
  },
  {
    path: 'TnC-outstation-trip',
    loadChildren: () => import('./info/terms-local/terms-local.module').then(m => m.TermsLocalModule)
  },
  {
    path: 'TnC_premium_outstation_trip',
    loadChildren: () => import('./info/terms-local/terms-local.module').then(m => m.TermsLocalModule)
  },
  {
    path: 'TnC_oneway_outstation_trip',
    loadChildren: () => import('./info/terms-local/terms-local.module').then(m => m.TermsLocalModule)
  },
  {
    path: 'TnC_oneway_outstation_trip_cng',
    loadChildren: () => import('./info/terms-local/terms-local.module').then(m => m.TermsLocalModule)
  },
  {
    path: 'TnC_local_8_10',
    loadChildren: () => import('./info/terms-local/terms-local.module').then(m => m.TermsLocalModule)
  },
  {
    path: 'TnC_local_unlimited',
    loadChildren: () => import('./info/terms-local/terms-local.module').then(m => m.TermsLocalModule)
  },
  {
    path: 'TnC_BLR_offer',
    loadChildren: () => import('./info/terms-local/terms-local.module').then(m => m.TermsLocalModule)
  },
  {
    path: 'tnc_airport_trip',
    loadChildren: () => import('./info/terms-local/terms-local.module').then(m => m.TermsLocalModule)
  },
  {
    path: 'tnc_bangalore_airport_trip',
    loadChildren: () => import('./info/terms-local/terms-local.module').then(m => m.TermsLocalModule)
  },
  {
    path: 'TnC-special-services',
    loadChildren: () => import('./info/terms-special-service/terms-special-service.module').then(m => m.TermsSpecialServiceModule)
  },
  {
    path: 'mobile-app',
    loadChildren: () => import('./download-app/mobileapp.module').then(m => m.MobileAppModule)
  },
  {
    path: 'corporate-car-rental',
    loadChildren: () => import('./corporate-car-rental-agency-india/corporate.module').then(m => m.CorporateModule)
  },
  {
    path: 'feedback/:bookingid/:mobileno/:nps',
    loadChildren: () => import('./feedback/feedback.module').then(m => m.FeedbackModule)
  },
  {
    path: 'enquiry_booking',
    loadChildren: () => import('./enquirytobooking/enquirytobooking.module').then(m => m.EnquiryModule)
  },
  {
    path: 'term-conditions',
    loadChildren: () => import('./info/terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: 'cancellation-and-refunds',
    loadChildren: () => import('./cancellation-refunds/cancellation-refunds.module').then(m => m.CancellationRefundsModule)
  },
  {
    path: 'aboutus',
    loadChildren: () => import('./info/about-us/about-us.module').then(m => m.AboutUsModule)
  },
  {
    path: 'offers-coupons',
    loadChildren: () => import('./info/offers-coupons/offers-coupons.module').then(m => m.OffersCouponsModule)
  },
  {
    path: 'select_cars',
    loadChildren: () => import('./select-car/select-car.module').then(m => m.SelectCarsModule)

  },
  {
    path: 'trackDriver',
    loadChildren: () => import('./app-dialog/driver-tracking/driver-tracking.module').then(m => m.DriverTrackingModule)
  },
  {
    path: 'account',
    loadChildren: () => import('./account-setting/account-setting.module').then(m => m.AccountSettingModule)
  },
  {
    path: 'booking-details',
    loadChildren: () => import('./booking-details/booking-details.module').then(m => m.BookingDetailsModule)
  },
  {
    path: 'booking-edit/:bookingid/:accesskey',
    loadChildren: () => import('./booking-edit/booking-edit.module').then(m => m.BookingEditModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./info/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'promise-of-quality',
    loadChildren: () => import('./promise-of-quality/promise-of-quality.module').then(m => m.PromiseOfQualityModule)
  },
  {
    path: 'choose-cabs-over-self-drive',
    loadChildren: () => import('./info/choose-cabs-over-self-drive/choose-cabs-over-self-drive.module').then(m => m.ChooseCabsOverSelfDriveModule)
  },
  {
    path: 'car-load',
    loadChildren: () => import('./car-load/car-load.module').then(m => m.CarLoadModule)
  },
  {
    path: ':firstPathComponant',
    loadChildren: () => import('./seo-home/seo-home.module').then(m => m.SeoHomeModule)
  },
  {
    path: ':firstPathComponant/:secondPathComponant',
    loadChildren: () => import('./seo-home/seo-home.module').then(m => m.SeoHomeModule)
  },
  {
    path: 'indigo',
    loadChildren: () => import('./seo-home/seo-home.module').then(m => m.SeoHomeModule)
  },
  {
    path: 'luxury/car-rental',
    loadChildren: () => import('./seo-home/seo-home.module').then(m => m.SeoHomeModule)
  },
  {
    path: 'customer-bill/:bookingid/:bookingkey',
    loadChildren: () => import('./customer-bill/customer-bill.module').then(m => m.CustomerBillModule)
  },
  {
    path: 'customer-email/:bookingid/:bookingkey',
    loadChildren: () => import('./customer-email/customer-email.module').then(m => m.CustomerEmailModule)
  },

  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    pathMatch: 'full'
  },
  {
    path: '**',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: CustomPreloading
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
