import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MessageService {
    private subject = new Subject<any>();
    outstationTripText: string = '';
    localTripText: string = '';
    airportTripText: string = '';

    sendMessage(message: string) {
        this.subject.next({ res: message });
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }

    setOutstationText(text: string) {
        this.outstationTripText = text;
    }

    getOutstationText() {
        return this.outstationTripText;
    }

    setLocalText(text: string) {
        this.localTripText = text;
    }

    getLocalText() {
        return this.localTripText;
    }

    setAirportText(text: string) {
        this.airportTripText = text;
    }

    getAirportText() {
        return this.airportTripText;
    }
}
