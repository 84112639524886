<div class="menuList">
  <ul>
    <li *ngFor="let listItem of menuItemList">
      <a *ngIf="listItem.routerLink!='logout'" routerLink="{{listItem.routerLink!='logout'?listItem.routerLink:''}}" (click)="didSelect(listItem.routerLink)">
        {{listItem.name}}
      </a>
      <a *ngIf="listItem.routerLink=='logout'" href="javascript:void(0);" (click)="didSelect(listItem.routerLink)">
        {{listItem.name}}
      </a>
    </li>
  </ul>
</div>