import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CacheService } from 'ng2-cache';
import { AppConstants } from 'src/environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
declare var clevertap: any;

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(private cacheService: CacheService,        
        @Inject(PLATFORM_ID) private platformId: any,
        private http: HttpClient ) { }


    memoryStorage: any = {};

    checkPlatform(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    isLocalStorageAvailable() {
        if (this.checkPlatform()) {
            var testKey = 'test', storage = window.sessionStorage;
            try {
                storage.setItem(testKey, '1');
                storage.removeItem(testKey);
                return true;
            } catch (error) {
                return false;
            }
        } else {
            return;
        }
    }

    setItem(key: string, item: any) {

        if (this.isLocalStorageAvailable()) {
            localStorage.setItem(key, item)
        } else {
            this.memoryStorage[key] = item
        }

    }


    getItem(key: string) {

        if (this.isLocalStorageAvailable()) {
            return localStorage.getItem(key)
        } else {
            return this.memoryStorage[key]
        }
    }


    setJsonItem(key: string, item: any) {

        if (this.isLocalStorageAvailable()) {

            let jsonStr = JSON.stringify(item)
            localStorage.setItem(key, jsonStr)

        } else {
            this.memoryStorage[key] = item
        }

    }

    getJsonItem(key: string): any {

        if (this.isLocalStorageAvailable()) {

            let item = localStorage.getItem(key)
            return item ? JSON.parse(item) : null;

        } else {
            return this.memoryStorage[key]
        }

    }



    setCacheItem(key: string, item: any) {

        if (this.isLocalStorageAvailable()) {
            this.cacheService.set(key, item, { maxAge: AppConstants.cacheDuration });

        } else {
            this.memoryStorage[key] = item
        }

    }

    getCacheItem(key: string) {

        if (this.isLocalStorageAvailable()) {
            return this.cacheService.get(key);
        } else {
            return this.memoryStorage[key]
        }

    }

    removeItem(key: string) {

        if (this.isLocalStorageAvailable()) {

            localStorage.removeItem(key)

        } else {
            this.memoryStorage[key] = undefined
        }

    }

    randomString(length: number) {

        var result = '';
        let chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    setCacheRandomString(key: string, item: any) {

        if (this.isLocalStorageAvailable()) {
            this.cacheService.set(key, item, { maxAge: 1800 });

        } else {
            this.memoryStorage[key] = item
        }

    }

    getCacheSessionId(){
        if(this.getCacheItem('session_id') == null || this.getCacheItem('session_id') == ''){
            let session_id =this.randomString(19)+(new Date).getTime()
            console.log(session_id)
            this.setCacheRandomString('session_id',session_id)
            return session_id;
        }else{
            return this.getCacheItem('session_id');
        }
    }

    async save_analytics_data(event_name: string, event_data:any): Promise<any> {
        try {
            let token = await this.getToken();

           
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj = new FormData();
                       
            bodyObj.set('session_id', this.getCacheSessionId());
            bodyObj.set('event_name', event_name);
            const userDetails = localStorage.getItem('loggedUserDetail');
            const logintoken = localStorage.getItem('loginUserToken');

            console.log(event_name);
            if (logintoken && userDetails) {
                const userDetailParse = JSON.parse(userDetails);
                if (userDetailParse.email) {
                    event_data.customer_email = userDetailParse.email;
                }
            }
            console.log(event_data);
            // clevertap.event.push(event_name, event_data);
            bodyObj.set('event_data', JSON.stringify(event_data));            

            const analytics_data_Url = AppConstants.apiPlaygroundUrl + 'analytics_data' + '?token=' + token;
            await this.http.post(analytics_data_Url, bodyObj, { headers: headers }).toPromise();            
            return true;

        } catch (error) {
            return Promise.reject(error);
        }
    }

    async save_CT_data(event_name: string, event_data:any): Promise<any> {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj = new FormData();
                       
            bodyObj.set('session_id', this.getCacheSessionId());
            bodyObj.set('event_name', event_name);

            console.log(event_name);
            console.log(event_data);
            clevertap.event.push(event_name, event_data);         
            return true;

        } catch (error) {
            return Promise.reject(error);
        }
    }

    async getToken(): Promise<any>  {
        let token = this.getItem('SavaariToken');
        if (token == null || token == '') {
            try {
                const url = AppConstants.apiPlaygroundUrl + AppConstants.tokenPath
                const params: HttpParams = new HttpParams({
                    fromObject: {
                        'apiKey': AppConstants.apiKey,
                        'appId': AppConstants.appId
                    }
                });

                const res: any = await this.http.get(url, { params: params }).toPromise();
                const body = res;
                token = body.data.token
                this.setItem('SavaariToken',token );                
                
            }
            catch (error) {
            }
            
        }
        return token;
    }

}
