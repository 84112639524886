import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AppConstants, TripTypeConstant, SubTripTypeConstant, subTripTypeConstants, SavaariMetros, partnerAffiliateIds } from '../../environments/environment';
import { StorageService } from './storage.service';
import { TokenService } from './token.service';

@Injectable({
    providedIn: 'root'
})
export class PaymentSessionService {

    remain_amt: any;
    npl: any;
    private baseUrl: string = AppConstants.apiUrl;         // URL to the api of the user details and seo content
    private apiPlaygroundUrl: string = AppConstants.apiPlaygroundUrl; // URL to the API PLAYGROUND
    private potentialBookingUrl: string = AppConstants.bookingUrl;    // URL to the booking car in the API PLAYGROUND (of savaari team)
    private vasBookingUpdateUrl: string = AppConstants.vasUpdateUrl;    // URL to the booking car in the API PLAYGROUND (of savaari team)
    private applyCouponUrl: string = AppConstants.applyCouponUrl;    // URL to the apply coupon on payment step in the API PLAYGROUND (of savaari team)
    private removeCouponUrl: string = AppConstants.removeCouponUrl;  // URL to the remove coupon on payment step in the API PLAYGROUND (of savaari team)
    private localitiesPath = AppConstants.localitiesPath;   // URL to the localities fetching in the API PLAYGROUND (of savaari team)
    private countryCodePath = AppConstants.countryCodePath;   // URL to the localities fetching in the API PLAYGROUND (of savaari team)
    private updateBookingPath = AppConstants.updateBookingUrl;
    private updateFlightUrl: string = AppConstants.updateFlightUrl;  // URL to the remove coupon on payment step in the API PLAYGROUND (of savaari team)

    private validateCouponUrl: string = AppConstants.validateCouponUrl;

    private advanve_payment_check_path: string = AppConstants.advanve_payment_check_path;


    private savaari_Url: string = AppConstants.savaari_Url;
    private language_carrier_path: string = AppConstants.language_carrier_path;
    private whatsapp_path: string = AppConstants.whatsapp_path;
    private payment_link_data_path: string = AppConstants.payment_link_data;
    private update_payment_info_path: string = AppConstants.update_payment_info;
    private payment_link_validate: string = AppConstants.payment_link_validate;
    private loyaltyInternalApiUrl: string = AppConstants.loyaltyInternalApiUrl;

    public testd: any;
    private selectedDestinationCity: any = '';
    private bookingEmail: any;
    private bookingDetail: any = {};
    private bookingPickupDetail: any = {};
    private bookingId: any;
    private localityCities: Array<any> = [];
    private countryCode: Array<any> = [];
    private amount_full: any;
    constructor(private http: HttpClient,
        private localStorage: StorageService, private tokenService: TokenService) {
    }


    // Setting the STEP 1 user email address
    public setBookingEmail(bookingEmail: any) {
        this.bookingEmail = bookingEmail;
        this.localStorage.setItem('bookingEmail', this.bookingEmail);
    }
    // Getting the user email address
    public getBookingEmail() {
        return this.bookingEmail;
    }
    // Setting the STEP 2 user details
    public setBookingPickupDetail(bookingPickupDetail: any) {
        this.bookingPickupDetail = bookingPickupDetail;
        if (this.bookingPickupDetail && this.bookingPickupDetail.pickupaddress) {
            const adress = this.bookingPickupDetail.pickupaddress.replace(/\n/g, '<br/>');
            const finaladdress = adress.replace(new RegExp('<br/>', 'g'), ' ');
            this.bookingPickupDetail.pickupaddress = finaladdress;

        }
        // if(!(this.bookingPickupDetail && this.bookingPickupDetail.locality && this.bookingPickupDetail.locality.id))
        // {

        // 	if(this.bookingPickupDetail.locality )
        // 	this.bookingPickupDetail.locality='';
        // }
        this.localStorage.setItem('bookingPickupDetail', JSON.stringify(this.bookingPickupDetail));
    }
    // Getting the STEP 2 user details
    public getBookingPickupDetail() {
        return this.bookingPickupDetail;
    }
    // Setting the SELECTED CAR details along with source and destination details from first page
    public setBookingDetail(bookingDetail: any) {
        this.selectedDestinationCity = '';
        this.bookingDetail = bookingDetail;
        this.localStorage.setItem('bookingDetail', JSON.stringify(this.bookingDetail));
        if (this.bookingDetail && this.bookingDetail.to_city_details) {
            for (let i = 0; i < this.bookingDetail.to_city_details.length; i++) {
                this.selectedDestinationCity += this.bookingDetail.to_city_details[i].cityId + ',';
            }
            this.selectedDestinationCity = this.selectedDestinationCity.slice(0, -1);
        }
    }
    // Getting details of the first page and select car page.
    public getBookingDetail() {
        return this.bookingDetail;
    }
    // Creating the potential booking after step 2 and saving BOOKED ID and RESERVATION ID (ORDER ID)
    public setPotentialBookingDetails(bookingId: any, reservationId: any) {
        this.bookingId = bookingId;
    }

    // Fetching Locality list from the API PLAYGROUND
    async getLocalities(newapicall = true): Promise<any> {
        try {
            if (!newapicall && this.localityCities != null) {
                return this.localityCities;
            }
            let token = await this.tokenService.getToken()
            const params = new HttpParams({
                fromObject: {
                    'sourceCity': this.bookingDetail.from_city_id,
                    'token': token
                }
            });

            const url = this.apiPlaygroundUrl + this.localitiesPath;
            const res: any = await this.http.get(url, { params: params }).toPromise()
            this.localityCities = res.data;
            return this.localityCities;

        } catch (error) {
            return [];
        }
    }

    // Get country code to pickup details
    async getCountryCode(): Promise<any> {
        try {
            if (this.countryCode.length > 0) {
                return this.countryCode;
            }
            const url = this.baseUrl + this.countryCodePath;
            const res: any = await this.http.get(url).toPromise()
            const codes = res.countryCode;

            this.countryCode = [];
            for (let code in codes) {
                this.countryCode.push({ 'key': code, 'value': codes[code] });
            }
            return this.countryCode;
        } catch (error) {
            return [];
        }

    }

    stringContainsArrayValue(inputString: string, searchArray: string[]): boolean {
        // Use Array.prototype.some() to check if any value in the array is in the string
        return searchArray.some(value => inputString.includes(value));
    }

    // Saving the potential booking details after step 2
    async potentialBooking(): Promise<any> {
        try {
            console.log(this.bookingPickupDetail.pickupaddress);
            const pickaddress=this.bookingPickupDetail.pickupaddress;
            const dropAddress=this.bookingPickupDetail.dropAddress;
            let terminal_dd = '';
            let landmark = '';
            let airportFlag='';
            if (this.bookingPickupDetail.landmark && this.bookingPickupDetail.flightdetails == '') {
                landmark = this.bookingPickupDetail.landmark + ', ';
            }
            const adress = this.bookingPickupDetail.pickupaddress.replace(/\n/g, '<br/>');
            const finaladdress = landmark + adress.replace(new RegExp('<br/>', 'g'), ' ');
            const showlocality = this.checkmetro(this.bookingDetail.from_city_id);
            const Urgent_booking = this.bookingDetail.car_details.urgent_booking_flag;
            const fixedAmount = this.localStorage.getItem('fixedAmount');
            const fixedAmountFlag = this.localStorage.getItem('fixedAmountFlag');
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let potentialBookingObj: FormData = new FormData();
            potentialBookingObj.append('sourceCity', this.bookingDetail.from_city_id);
            // for subTripType - pick_airport  and drop_airport -change of trip typw from local to airport 
            if (this.bookingDetail.trip_sub_type == 'pick_airport' || this.bookingDetail.trip_sub_type == 'drop_airport') {
                potentialBookingObj.append('tripType', 'airport');
                if (this.bookingDetail.trip_sub_type == 'pick_airport' && this.bookingPickupDetail.terminal_dd != '') {
                    terminal_dd = '||' + this.bookingPickupDetail.terminal_dd;
                } else {
                    terminal_dd = '';
                }                                
            } else {
                potentialBookingObj.append('tripType', this.bookingDetail.trip_type);
                terminal_dd = '';
            }
            // 
            if (fixedAmountFlag && fixedAmount != '' && fixedAmount > 0) {
                potentialBookingObj.append('fixed_amount', fixedAmount);
            }

            if (this.bookingDetail.trip_sub_type=="oneWay"){
                console.log(this.bookingDetail);
                console.log(this.bookingDetail.trip_sub_type);
                console.log(this.bookingDetail.from_city_id);
                console.log(this.bookingDetail.from_city_name);
                console.log(this.bookingDetail.to_city_details[0].cityId);
                console.log(this.bookingDetail.to_city_name);
                console.log(pickaddress);
                console.log(dropAddress);
            
                const searchTerms = ["Airport", "T1", "T2", "T3", "Terminal 1", "Terminal 2", "Terminal 3"];
                const found1 = this.stringContainsArrayValue(this.bookingDetail.from_city_name, searchTerms);
                const found2 = this.stringContainsArrayValue(pickaddress, searchTerms);
                const found3 = this.stringContainsArrayValue(this.bookingDetail.to_city_name, searchTerms);
                const found4 = this.stringContainsArrayValue(dropAddress, searchTerms);
                console.log(found4);
                if(found1){ console.log('1111');
                    if(this.bookingDetail.from_city_id == 48 || this.bookingDetail.from_city_id == 36 || (this.bookingDetail.from_city_id == 145 && this.bookingDetail.from_city_name.includes('T1')) || (this.bookingDetail.from_city_id == 145 && this.bookingDetail.from_city_name.includes('T2')) || (this.bookingDetail.from_city_id == 145 && pickaddress.includes('Airport') ) || (this.bookingDetail.from_city_id == 145 && pickaddress.includes('Terminal 1') ) || (this.bookingDetail.from_city_id == 145 && pickaddress.includes('Terminal 2') )){
                        airportFlag = '1';
                        console.log('airportFlag payment service page 1');
                    }else if(this.bookingDetail.from_city_id == 223 || this.bookingDetail.from_city_id == 114 || this.bookingDetail.from_city_id == 330  || this.bookingDetail.from_city_id == 286 || this.bookingDetail.from_city_id == 2  || this.bookingDetail.from_city_id == 371 || this.bookingDetail.from_city_id == 354  || this.bookingDetail.from_city_id == 297 || (this.bookingDetail.from_city_id == 145 && this.bookingDetail.from_city_name.includes('T3')) || (this.bookingDetail.from_city_id == 145 && this.bookingDetail.from_city_name.includes('Terminal 3'))){
                        airportFlag = '2';
                        console.log('airportFlag payment service page 2');
                    }  
                }else if(found2){
                    if(this.bookingDetail.from_city_id == 48 || this.bookingDetail.from_city_id == 36 || ((this.bookingDetail.from_city_id == 145 && pickaddress.includes('T1')) || (this.bookingDetail.from_city_id == 145 && pickaddress.includes('T2')) || (this.bookingDetail.from_city_id == 145 && pickaddress.includes('Airport')) || (this.bookingDetail.from_city_id == 145 && pickaddress.includes('Terminal 1')) || (this.bookingDetail.from_city_id == 145 && pickaddress.includes('Terminal 2')))){
                        airportFlag = '1';
                        console.log('airportFlag payment service page 3');
                    }else if(this.bookingDetail.from_city_id == 223 || this.bookingDetail.from_city_id == 114 || this.bookingDetail.from_city_id == 330 || this.bookingDetail.from_city_id == 286  || this.bookingDetail.from_city_id == 2 || this.bookingDetail.from_city_id == 371  || this.bookingDetail.from_city_id == 354  || this.bookingDetail.from_city_id == 297 || (this.bookingDetail.from_city_id == 145 && pickaddress.includes('T3')) || (this.bookingDetail.from_city_id == 145 && pickaddress.includes('Terminal 3'))){
                        airportFlag = '2';
                        console.log('airportFlag payment service page 4');
                    }
                }else if (found3){ console.log('333');
                    if(this.bookingDetail.to_city_details[0].cityId == 4276 || this.bookingDetail.to_city_details[0].cityId == 560 || this.bookingDetail.to_city_details[0].cityId == 4170 || (this.bookingDetail.to_city_details[0].cityId == 4506 && this.bookingDetail.to_city_name.includes('T1')) || (this.bookingDetail.to_city_details[0].cityId == 4506 && this.bookingDetail.to_city_name.includes('T2')) || (this.bookingDetail.to_city_details[0].cityId == 4506 && this.bookingDetail.to_city_name.includes('Airport')) || (this.bookingDetail.to_city_details[0].cityId == 4506 && this.bookingDetail.to_city_name.includes('Terminal 1')) || (this.bookingDetail.to_city_details[0].cityId == 4506 && this.bookingDetail.to_city_name.includes('Terminal 2'))){
                        airportFlag = '1';
                        console.log('airportFlag payment service page 5');
                    }else if(this.bookingDetail.to_city_details[0].cityId == 3709 || this.bookingDetail.to_city_details[0].cityId == 4483  || this.bookingDetail.to_city_details[0].cityId == 7878 || this.bookingDetail.to_city_details[0].cityId == 2653  || this.bookingDetail.to_city_details[0].cityId == 2194 || this.bookingDetail.to_city_details[0].cityId == 2532  || this.bookingDetail.to_city_details[0].cityId == 950 || this.bookingDetail.to_city_details[0].cityId == 3942 || (this.bookingDetail.to_city_details[0].cityId == 4506 && this.bookingDetail.to_city_name.includes('T3')) || (this.bookingDetail.to_city_details[0].cityId == 4506 && this.bookingDetail.to_city_name.includes('Terminal 3'))){
                        airportFlag = '2';
                        console.log('airportFlag payment service page 6');
                    }
                }else if (found4){ console.log('here');
                    if(this.bookingDetail.to_city_details[0].cityId == 4276 || this.bookingDetail.to_city_details[0].cityId == 560 || this.bookingDetail.to_city_details[0].cityId == 4170 || (this.bookingDetail.to_city_details[0].cityId == 4506 && dropAddress.includes('T1')) || (this.bookingDetail.to_city_details[0].cityId == 4506 && dropAddress.includes('T2')) || (this.bookingDetail.to_city_details[0].cityId == 4506 && dropAddress.includes('Airport')) || (this.bookingDetail.to_city_details[0].cityId == 4506 && dropAddress.includes('Terminal 1')) || (this.bookingDetail.to_city_details[0].cityId == 4506 && dropAddress.includes('Terminal 2'))){
                        airportFlag = '1';
                        console.log('airportFlag payment service page 7');
                    }else if(this.bookingDetail.to_city_details[0].cityId == 3709 || this.bookingDetail.to_city_details[0].cityId == 4483  || this.bookingDetail.to_city_details[0].cityId == 7878 || this.bookingDetail.to_city_details[0].cityId == 2653  || this.bookingDetail.to_city_details[0].cityId == 2194 || this.bookingDetail.to_city_details[0].cityId == 2532  || this.bookingDetail.to_city_details[0].cityId == 950 || this.bookingDetail.to_city_details[0].cityId == 3942 || (this.bookingDetail.to_city_details[0].cityId == 4506 && dropAddress.includes('T3')) || (this.bookingDetail.to_city_details[0].cityId == 4506 && dropAddress.includes('Terminal 3'))){                            
                        airportFlag = '2';
                        console.log('airportFlag payment service page 8');
                    }
                }
            }
            console.log(airportFlag);
            if(airportFlag == '' ){
                airportFlag = '0';
                console.log('airportFlag payment service page 9');
            }
            console.log(airportFlag);
            this.localStorage.setItem("OW_airportFlag", airportFlag);

            console.log(this.bookingPickupDetail.custLocality);
            potentialBookingObj.append('subTripType', this.bookingDetail.trip_sub_type);
            potentialBookingObj.append('pickupDateTime', this.bookingDetail.pickup_date + ' ' + this.bookingDetail.pickup_time_24);
            potentialBookingObj.append('duration', this.bookingDetail.numberOfDaysToBook);
            potentialBookingObj.append('pickupAddress', finaladdress + terminal_dd);

            if (this.bookingDetail.trip_sub_type == 'pick_airport' || this.bookingDetail.trip_sub_type == 'drop_airport') {
                potentialBookingObj.append('customerLatLong', this.bookingPickupDetail.custLatLong);
                potentialBookingObj.append('locality', this.bookingPickupDetail.custLocality);
            } else if (showlocality && this.bookingPickupDetail.locality && this.bookingPickupDetail.locality.id) {
                potentialBookingObj.append('localityId', this.bookingPickupDetail.locality.id);
            }
            else if (showlocality) {
                potentialBookingObj.append('customerLatLong', this.bookingPickupDetail.custLatLong);
                potentialBookingObj.append('locality', this.bookingPickupDetail.custLocality);
            }
            const selCity = JSON.parse(this.localStorage.getItem('selectedSourceCity'));
            if ((this.bookingDetail.trip_sub_type == 'pick_airport' || this.bookingDetail.trip_sub_type == 'drop_airport') && typeof selCity.tid !== 'undefined' && selCity.tid != '') {
                potentialBookingObj.append('terminalname', selCity.tid);
            }
            if (this.bookingDetail.trip_sub_type == 'pick_airport' || this.bookingDetail.trip_sub_type == 'drop_airport') {
                potentialBookingObj.append('airport_id', selCity.airportId);
                potentialBookingObj.append('airport_name', selCity.airportAddress);
            }
            if (selCity != null && typeof selCity.aid !== 'undefined' && selCity.aid != '') {
                potentialBookingObj.append('alias_source_city_id', selCity.aid);
            }
            if (this.bookingDetail.trip_type == 'outstation' && this.bookingDetail.trip_sub_type == 'oneWay') {
                potentialBookingObj.append('dropLatLong', this.bookingPickupDetail.dropLatLong);
                potentialBookingObj.append('dropLocality', this.bookingPickupDetail.dropLocality);
                if (this.bookingPickupDetail.dropLandmark != undefined && this.bookingPickupDetail.dropLandmark != '') {
                    potentialBookingObj.append('dropAddress', this.bookingPickupDetail.dropLandmark + ', ' + this.bookingPickupDetail.dropAddress);
                } else {
                    potentialBookingObj.append('dropAddress', this.bookingPickupDetail.dropAddress);
                }
            }

            const destCity = JSON.parse(this.localStorage.getItem('selectedDestinationCity'));
            if (this.bookingDetail.trip_type == 'outstation' && destCity != null && destCity.length > 0) {
                let temp_dest = [];
                for (let i = 0; i < destCity.length; i++) {
                    console.log(destCity[i])
                    if (typeof destCity[i].aid !== 'undefined' && destCity[i].aid != '' && destCity[i].aid > 0) {
                        temp_dest.push(destCity[i].aid)
                    } else {
                        temp_dest.push(0)
                    }
                }
                potentialBookingObj.append('alias_dest_city_id', temp_dest.join(','));
            }

            const sellocality = JSON.parse(this.localStorage.getItem('customerLocalityDetails'));
            if ((this.bookingDetail.trip_sub_type == 'pick_airport' || this.bookingDetail.trip_sub_type == 'drop_airport') && typeof sellocality.place_id !== 'undefined' && sellocality.place_id != '') {
                potentialBookingObj.append('selectPlaceId', sellocality.place_id);
            }
            potentialBookingObj.append('customerTitle', this.bookingPickupDetail.customerTitle);
            potentialBookingObj.append('customerName', this.bookingPickupDetail.title);
            potentialBookingObj.append('customerEmail', this.bookingEmail);
            potentialBookingObj.append('customerMobile', this.bookingPickupDetail.mobilenumber);
            potentialBookingObj.append('countryCode', this.bookingPickupDetail.mobilenumbercode);
            potentialBookingObj.append('carType', this.bookingDetail.car_details.carId);
            potentialBookingObj.append('premiumFlag', this.bookingDetail.car_details.premiumFlag);
            potentialBookingObj.append('couponCode', '');
            potentialBookingObj.append('destinationCity', this.selectedDestinationCity);            
            if(this.bookingPickupDetail.alternateemail !='' && typeof this.bookingPickupDetail.alternateemail != 'undefined'){
                potentialBookingObj.append('customerSecondaryEmail', this.bookingPickupDetail.alternateemail);
            }
            potentialBookingObj.append('source', 'WEB');
            console.log(this.bookingPickupDetail);
            if (this.bookingPickupDetail.flightdetails != '') {
                potentialBookingObj.append('flight_no', this.bookingPickupDetail.flightdetails);
            }

            potentialBookingObj.append('OW_airportFlag',airportFlag);

            //for device 
            if (navigator.userAgent.match(/Android/i)
                || navigator.userAgent.match(/webOS/i)
                || navigator.userAgent.match(/iPhone/i)
                || navigator.userAgent.match(/iPad/i)
                || navigator.userAgent.match(/iPod/i)
                || navigator.userAgent.match(/BlackBerry/i)
                || navigator.userAgent.match(/Windows Phone/i)
                || navigator.userAgent.match(/Opera Mini/i)
                || navigator.userAgent.match(/IEMobile/i)
                || navigator.userAgent.match(/WPDesktop/i)
            ) {
                potentialBookingObj.append('device', 'MOBILE');
                if (this.localStorage.getItem('mahindraFlag') == '1' && partnerAffiliateIds.clubMahindraApp > 0) {
                    potentialBookingObj.append('affiliateId', partnerAffiliateIds.clubMahindraApp.toString());
                }
            }
            else {
                potentialBookingObj.append('device', 'DESKTOP');
                if (this.localStorage.getItem('mahindraFlag') == '1' && partnerAffiliateIds.clubMahindraDesktop > 0) {
                    potentialBookingObj.append('affiliateId', partnerAffiliateIds.clubMahindraDesktop.toString());
                }
            }
            // checking the booking is a partner refernce and passing the partner affiliate id
            const partnerDetails = JSON.parse(this.localStorage.getItem('partnerDetails'));
            if (partnerDetails && partnerDetails.partnerAffiliateId) {
                potentialBookingObj.append('affiliateId', partnerDetails.partnerAffiliateId);
            }
            if (this.localStorage.getItem('ixigoFlag') == '1' && this.localStorage.getItem('ixigoColor') == '#EC5B24' && partnerAffiliateIds.ixigo > 0) {
                potentialBookingObj.append('affiliateId', partnerAffiliateIds.ixigo.toString());
            }
            if (this.localStorage.getItem('ixigoFlag') == '1' && this.localStorage.getItem('ixigoColor') == '#1556BA' && partnerAffiliateIds.ixigoTrain > 0) {
                potentialBookingObj.append('affiliateId', partnerAffiliateIds.ixigoTrain.toString());
            }

            if (Urgent_booking) {
                potentialBookingObj.append('Urgent_booking', Urgent_booking);
            }
            const loginUserStorageString = this.localStorage.getItem('loggedUserDetail');
            if (loginUserStorageString !== null) { 
                const loginUserStorageObject = JSON.parse(loginUserStorageString);          
                potentialBookingObj.append('app_user_id', loginUserStorageObject.user_id);
            }

            let token = await this.tokenService.getToken();
            const params = new HttpParams().set("token", token);

            const potentialBookingUrl = this.apiPlaygroundUrl + this.potentialBookingUrl;
            const potentiatlBooking = await this.http.post(potentialBookingUrl, potentialBookingObj, { headers: headers, params: params }).toPromise();
            return potentiatlBooking;

        } catch (error) {
            return Promise.reject(error);
        }
    }

    // Saving the potential booking details after step 2
    async vasBookingupdate(pre_vas_booking_data:any,vas_data:any): Promise<any> {
        try {
            
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let vasBookingObj: FormData = new FormData();
            vasBookingObj.append('booking_id', pre_vas_booking_data.bookingId);
            vasBookingObj.append('pre_vas_booking_total_amount', pre_vas_booking_data.totalFare);
            vasBookingObj.append('pre_vas_booking_package_km', pre_vas_booking_data.tripKilometer);
            vasBookingObj.append('pre_vas_booking_package_hr', pre_vas_booking_data.tripHour);
            vasBookingObj.append('vas_data', JSON.stringify(vas_data));
            
            let token = await this.tokenService.getToken();
            const params = new HttpParams().set("token", token);

            const vasBookingUrl = this.apiPlaygroundUrl + this.vasBookingUpdateUrl;
            const potentiatlBooking = await this.http.post(vasBookingUrl, vasBookingObj, { headers: headers, params: params }).toPromise();
            return potentiatlBooking;

        } catch (error) {
            return Promise.reject(error);
        }
    }

    // Confirm booking if pay to driver selected
    async confirmBooking(): Promise<any> {
        try {
            this.bookingDetail = JSON.parse(this.localStorage.getItem('bookingDetail'));
            const showlocality = this.checkmetro(this.bookingDetail.from_city_id);
            const Urgent_booking = this.bookingDetail.car_details.urgent_booking_flag;

            this.setBookingDetail(this.bookingDetail);
            this.bookingPickupDetail = JSON.parse(this.localStorage.getItem('bookingPickupDetail'));
            const potentialBooking = JSON.parse(this.localStorage.getItem('potentialBooking'));
            this.bookingId = potentialBooking.data.bookingId;
            this.bookingEmail = this.localStorage.getItem('bookingEmail');
            const enquiry_id = this.localStorage.getItem('enquiry_id');
            const enquiry_utm_source = this.localStorage.getItem('enquiry_utm_source');
            //this.bookingEmail = potentialBooking.data.customer['email'];
            //console.log(potentialBooking.data.customer);
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bookingObj: FormData = new FormData();
            bookingObj.append('sourceCity', this.bookingDetail.from_city_id);
            bookingObj.append('tripType', (this.bookingDetail.trip_sub_type == 'pick_airport' || this.bookingDetail.trip_sub_type == 'drop_airport') ?
                'airport' : this.bookingDetail.trip_type);
            bookingObj.append('subTripType', this.bookingDetail.trip_sub_type);
            bookingObj.append('pickupDateTime', this.bookingDetail.pickup_date + ' ' + this.bookingDetail.pickup_time_24);
            bookingObj.append('duration', this.bookingDetail.numberOfDaysToBook);
            bookingObj.append('pickupAddress', this.bookingPickupDetail.pickupaddress);
            if (showlocality && this.bookingPickupDetail.locality && this.bookingPickupDetail.locality.id) {
                bookingObj.append('localityId', this.bookingPickupDetail.locality.id);
            } else if (this.bookingDetail.trip_sub_type == 'pick_airport' || this.bookingDetail.trip_sub_type == 'drop_airport' || showlocality) {
                bookingObj.append('customerLatLong', this.bookingPickupDetail.custLatLong);
                bookingObj.append('locality', this.bookingPickupDetail.custLocality);
            }
            if (this.bookingDetail.trip_type == 'outstation' && this.bookingDetail.trip_sub_type == 'oneWay') {
                bookingObj.append('dropLatLong', this.bookingPickupDetail.dropLatLong);
                bookingObj.append('dropLocality', this.bookingPickupDetail.dropLocality);
                if (this.bookingPickupDetail.dropLandmark != undefined && this.bookingPickupDetail.dropLandmark != '') {
                    bookingObj.append('dropAddress', this.bookingPickupDetail.dropLandmark + ', ' + this.bookingPickupDetail.dropAddress);
                } else {
                    bookingObj.append('dropAddress', this.bookingPickupDetail.dropAddress);
                }
            }
            const loginUserStorageString = this.localStorage.getItem('loggedUserDetail');
            if (loginUserStorageString !== null) { 
                const loginUserStorageObject = JSON.parse(loginUserStorageString);          
                bookingObj.append('app_user_id', loginUserStorageObject.user_id);
            }

            bookingObj.append('customerTitle', this.bookingPickupDetail.customerTitle);
            bookingObj.append('customerName', this.bookingPickupDetail.title);
            bookingObj.append('customerEmail', this.bookingEmail);
            bookingObj.append('customerMobile', this.bookingPickupDetail.mobilenumber);
            bookingObj.append('countryCode', this.bookingPickupDetail.mobilenumbercode);
            bookingObj.append('carType', this.bookingDetail.car_details.carId);        
            bookingObj.append('prePayment', '0');
            bookingObj.append('couponCode', '');
            bookingObj.append('destinationCity', this.selectedDestinationCity);
            bookingObj.append('bookingId', this.bookingId);
            //bookingObj.append('customerSecondaryEmail', typeof this.bookingPickupDetail.alternateemail != 'undefined' ? this.bookingPickupDetail.alternateemail : '');
            bookingObj.append('source', 'WEB');
            bookingObj.append('Urgent_booking', Urgent_booking);
            bookingObj.append('enquiry_id', '');

            if (enquiry_id) {
                bookingObj.set('enquiry_id', enquiry_id);
                bookingObj.set('enquiry_utm_source', enquiry_utm_source);
                if (this.localStorage.getItem('quotation_data')) {
                    let quotation_data = JSON.parse(this.localStorage.getItem('quotation_data'));
                    quotation_data['booking-id'] = this.bookingId;
                    quotation_data.event = quotation_data.event + '-' + this.bookingId;
                    console.log(quotation_data);
                    window['dataLayer'] = window['dataLayer'] || [];
                    window['dataLayer'].push(quotation_data);
                    this.localStorage.removeItem('quotation_data')
                }
            }

            let token = await this.tokenService.getToken()
            const confirmBookingUrl = this.apiPlaygroundUrl + this.updateBookingPath + '?token=' + token;
            const confirmBooking = await this.http.post(confirmBookingUrl, bookingObj, { headers: headers }).toPromise();
            const confirmBookingStatus: any = confirmBooking;
            if (confirmBookingStatus && confirmBookingStatus.status === 'success') {
                this.bookingEmail = '';
                //this.localStorage.setItem('bookingEmail', '');
            }
            return confirmBookingStatus;

        } catch (error) {
            return Promise.reject(error);
        }
    }

    async updateBooking(payment_mode_selected: any, orderId: any, advance_amt: any, type: any): Promise<any> {
        try {
            let token = await this.tokenService.getToken()

            this.bookingDetail = JSON.parse(this.localStorage.getItem('bookingDetail'));
            this.setBookingDetail(this.bookingDetail);
            this.bookingPickupDetail = JSON.parse(this.localStorage.getItem('bookingPickupDetail'));
            const potentialBooking = JSON.parse(this.localStorage.getItem('potentialBooking'));
            this.bookingId = potentialBooking.data.bookingId;
            this.amount_full = potentialBooking.data.totalFare;
            this.bookingEmail = this.localStorage.getItem('bookingEmail');
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj = new FormData();
            this.remain_amt = this.amount_full - advance_amt;
            bodyObj.append('token', token);
            bodyObj.append('advance_amount', advance_amt);
            bodyObj.append('payment_gateway_code', payment_mode_selected);
            bodyObj.append('booking_id', this.bookingId);
            bodyObj.append('order_id', orderId);
            if (this.bookingDetail.trip_type == 'outstation' && this.bookingDetail.trip_sub_type == 'oneWay') {
                bodyObj.append('dropLatLong', this.bookingPickupDetail.dropLatLong);
                bodyObj.append('dropLocality', this.bookingPickupDetail.dropLocality);
                if (this.bookingPickupDetail.dropLandmark != undefined && this.bookingPickupDetail.dropLandmark != '') {
                    bodyObj.append('dropAddress', this.bookingPickupDetail.dropLandmark + ', ' + this.bookingPickupDetail.dropAddress);
                } else {
                    bodyObj.append('dropAddress', this.bookingPickupDetail.dropAddress);
                }
            }

            if (type !== "payfull") {
                bodyObj.append('advance_status', "1"); bodyObj.append('total_status', "0");
            }
            else {
                bodyObj.append('advance_status', "0"); bodyObj.append('total_status', "1");
            }
            if (payment_mode_selected != 13 && payment_mode_selected != 10 && payment_mode_selected != 'PTD') {
                for (let i = 0; i < potentialBooking.data.paymentOptions.length; i++) {
                    if (potentialBooking.data.paymentOptions[i].payment_gateway_code === payment_mode_selected) {
                        this.amount_full = potentialBooking.data.paymentOptions[i].parameters.amountFull;
                    }
                }
            }
            bodyObj.append('balance_amount', this.remain_amt);

            if (payment_mode_selected == 'PTD') {
                bodyObj.set('payment_gateway_code', "0");
                bodyObj.set('advance_status', "0");
                bodyObj.set('total_status', "0");
            }

            const confirmBookingUrl = this.apiPlaygroundUrl + this.potentialBookingUrl + this.update_payment_info_path + '?token=' + token;
            const confirmBooking = await this.http.post(confirmBookingUrl, bodyObj, { headers: headers }).toPromise();
            const updateBookingStatus: any = confirmBooking;
            if (updateBookingStatus && updateBookingStatus.status === 'success') {
                //this.bookingEmail = '';
                //this.localStorage.setItem('bookingEmail', '');
            }
            return updateBookingStatus;

        } catch (error) {
            return Promise.reject(error);
        }
    }

    // Apply coupon in the STEP 3 of the booking
    async applyCouponCode(couponCode: any): Promise<any> {
        try {
            this.bookingDetail = JSON.parse(this.localStorage.getItem('bookingDetail'));
            this.setBookingDetail(this.bookingDetail);
            this.bookingPickupDetail = JSON.parse(this.localStorage.getItem('bookingPickupDetail'));
            const potentialBooking = JSON.parse(this.localStorage.getItem('potentialBooking'));
            this.bookingEmail = this.localStorage.getItem('bookingEmail');
            let trip_type: any = '';
            let trip_sub_type: any = '';
            if (this.bookingDetail.trip_type === 'outstation') {
                trip_type = TripTypeConstant.outstation;
            } else {
                trip_type = (this.bookingDetail.trip_sub_type == 'pick_airport' || this.bookingDetail.trip_sub_type == 'drop_airport') ? 1 : TripTypeConstant.local;
            }
            if (this.bookingDetail.trip_sub_type == 'roundTrip') {
                trip_sub_type = subTripTypeConstants(SubTripTypeConstant.roundTrip);
            } else if (this.bookingDetail.trip_sub_type == 'oneWay') {
                trip_sub_type = subTripTypeConstants(SubTripTypeConstant.oneWay);
            } else if (this.bookingDetail.trip_sub_type == '880') {
                trip_sub_type = subTripTypeConstants(SubTripTypeConstant.local_880);
            } else if (this.bookingDetail.trip_sub_type == '440') {
                trip_sub_type = subTripTypeConstants(SubTripTypeConstant.local_440);
            } else if (this.bookingDetail.trip_sub_type == '12120') {
                trip_sub_type = subTripTypeConstants(SubTripTypeConstant.local_12120);
            } else if (this.bookingDetail.trip_sub_type == 'pick_airport') {
                trip_sub_type = subTripTypeConstants(SubTripTypeConstant.air_pickup);
            } else if (this.bookingDetail.trip_sub_type == 'drop_airport') {
                trip_sub_type = subTripTypeConstants(SubTripTypeConstant.air_dropoff);
            } else {
                trip_sub_type = subTripTypeConstants(SubTripTypeConstant.local_880);
            }
            const payFixedAmount = this.localStorage.getItem('fixedAmount');
            const payFixedAmountFlag = this.localStorage.getItem('fixedAmountFlag');
            console.log(subTripTypeConstants(SubTripTypeConstant.air_pickup) + 'aaaaaaaaaaaaaaaaaaaaaaa');
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj = new FormData();
            bodyObj.append('coupon_code', couponCode);
            bodyObj.append('travel_id', potentialBooking.data.travelId);
            bodyObj.append('trip_type', trip_type);
            bodyObj.append('total_amt', potentialBooking.data.totalFare);
            bodyObj.append('email', this.bookingEmail);
            bodyObj.append('trip_sub_type', trip_sub_type);
            bodyObj.append('from_city_id', this.bookingDetail.from_city_id);

            bodyObj.append('name', potentialBooking.data.customer.name);
            bodyObj.append('mobile', potentialBooking.data.customer.mobile);
            bodyObj.append('order_id', potentialBooking.data.order_id);
            if (payFixedAmountFlag && payFixedAmount != '' && payFixedAmount > 0) {
                bodyObj.append('fixed_amount', payFixedAmount);
            }

            let utm_campaign = '';
            const campainDetails = this.localStorage.getItem('campaingBooking');
            if (campainDetails) {
                const campainData = JSON.parse(campainDetails);
                utm_campaign = campainData['utm_campaign'];
            }
            bodyObj.append('utm_campaign', utm_campaign);

            let token = await this.tokenService.getToken()
            const response: any = await this.http.post(this.apiPlaygroundUrl + this.applyCouponUrl + '?token=' + token, bodyObj, { headers: headers }).toPromise();
            return response.data;

        } catch (error) {
            return Promise.reject(error);
        }
    }

    // Remove coupon in the STEP 3 of the booking if already applied
    async removeCouponCode(couponCode: any): Promise<any> {
        try {
            this.bookingDetail = JSON.parse(this.localStorage.getItem('bookingDetail'));
            this.setBookingDetail(this.bookingDetail);
            this.bookingPickupDetail = JSON.parse(this.localStorage.getItem('bookingPickupDetail'));
            const potentialBooking = JSON.parse(this.localStorage.getItem('potentialBooking'));
            this.bookingEmail = this.localStorage.getItem('bookingEmail');
            if (this.bookingEmail == null) {
                throw Error("Email can not blank");
            }
            let trip_type: any = (this.bookingDetail.trip_type === 'outstation') ? TripTypeConstant.outstation : TripTypeConstant.local;
            const payFixedAmount = this.localStorage.getItem('fixedAmount');
            const payFixedAmountFlag = this.localStorage.getItem('fixedAmountFlag');
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj = new FormData();
            bodyObj.append('anchorval', '1');
            bodyObj.append('rem_cpon_code', couponCode);
            bodyObj.append('travel_id', potentialBooking.data.travelId);
            bodyObj.append('trip_type', trip_type);
            bodyObj.append('total_amt', potentialBooking.data.totalFare);
            bodyObj.append('email', this.bookingEmail);
            bodyObj.append('name', potentialBooking.data.customer.name);
            bodyObj.append('mobile', potentialBooking.data.customer.mobile);
            bodyObj.append('order_id', potentialBooking.data.order_id);
            if (payFixedAmountFlag && payFixedAmount != '' && payFixedAmount > 0) {
                bodyObj.append('fixed_amount', payFixedAmount);
            }

            let token = await this.tokenService.getToken()

            const response: any = await this.http.post(this.apiPlaygroundUrl + this.removeCouponUrl + '?token=' + token, bodyObj, { headers: headers }).toPromise();
            return response.data;
        }
        catch (error) {
            return Promise.reject(error);
        }
    }

    // Update booking details with flight/train number
    async flightTrainNumberUpdate(flightNumber: any, reservationId: any): Promise<any> {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            const bodyObj = new FormData();
            bodyObj.append('flightNumber', flightNumber);
            bodyObj.append('reservationId', reservationId);

            let token = await this.tokenService.getToken()
            const response: any = await this.http.post(this.apiPlaygroundUrl + this.updateFlightUrl + '?token=' + token, bodyObj, { headers: headers }).toPromise();
            return response.data;
        }
        catch (error) {
            return Promise.reject(error);
        }
    }

    async getbooking_merchantdetails(bookingid: any, paymentid: any): Promise<any> {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            const bodyObj = new FormData();
            bodyObj.append('booking_id', bookingid);
            bodyObj.append('payment_id', paymentid);

            let token = await this.tokenService.getToken()
            const url = this.apiPlaygroundUrl + this.potentialBookingUrl + this.payment_link_data_path + '?token=' + token;

            const response: any = await this.http.post(url, bodyObj, { headers: headers }).toPromise();
            return response.data;
        }
        catch (error) {
            return Promise.reject(error)
        }
    }

    async payment_link_validation(bookingid: any, paymentid: any, adv_amount: any, pg_id: any): Promise<any> {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            const bodyObj = new FormData();
            bodyObj.append('bid', bookingid);
            bodyObj.append('pid', paymentid);
            bodyObj.append('adv_amt', adv_amount);
            bodyObj.append('pg_id', pg_id);

            const url = this.savaari_Url + this.payment_link_validate;

            const response = await this.http.post(url, bodyObj, { headers: headers }).toPromise();
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async update_payment_gateway_data(gatewayid: any, orderid: any): Promise<any> {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj = new FormData();
            bodyObj.append('order_id', orderid);
            bodyObj.append('payment_gateway_code', gatewayid);

            let token = await this.tokenService.getToken()
            const url = this.apiPlaygroundUrl + this.potentialBookingUrl + this.update_payment_info_path + '?token=' + token;

            const response: any = await this.http.post(url, bodyObj, { headers: headers }).toPromise();
            return response.data;
        } catch (error: any) {
            const errors = error.error;
            return Promise.reject(errors);
        }
    }

    async generate_payment_link(bookingid: any, paymentid: any): Promise<any> {
        try {
            this.npl = true;
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');

            let bodyObj = new FormData();
            bodyObj.append('booking_id', bookingid);
            bodyObj.append('payment_id', paymentid);
            bodyObj.append('npl', this.npl);

            let token = await this.tokenService.getToken()
            const url = this.apiPlaygroundUrl + this.potentialBookingUrl + this.payment_link_data_path + '?token=' + token;
            const response: any = await this.http.post(url, bodyObj, { headers: headers }).toPromise();
            return response.data;
        } catch (error: any) {
            const errors = error.error;
            return Promise.reject(errors);
        }
    }

    async updateluggagecarriedetails(bookingid: any, language: any, carrier: any): Promise<any> {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');

            let bodyObj = new FormData();
            bodyObj.append('bookingid', bookingid);
            bodyObj.append('carrier_req', carrier);
            bodyObj.append('lang_req', language);

            const response = await this.http.post(this.savaari_Url + this.language_carrier_path, bodyObj).toPromise();
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async updatewhatsappdetails(booking_id: any, message_opt_in: any, mobilenumber: any, countryCode: any): Promise<any> {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj = new FormData();
            bodyObj.append('action', 'update');
            bodyObj.append('booking_id', booking_id);
            bodyObj.append('message_opt_in', message_opt_in);
            bodyObj.append('mobilenumber', mobilenumber);
            bodyObj.append('countryCode', countryCode);

            const response = await this.http.post('/payment_confirmation/' + this.whatsapp_path, bodyObj).toPromise();
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async unsubscribewhatsappdetails(booking_id: any, mobileno: any): Promise<any> {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj = new FormData();
            bodyObj.append('action', 'unsubscribe');
            bodyObj.append('booking_id', booking_id);
            bodyObj.append('mobileno', mobileno);
            const response = await this.http.post(this.savaari_Url + this.whatsapp_path, bodyObj).toPromise();
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    /*async getpaymentgateway(booking_id: any) {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            const bodyObj = new FormData();
            bodyObj.append('booking_id', booking_id);
            const response: any = await this.http.post(this.apiPlaygroundUrl + "email_sent", bodyObj).toPromise();
            return response.data;
        } catch (error) {
            return Promise.reject(error);
        }
    }*/

        async updateGSTDetails(booking_id: any,companyName:any,gstNumber:any): Promise<any> {
        try {

            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj = new FormData();
            bodyObj.append('booking_id', booking_id);            
            bodyObj.append('companyName', companyName)
            bodyObj.append('gstNumber', gstNumber)

            let token = await this.tokenService.getToken()

            const api_url = this.apiPlaygroundUrl + 'update_gst' + '?token=' + token;
            console.log(api_url);
            
            const response: any = await this.http.post(api_url, bodyObj, { headers: headers }).toPromise();

            return response.data;

        } catch (error: any) {

            try {

                const errors = error.json().errors;
                if (errors instanceof Array) {
                    error.error = errors[0];
                }
                return Promise.reject(error);

            } catch (e) {
                return Promise.reject(e);

            }
        }
    }    

    // Get Advance Payment Status
    async getadvancepaymentstatus(tripid: any, tripsubid: any, cityid: any, pickupdate: any, carid: any, totalamt: any, packageid: any, bookingsrc: any, pickup_time_24: any, IsPremium: any, destIds: any, reverse_dynamic_oneway: any, urgent_booking_payment_percent: any, airport_id:any, airport_name:any): Promise<any> {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj: FormData = new FormData();
            bodyObj.append('t_id', tripid);
            bodyObj.append('t_s_id', tripsubid);
            bodyObj.append('c_id', cityid);
            bodyObj.append('pick_date', pickupdate);
            bodyObj.append('car_id', carid);
            bodyObj.append('tot_amt', totalamt);
            bodyObj.append('package_id', packageid);
            bodyObj.append('b_src', bookingsrc);
            bodyObj.append('pick_time', pickup_time_24);
            bodyObj.append('IsPremium', IsPremium);
            bodyObj.append('drop_city_id', destIds);
            bodyObj.append('reverse_dynamic_oneway', reverse_dynamic_oneway);
            bodyObj.append('urgent_booking_payment_percent', urgent_booking_payment_percent);
            bodyObj.append('airport_id', airport_id);
            bodyObj.append('airport_name', airport_name);

            const response = await this.http.post('/payment_confirmation/' + this.advanve_payment_check_path, bodyObj).toPromise();
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    // Apply coupon in the STEP 3 of the booking
    async validateCouponCode(couponData: any): Promise<any> {
        try {
            console.log(couponData);
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            const params = new HttpParams({
                fromObject: {
                    'couponCode': couponData.couponCode,
                    'sourceCity': couponData.sourceCity,
                    'tripType': couponData.tripType,
                    'subTripType': couponData.subTripType,
                    'bookingAmount': couponData.bookingAmount,
                    'duration': couponData.duration,
                    'carType': couponData.carType,
                    'pickupDateTime': couponData.pickupDateTime
                }
            });

            let token = await this.tokenService.getToken()
            const response: any = await this.http.get(this.apiPlaygroundUrl + this.validateCouponUrl + '?token=' + token, { params: params }).toPromise();
            console.log(response);
            return response.data;
        } catch (error: any) {
            const errorsArr = { message: error.error.internalMessage };
            return Promise.reject(errorsArr);
        }
    }

    checkmetro(cityid: SavaariMetros) {
        if (cityid == SavaariMetros.city1 || cityid == SavaariMetros.city2 || cityid == SavaariMetros.city3 || cityid == SavaariMetros.city4 || cityid == SavaariMetros.city5 || cityid == SavaariMetros.city6 || cityid == SavaariMetros.city7 || cityid == SavaariMetros.city8) {
            return true;
        }
        return true;
    }

    // Loyalty Payment Gateway Balance check internal api call
    async getLRBalanceDetails(LRCardNumber: number, LRMobileNumber: number): Promise<any> {
        try {
            const api_url = this.loyaltyInternalApiUrl + '?check_balance=true&card_number=' + LRCardNumber + '&mobile_number=' + LRMobileNumber;
            console.log(api_url);
            const response = await this.http.get(api_url).toPromise();
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    // Loyalty Payment Gateway Initiate Payment internal api call
    async initiateLRPayment(LRCardNumber: any, LRMobileNumber: any, LRRedeemAmount: any, LRInvoiceAmount: any): Promise<any> {
        try {
            let bodyObj = new FormData();
            bodyObj.append('initiate_payment', 'true');
            bodyObj.append('card_number', LRCardNumber);
            bodyObj.append('mobile_number', LRMobileNumber);
            bodyObj.append('amount', LRRedeemAmount);
            bodyObj.append('invoice_amount', LRInvoiceAmount);
            const response = await this.http.post(this.loyaltyInternalApiUrl, bodyObj).toPromise();
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    // Loyalty Payment Gateway Confirm Payment internal api call
    async confirmLRPayment(LRTokenNumber: any, LRRedeemOTP: any): Promise<any> {
        try {
            let bodyObj = new FormData();
            bodyObj.append('confirm_payment', 'true');
            bodyObj.append('token', LRTokenNumber);
            bodyObj.append('otp', LRRedeemOTP);
            const response = await this.http.post(this.loyaltyInternalApiUrl, bodyObj).toPromise();
            return response;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async razorCreateOrder(amount: any, encodedAmount: any, pay_id: any): Promise<any> {
        try {
            let bodyObj = new FormData();
            bodyObj.append('amount', amount);
            bodyObj.append('encoded_amount', encodedAmount);
            bodyObj.append('savaari_payment_id', pay_id);

            const response = await this.http.post('/razor_createorder.php', bodyObj).toPromise();
            const orderData = response;
            return orderData;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async paytmInAppCreateOrder(action: any, selectedAmount: any, pay_id: any, airportMode: any): Promise<any> {
        try {
            let bodyObj = new FormData();
            bodyObj.append('action', action);
            bodyObj.append('selectedAmount', selectedAmount);
            bodyObj.append('pay_id', pay_id);
            if (airportMode == 1) { bodyObj.append('AirportMode', airportMode); }

            let url = AppConstants.savaariDomainUrl + '/savaaripaytmStep2.php';
            const response = await this.http.post(url, bodyObj).toPromise();
            const orderData = response;
            return orderData;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async updateConfirmBooking(paymentData: any): Promise<any> {
        try {
            let bodyObj = new FormData();
            bodyObj.append('paymentmode', 'savaariwebsite');
            bodyObj.append('orderId', paymentData.savaari_pay_id);
            bodyObj.append('paymentId', paymentData.razorpay_payment_id);
            bodyObj.append('advancedAmount', paymentData.selectedAmount);

            let url = AppConstants.savaariDomainUrl + '/payment_confirmation/confirmation.php';
            const response = await this.http.post(url, bodyObj).toPromise();
            const orderData = response;
            return orderData;
        } catch (error) {
            return Promise.reject(error);
        }
    }

    async getAirportData(): Promise<any> {
        try {
            let url = '/assets/json/airport.json';
            const res = await this.http.get(url).toPromise();
            return res;
        } catch (error) {
            return [];
        }
    }

    async sent_OTP(isd_code:any, mobile: any, whatsapp_flag: any): Promise<any> {
        try {
            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj = new FormData();
            bodyObj.append('user_mobile', mobile); 
            bodyObj.append('user_isd_code', isd_code);  
            bodyObj.append('send_whatsapp_flag', whatsapp_flag);                                            
            // bodyObj.append('user_email', email);
            // bodyObj.append('booking_id', booking_id);
            // if(user_id != '')
            //     bodyObj.append('user_id', user_id);

            let token = await this.tokenService.getToken()
            const url = this.apiPlaygroundUrl + 'send_login_otp' + '?token=' + token;

            const response: any = await this.http.post(url, bodyObj, { headers: headers }).toPromise();
            return response.data;
        } catch (error: any) {
            const errors = error.error;
            return errors.errors;
        }
    }

}
