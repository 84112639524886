// authentication.service.ts

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
    private loginStatusSubject = new BehaviorSubject<any>(null);
    loginStatus$: Observable<any> = this.loginStatusSubject.asObservable();

    private loginWithEmail = new BehaviorSubject<any>(null);
    emailLogin$: Observable<any> = this.loginWithEmail.asObservable();

  login() {
    const loginData = { status: 'success' };
    this.loginStatusSubject.next(loginData);
  }

  showLogin() {
    const loginData = { status: 'success' };
    this.loginWithEmail.next(loginData);
  }

}
