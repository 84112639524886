import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { AppConstants, SavaariServerErrorcode } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { StorageService } from '../services/storage.service';
import { TokenService } from './token.service';
declare var clevertap: any;

const jwtHelper = new JwtHelperService();

@Injectable({
    providedIn: 'root'
})
export class UserManagementService {

    private baseUrl: string = AppConstants.apiUrl;    // URL to the api of the user details and seo content

    private registrationUrl: string = this.baseUrl + AppConstants.registrationUrl;    // URL to the register users to savaari
    private registrationUrlForCorporate: string = this.baseUrl + AppConstants.registrationUrlForCorporate;  // URL to the register corporate users to savaari
    private loginUrl: string = this.baseUrl + AppConstants.loginUrl;      // URL to the login users to savaari
    private autoLoginUrl: string = this.baseUrl + AppConstants.autoLoginUrl;      // URL to the auto Login users to savaari
    private updateDetailUrl: string = this.baseUrl + AppConstants.updateDetailUrl;    // URL to the update users details to savaari
    private changePasswordUrl: string = this.baseUrl + AppConstants.changePasswordUrl;     // URL to the change the password of users
    private apiPlaygroundUrl: string = AppConstants.apiPlaygroundUrl; // URL to the API PLAYGROUND
    private forgotPasswordUrl: string = AppConstants.forgotPasswordUrl;    // URL to the booking car in the API PLAYGROUND (of savaari team)
    private bookingDetailsUrl: string = this.baseUrl + AppConstants.bookingDetailsUrl;    // URL to the get users booking details
    private bookingDataUrl: string = this.baseUrl + AppConstants.bookingDataUrl;    // URL to the get users booking details
    public status: any;
    constructor(private http: HttpClient, private localStorage: StorageService, private tokenService: TokenService) {

    }

    // Post data to register user to the savaari
    async registerUser(userDetails: any, referrer: string): Promise<any> {

        try {
            let clientip = '';
            try {
                const ipaddress: any = await this.http.get('//api.ipify.org/?format=json&callback=JSONP_CALLBACK').toPromise();
                clientip = ipaddress.ip;
            } catch (e) {
                clientip = '';
            }
            const response = await this.http.post(
                this.registrationUrl, JSON.stringify({
                    'userName': userDetails.userName, 'userISDCode': userDetails.userISDCode,
                    'userPhone': userDetails.userPhone, 'userEmail': userDetails.userEmail, 'password': userDetails.userPassword, 'referer': referrer, 'clientip': clientip
                })).toPromise();

            return response;
        } catch (error: any) {
            return Promise.reject(error.error)
        }
    }

    // Post data to register corporate user to the savaari
    async registerCorporateUser(email: any, name: any, mobile: any, city: string, company: any, monthlyRentalRequirement: any, referer = '', utmId = '', utmCampaignName = '', adgroupId = '', keywords_c = '', camapign_id_c = ''): Promise<any> {

        try {
            let clientip = '';
            try {
                const ipaddress: any = await this.http.get('//api.ipify.org/?format=json&callback=JSONP_CALLBACK').toPromise();
                clientip = ipaddress.ip;
            } catch (e) {
                clientip = '';
            }
            const response = await this.http.post(
                this.registrationUrlForCorporate, JSON.stringify({
                    'email': email,
                    'name': name,
                    'mobile': mobile,
                    'city': city,
                    'company': company,
                    'monthlyRentalRequirement': monthlyRentalRequirement,
                    'referer': referer,
                    'clientip': clientip,
                    'utmId': utmId,
                    'utmCampaignName': utmCampaignName,
                    'adgroupId': adgroupId,
                    'corporateKeyword': keywords_c,
                    'campaignId': camapign_id_c
                })).toPromise();

            return response;
        } catch (error: any) {
            return Promise.reject(error.error)
        }
    }


    // Post password to update user password to the savaari
    async forgotPassword(userEmail: string): Promise<Boolean> {

        try {
            this.localStorage.removeItem('loggedUserDetail');
            this.localStorage.removeItem('loginUserToken');

            const headers = new HttpHeaders();
            headers.append('Content-Type', 'application/x-www-form-urlencoded');
            let bodyObj = new FormData();
            bodyObj.append('user_email', userEmail);
            let token = await this.tokenService.getToken()
            const forgotPasswordUrl = this.apiPlaygroundUrl + this.forgotPasswordUrl + '?token=' + token;
            const result: any = await this.http.post(forgotPasswordUrl, bodyObj, { headers: headers }).toPromise();
            const resultData = result.data;

            if (resultData.noemail == true) {
                throw Error("Email can not be empty")
            }
            if (resultData.passwordsend == false) {
                throw Error("You have no account with us. Please Register")
            }
            return resultData;


        } catch (error: any) {
            let resultData = error.error.errors;
            if (resultData) {
                resultData = JSON.parse(resultData);
            }
            if (resultData.noemail == true) {
                error.message = 'Email can not be empty';
            }
            if (resultData.passwordsend == false) {
                error.message = 'You have no account with us. Please Register';
            }
            if (!error.message) {
                error.message = resultData.message;
            }
            return Promise.reject(error)
        }
    }

    // Post data to login user to the savaari
    async loginUser(userEmail: any, password: any): Promise<Boolean> {

        try {
            this.localStorage.removeItem('loggedUserDetail');
            this.localStorage.removeItem('loginUserToken');
            const result: any = await this.http.post(this.loginUrl, JSON.stringify({ 'userEmail': userEmail, 'password': password })).toPromise();
            const resultData = result;
            this.localStorage.setItem('loggedUserDetail', JSON.stringify(resultData.user));
            this.localStorage.setItem('loginUserToken', resultData.token);            
            return Promise.resolve(true);

        } catch (error: any) {
            return Promise.reject(error.error);
        }

    }

    // Post data to login user to the savaari
    async OtpVerify(isd_code: any, userMobile: any, otp: any): Promise<Boolean> {

        try {
            this.localStorage.removeItem('loggedUserDetail');
            this.localStorage.removeItem('loginUserToken');
            const result: any = await this.http.post(this.baseUrl + 'user/otplogin', JSON.stringify({ 'user_isd_code': isd_code, 'userMobile': userMobile, 'otp': otp })).toPromise();
            const resultData = result;
            console.log(resultData)
            this.localStorage.setItem('loggedUserDetail', JSON.stringify(resultData.user));
            this.localStorage.setItem('loginUserToken', resultData.token);
            this.localStorage.setItem('whatsAppEnableFlag', resultData.user.whatsapp_sms_subscription_flag == 'YES' ? 1 : 0);
            clevertap.onUserLogin.push({
                "Site": {
                    "Name": resultData.user.firstname,
                    "Identity": resultData.user.user_id,
                    "Email": resultData.user.email,
                    "Phone": "+" + resultData.user.isd_code + resultData.user.mobileno,
                    "MSG-email": true,                // Disable email notifications
                    "MSG-push": true,                  // Enable push notifications
                    "MSG-sms": true,                   // Enable sms notifications
                    "MSG-whatsapp": true,              // Enable WhatsApp notifications
                }
            })
            return Promise.resolve(true);

        } catch (error: any) {
            return Promise.reject(error.error);
        }

    }


    // If valid JWT, Post data to auto login user to the savaari
    async autologin(): Promise<Boolean> {
        try {
            const logintoken = this.localStorage.getItem('loginUserToken');
            const storedInfo = this.localStorage.getItem('loggedUserDetail');

            if (jwtHelper.isTokenExpired(logintoken) == true) {
                this.localStorage.removeItem('loggedUserDetail');
                this.localStorage.removeItem('loginUserToken');
                throw Error()
            }


            if (storedInfo == null || logintoken == null) {
                throw Error()
            }
            const loginUserStorageString = this.localStorage.getItem('loggedUserDetail');
            const loginUserStorageObject = JSON.parse(loginUserStorageString);
            const app_user_id = loginUserStorageObject.user_id;
            const result: any = await this.http.post(this.autoLoginUrl, JSON.stringify({ 'app_user_id': app_user_id, 'logintoken': logintoken })).toPromise();
            const resultData = result;
            this.localStorage.setItem('loggedUserDetail', JSON.stringify(resultData.user));
            this.localStorage.setItem('loginUserToken', resultData.token);
            this.localStorage.setItem('whatsAppEnableFlag', resultData.user.whatsapp_sms_subscription_flag == 'YES' ? 1 : 0);
            clevertap.onUserLogin.push({
                "Site": {
                    "Name": resultData.user.firstname,
                    "Identity": resultData.user.user_id,
                    "Email": resultData.user.email,
                    "Phone": "+" + resultData.user.isd_code + resultData.user.mobileno,
                    "MSG-email": true,                // Disable email notifications
                    "MSG-push": true,                  // Enable push notifications
                    "MSG-sms": true,                   // Enable sms notifications
                    "MSG-whatsapp": true,              // Enable WhatsApp notifications
                }
            })
            return Promise.resolve(true);

        } catch (error: any) {
            const resultData = error.error;
            if (resultData.statusCode === SavaariServerErrorcode.NotFoundError) {

                this.localStorage.removeItem('loggedUserDetail');
                this.localStorage.removeItem('loginUserToken');
                window.location.href = '/';
            }
            return Promise.reject(error.error)

        }
    }


    // Post data to update user detail to the savaari
    async updateUserPersonal(userData: any, loginToken: any): Promise<any> {
        try {
            let dataInput: any = {
                'userEmail': userData.userEmail,
                'userName': userData.userName,
                'userPhone': userData.userPhone,
                'alternatePhone': userData.alternatePhone,
                'whatsapp_optin': userData.isChecked ? 'YES' :'NO',
                'token': loginToken
            }

            let loginUserStorageString = this.localStorage.getItem('loggedUserDetail');
            const loginUserStorageObject = JSON.parse(loginUserStorageString);
            //if (loginUserStorageObject.email == '') {                
            dataInput.app_user_id = loginUserStorageObject.user_id
            //}

            const res = await this.http.post(this.updateDetailUrl, JSON.stringify(dataInput)).toPromise();
            clevertap.profile.push({
                "Site": {
                    "Name": userData.userName,
                    "Identity": loginUserStorageObject.user_id,
                    "Email": userData.userEmail,
                    "Phone": "+" + loginUserStorageObject.isd_code + loginUserStorageObject.mobileno,
                    "MSG-email": true,                // Disable email notifications
                    "MSG-push": true,                  // Enable push notifications
                    "MSG-sms": true,                   // Enable sms notifications
                    "MSG-whatsapp": userData.isChecked,              // Enable WhatsApp notifications         
                }
            });
            return res;

        } catch (error: any) {
            return Promise.reject(error.error);
        }

    }

    async updateUserID(userData: any, loginToken: any): Promise<any> {
        try {
            const res = await this.http.post(this.updateDetailUrl, JSON.stringify({
                'userEmail': userData.userEmail,
                'userName': userData.userName,
                'userPhone': userData.userPhone,
                'alternatePhone': userData.alternatePhone,
                'token': loginToken,
                'app_user_id': userData.app_user_id
            })).toPromise();
            let whatsAppEnableFlag = this.localStorage.getItem('whatsAppEnableFlag');

            clevertap.profile.push({
                "Site": {
                    "Name": userData.userName,
                    "Identity": userData.app_user_id,
                    "Email": userData.userEmail,
                    "Phone": "+" + userData.isd_code + userData.userPhone,
                    "MSG-email": true,                // Disable email notifications
                    "MSG-push": true,                  // Enable push notifications
                    "MSG-sms": true,                   // Enable sms notifications
                    "MSG-whatsapp": whatsAppEnableFlag === "1" ? true : false,              // Enable WhatsApp notifications         
                }
            });
            return res;

        } catch (error: any) {
            this.localStorage.removeItem('loggedUserDetail');
            this.localStorage.removeItem('loginUserToken');
            return Promise.reject(error.error);
        }

    }

    // Post password to update user password to the savaari
    async updateUserPassword(oldPassword: any, newPassword: any, userEmail: any, loginToken: any): Promise<any> {

        try {

            const res = await this.http.post(this.changePasswordUrl, JSON.stringify({
                'oldPassword': oldPassword,
                'newPassword': newPassword,
                'userEmail': userEmail,
                'token': loginToken
            })).toPromise();

            return res;

        } catch (error: any) {
            return Promise.reject(error.error);
        }
    }


    // Get booking data of the logged in user
    async getBookingDetails(email: any, token: any): Promise<any> {

        try {
            const loginUserStorageString = this.localStorage.getItem('loggedUserDetail');
            const loginUserStorageObject = JSON.parse(loginUserStorageString);
            const params = new HttpParams({
                fromObject: {
                    //'userEmail': email,
                    'app_user_id': loginUserStorageObject.user_id,
                    'token': token
                }
            });

            const res: any = await this.http.get(this.bookingDetailsUrl, { params: params }).toPromise();

            if (res.statusCode == 204) {

                return Promise.reject(res);

            } else {
                const data = res.bookingDetails;
                return data;
            }

        } catch (error: any) {
            return Promise.reject(error)
        }

    }

    // Get booking data of booking id and email
    async getBookingData(booking_id: any, email: any, token: any): Promise<any> {

        try {
            const params = new HttpParams({
                fromObject: {
                    'userEmail': email,
                    'booking_id': booking_id,
                    'token': token
                }
            });

            const res: any = await this.http.get(this.bookingDataUrl, { params: params }).toPromise();

            if (res.status === 204) {

                return Promise.reject(res)

            } else {
                const data = res.bookingDetails;
                return data;
            }

        } catch (error) {
            return Promise.reject(error)
        }

    }
}
