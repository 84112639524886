// This file can be replaced during build by using the fileReplacements array.
// ng build replaces environment.ts with environment.prod.ts.
// The list of file replacements can be found in angular.json.

export const environment = {
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as zone.run, zoneDelegate.invokeTask.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

export class AppConstants {
  /*** BASE URL CONSTANTS ***/
  public static savaariDomainUrl = 'https://www.betasavaari.com';
  public static apiUrl = 'https://api23.betasavaari.com/';
  public static webcacheUrl = 'https://api23.betasavaari.com/';
  public static savaari_api_Url = 'https://betasavaari.com/payment_confirmation/';
  public static advanve_payment_check_path = 'advance_payment_check.php';
  public static savaari_Url = 'https://betasavaari.com/payment_confirmation/';
  public static language_carrier_path = 'carrier_lang_req.php';
  public static whatsapp_path = 'whatsapp_path.php';
  public static razorpay_api_key = 'rzp_test_dsrBANLbHxlwZb';
  public static google_api_key = 'AIzaSyAmnnuQWUPhBi8HPbCMPkgfeqBrFD-f-dY';
  public static travelkit_server = 'https://travelkit.savaari.com';

  public static tokenPath = 'auth/token';
  public static sourceCitiesPath = 'source-cities';
  public static destinationCitiesPath = 'destination-cities';
  public static chardhamCitiesPath = 'chardham-cities';
  public static localSubTripTypes = 'web-trip-types';
  public static avilabilityPath = 'availabilities';
  public static pressReleasesPath = 'press-releases';
  public static offersCouponsPath = 'offers';
  public static seoContentPath = 'seo-content';
  public static newSeoContentPath = 'seo-new-content';
  public static seoCityListPath = 'seo-list';
  public static localitiesPath = 'localities';
  public static countryCodePath = 'country-code';
  public static savaari_ajax_Path = 'ajax';
  public static email_check_ApiUrl = 'email_sent';
  /*** USER RELATED API ***/
  public static registrationUrl = 'user';
  public static registrationUrlForCorporate ='user/corporate';
  public static loginUrl = 'user/login';
  public static autoLoginUrl = 'user/autologin';
  public static updateDetailUrl = 'user/update-profile';
  public static changePasswordUrl = 'user/update-password';
  public static forgotPasswordUrl = 'forgot_password';
  public static bookingDetailsUrl = 'booking-details';
  public static bookingDataUrl = 'booking-data';
  public static bookingEditUrl = 'booking-edit';
  public static bookingUpdateUrl = 'booking-update';
  public static bookingBillUrl = 'data/final_bill.php';

  /*** API PLAYGROUND API ***/
  public static apiPlaygroundUrl = 'https://api.betasavaari.com/partner_api/public/';
  public static apiKey = 'f645dbc7cd4ba17caf4fac8abc53dc02a01231dde7ec1c31124895aa0fd24166';
  public static appId = 'MjAxN3Nhdm1vYmlsZXdlYnNpdGU=';
  public static ApiUrl_internal = 'https://api.betasavaari.com/';

  /*** BOOKING RELATED API ***/
  public static bookingUrl = 'booking';
  public static vasUpdateUrl = 'vas_booking_update';
  public static updateBookingUrl = 'booking_update';
  public static applyCouponUrl = 'apply_coupon';
  public static removeCouponUrl = 'remove_coupon';
  public static updateFlightUrl = 'update_flight_details';
  public static validateCouponUrl = 'coupon-code';
  public static enquiryApiUrl = 'customer_app/v3/enquiry.php';

  public static payment_link_data = '/payment_link_data';
  public static update_payment_info = '/update_payment_info';
  public static payment_link_validate = 'payment_link_validate.php';
  public static date_time_path = 'date_time';
  /*** CONSTANT MESSAGES ***/
  public static emailPattern = new RegExp(/^(([^<>()\[\]\\.,;%:\s@"]+(\.[^<>()\[\]\\.,;%:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  public static lessThanTimeSlab = 7;
  public static localKilometer = 80;
  public static localPackage = 'Local (8hr/80 km)';
  public static commonErrorMessage = 'Sorry - Something went wrong with your request. <a routerLink="/">Please click here to try again</a>. If the issue persists, please call<a href="tel: 9045450000">9045450000</a> and our customer service executives will assist you.';

  public static cacheDuration = 1 * (24 * 60 * 60);

  //public static localSubTripType = '880';

  /*** SELECTED PAYMENT CONSTANTS ***/
  public static PART_PAYMENT_FLAG = 1;
  public static ADVANCE_PERCENT = 30;

  /*cities and cars for luggage and carrier*/
  public static cities: any = ["377", "223", "145", "114", "348", "73", "81"];
  public static cars: any = [18, 45, 46, 47];

  /**cities for dropdown */
  public static topSourceCities: any = ["377", "145", "114", "81", "261", "223", "48", "320", "422", "36"];

  /**cities for dropdown */
  public static topDestinationCities: any = ["1076", "4506", "4483", "2754", "4170", "1993", "7878", "427", "560", "2532"]

  /* Banner Image API */
  public static bannerImagePath = 'banner-images-list';
  public static bannerImageFolderPath = './assets/sliderBanner/';
  public static bannerImageList = [
      'savaari-default.jpg'
  ]

  /* Loyalty payment gateway Internal API URL */
  public static loyaltyInternalApiUrl = 'https://dev.betasavaari.com/api/loyaltyrewardz.php';
  public static LoyaltyRewardsPaymentConfirmationRedirectionUrl = 'https://www.betasavaari.com/payment_confirmation/confirmation.php';
  public static EnableLoyaltyRewardsPaymentGateway = true;

  /* Customer feedback */
  public static feedbackApiUrl = 'https://api.betasavaari.com/booking_engine/v2/api/feedback.php';
  public static reviewApiUrl = 'https://api.betasavaari.com/booking_engine/v2/api/reviews.php';
  public static cancelBookingApiUrl = 'https://api.betasavaari.com/system_bookings/cancellation.php';
}

export enum SavaariMetros {

  city1 = 377,
  city2 = 223,
  city3 = 145,
  city4 = 114,
  city5 = 348,
  city6 = 73,
  city7 = 81,
  city8= 261,

}

export enum PickUpTimeValidationResult {
  Invalid = 1,
  Valid = 2,
  TooEarly = 3
}

export enum TripTypeConstant {
  outstation = 3,
  local = 2
}

export enum SubTripTypeConstant {
  roundTrip = 8,
  oneWay = 7,
  local_440 = 3,
  local_880 = 4,
  local_12120 = 12,
  air_pickup = 1,
  air_dropoff = 2
}


export function subTripTypeConstants(subtriptype: any) {
  switch (subtriptype) {
      case 8: return 'OUT_ROUND';
      case 7: return 'OUT_ONEWAY';
      case 4: return '880';
      case 3: return '440';
      case 12: return '12120';
      case 1: return 'PICK_AIRPORT';
      case 2: return 'DROP_AIRPORT';
      default: return;
  }
}

export enum SavaariServerErrorcode {
  Noservices = 61000,
  NoDestinationCity = 21004,
  TimeExpire = 51002,
  PartnerApiTokenError = 11001,
  NotFoundError = 401,
  UrgentBooking10DaysError = 51010,
  NoCarsError = 61001,
  NoDestinationCityList = 61002,
  NoservicesOneWay = 1
}


export class SavaariDomainErrorUtils {
  public static SavaariDomainErrorMessage(errorCode: any) {
      switch (errorCode) {
          case SavaariServerErrorcode.Noservices: return 'Sorry - We currently do not have services in your searched city. Please click here to search with a different city.';
          case SavaariServerErrorcode.NoservicesOneWay: return 'We have currently stopped one-way services on this route. Book a round trip to avail attractive discounts.';
          case SavaariServerErrorcode.NoDestinationCity: return 'Sorry - We currently do not have services in your searched city. Please click here to search with a different city.';
          case SavaariServerErrorcode.UrgentBooking10DaysError: return 'For trips longer than 30 days, please call <a href="tel: 9045450000">90 4545 0000</a> and we will assist you with a confirmed booking.';
          case SavaariServerErrorcode.NoCarsError: return 'Sorry - Something went wrong with your request. Please click here to try again. If the issue persists, please call <a href="tel: 9045450000">90 4545 0000</a> and our customer service executives will assist you.';
          default: return;
      }
  }
}


export enum partnerAffiliateIds {
  indigo = 285406,
  ixigo = 62075,
  ixigoTrain = 1448278,
  clubMahindraDesktop = 1448366,
  clubMahindraApp = 1448368
}