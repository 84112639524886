import { Component } from '@angular/core';
import { TokenService } from './services/token.service';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  isTokenLoaded = true;
  metaTitle = '';
  metaDescription = '';
  partnerDetails = {};
  partnerSeoContent = '';
  tripType = 'outstation';
  subtripType = 'oneWay';
  seocontent = [];
  sourceCityName = '';
  destinationCityName = '';
  showMenu = false;
  selectCarFlag = false;
  showNavbar: boolean = true;

  constructor(private tokenService: TokenService,private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Check the route to see if the navbar should be hidden
        this.showNavbar = !event.url.includes('populate');
      }
    });
  }

  ngOnInit() {

  }

  refreshToken = async () => {
    try {
      await this.tokenService.getToken();
      this.isTokenLoaded = true;
    } catch (error) {
      this.isTokenLoaded = true;
    }
  }

  ngDoCheck() {
    if (window.location.pathname.includes('select_cars')) {
      this.selectCarFlag = true;
    } else {
      this.selectCarFlag = false;
    }
  }


  openMenu() {
    this.showMenu = true;
  }

  closeMenu() {
    this.showMenu = false;
  }

}
