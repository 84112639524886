<div class="headLayer">
    <div *ngIf="mobileStatus !== 'valid'" class="m-0 float-left" style="font-weight: 500;">Enter your mobile number to
        proceed</div>
    <div *ngIf="mobileStatus == 'valid'" class="m-0 float-left" style="font-weight: 500;">OTP sent to {{mobile}}</div>
</div>
<button class="closeIcon" mat-dialog-close style="top: 5px;right: 5px;font-size: 26px; color: #ffffff;">&times;</button>
<div class="row">
    <div class="col-md-1 col-lg-1 col-sm-1"></div>
    <div class="col-md-10 col-lg-10 col-sm-10" style="padding: 10px 35px 0 35px;">
        <!-- <div class="" style="text-align: center;">
            <span class="text-uppercase pickup-details-heading">Account authentication</span>
        </div> -->

        <!-- Initial fields and button -->
        <div *ngIf="mobileStatus !== 'valid'" style="margin-top: 1.7rem;">
            <div class="input-group">
                <select id="idISD" class="single-border-input" [(ngModel)]="selectedCountryCode" (change)="handleChange()">
                    <option *ngFor="let code of countryCode;let idx = index" value="{{code.key}}"
                        style="border-bottom-color: #ced4da;">
                        {{code.value.country_name +' (+'+code.value.isd_code+')'}}
                    </option>
                </select>
                <input type="number"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    [maxlength]="selectedCountryCode !== '91|IND' ? 13 : 10"
                    style="border-radius: 8px;box-shadow: 0 4px 8px #01010133;" placeholder="Eg. 99XXXXXXXX"
                    class="form-control" [(ngModel)]="mobile" />
            </div>

            <div *ngIf="selectedCountryCode !== '91|IND'" style="display: flex; padding: 10px; margin-top: 5px; text-align: center;
            margin-bottom: -50px; justify-content: center;">
                <input type="checkbox" [(ngModel)]="isChecked" style=" top: -7px; margin-right: 7px;">
                <p>I agree to receive OTP on the above WhatsApp number</p>
            </div>

            <button type="button" class="otp-theme-button mr-5" (click)="resendOtp()" [disabled]="!isChecked"
                [class]="{'otp-theme-button-Premium': IsPremium==1}">
                CONTINUE
                <span *ngIf="otpSentProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                    style="color: #20a8d9"></span>
            </button>
            <!-- <button *ngIf="selectedCountryCode == '91|IND'" type="button" class="otp-theme-button mr-5"
                (click)="resendOtp()" [disabled]="" [class]="{'otp-theme-button-Premium': IsPremium==1}">
                CONTINUE
                <span *ngIf="otpSentProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                    style="color: #20a8d9"></span>
            </button> -->
            <!-- <div *ngIf="selectedCountryCode !== '91|IND'" style=" background-color: #FF8C00; 
            color: #ffffff; 
            padding: 10px;
            border-radius: 5px;
            margin-top: 5px;
            text-align: center;
            margin-top: 25px;">
                <p style="margin: 0;
                font-size: 14px;">Please Login/Register <a
                        style="text-decoration: underline; color: #ffffff; font-weight: bolder;"
                        (click)="loginWithEmail()">Here</a> To Proceed</p>
            </div> -->
        </div>

        <!-- OTP verification fields and button -->
        <div *ngIf="mobileStatus === 'valid'" style="margin-top: 1rem;">
            <input autocomplete="one-time-code" inputmode="numeric" name="one-time-code"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                type="number" maxlength="6" style="border-radius: 8px;box-shadow: 0 4px 8px #01010133;"
                placeholder="Enter OTP sent to your number" class="form-control margin-top-5px" [(ngModel)]="otpcode" />


            <button type="button" class="verify-button" (click)="verifyOTP()" [disabled]=""
                [class]="{'otp-theme-button-Premium': IsPremium==1}">
                VERIFY OTP
                <span *ngIf="otpCheckProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                    style="color: white"></span>
            </button>
        </div>
        <div class="d-flex" [ngStyle]="{'justify-content': mobileStatus === 'valid' ? 'space-between' : 'center'}">
            <div class="mt-2" *ngIf="mobileStatus === 'valid'" style="display: flex; justify-content: center;">
                <!-- <span style="color: #20a8d9 !important;
                    font-size: 10px;
                    height: 20px;
                    cursor: pointer;" (click)="resetMobileStatus()">
                    Change Phone Number
                </span> -->
                <span *ngIf="showButton"
                style="color: #20a8d9 !important; font-size: 10px; height: 20px; cursor: pointer; margin-top: 2px;" 
                (click)="resendWhatsAppOtp()">
            Resend OTP
          </span>
          
          <span *ngIf="!showButton"
          style="color: #4f4f4f !important; font-size: 10px; height: 20px; cursor: pointer; margin-top: 2px;" >
            Resend OTP in 00:{{ timer < 10 ? '0' + timer : timer }}
          </span>
            </div>
            <div class="mt-2" style="font-style: italic; text-align: center">
                <small *ngIf="otpStatus == 'empty'" class="text-danger">
                    Please enter OTP.
                </small>
                <small *ngIf="otpStatus == 'invalid'" class="text-danger">
                    Please enter valid OTP
                </small>
                <small *ngIf="mobileStatus == 'empty'" class="text-danger">
                    Please enter your mobile number
                </small>
                <small *ngIf="mobileStatus == 'invalid'" class="text-danger">
                    Please enter a valid Mobile Number
                </small>
                <small *ngIf="otpStatus == 'OTPVerifiedFailed'" class="text-danger">
                    OTP Verification Failed.
                </small>
                <small *ngIf="otpStatus == 'OTPVerified'" class="validation-success">
                    OTP Successfully Verified.
                </small>

                <small *ngIf="otpStatus == 'OTPSent' && mobileStatus =='valid' " class="validation-success">
                    Valid for 15 minutes
                </small>
                <small *ngIf="otpStatus == 'OTPSentfailed'" class="text-danger">
                   {{errorMessage}}
                </small>
                <span *ngIf="otpSentProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                    style="color: #20a8d9">
                </span>
            </div>
        </div>

    </div>
    <div class="col-md-1 col-lg-1 col-sm-1"></div>
</div>