import { Component, OnInit, Inject } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { PaymentSessionService } from '../../services/payment.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserManagementService } from '../../services/usermanagement.service';
import { AuthenticationService } from '../../services/otp.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css']
})
export class OtpComponent implements OnInit {
  errorMessage: any;

  constructor(private localStorage: StorageService,
    private paymentService: PaymentSessionService,
    private dialogRef: MatDialogRef<OtpComponent>,
    private usermanagement: UserManagementService,
    private authService: AuthenticationService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { mobileNumber: string, countryCode: string }
  ) {

  }

  otpcode: any;
  otpStatus: any;
  mobileStatus: any;
  otpCheckProgress = false;
  otpSentProgress = false;
  IsPremium = 0;
  custEmail: any;
  user_id: any;
  mobile: any;
  booking_id: any;
  countryCode: any[] = [];
  selectedCountryCode: string = '91|IND';
  isChecked: boolean = false;
  whatsapp_flag: any = 0;
  // bookingDetail = new BookingDetails();
  mainObj: any = {};
  showButton = false;
  timer = 60;
  intervalId: any;

  ngOnInit(): void {
    let ct_data = {
      event_date: new Date(),
      pop_up: "Open"
    }
    const currentUrl = this.router.url;
    if (currentUrl.includes('/select_cars')) this.localStorage.save_CT_data('login-popup', ct_data);

    // let bookingInfoString = this.localStorage.getItem('BookingParameters');

    //   let bookingInfo = JSON.parse(bookingInfoString);

    // Access properties
    //   console.log(bookingInfo.tripType);
    // console.log(bookingInfo)

    // bookingInfo = JSON.parse(bookingInfo);
    // console.log(bookingInfo.data.customer)

    // this.custEmail = bookingInfo.data.customer.email
    // this.mobile = bookingInfo.data.customer.mobile
    // this.user_id = bookingInfo.data.customer.user_id
    // this.booking_id = bookingInfo.data.bookingId

    // this.resendOtp();

    if (this.data.mobileNumber) {
      this.mobile = this.data.mobileNumber;
      this.selectedCountryCode = this.data.countryCode;
      this.resendOtp();
    }
    this.getCountryCodes();
    this.countryCode.push({ 'key': '91|IND', 'value': { country_name: 'India', isd_code: '91' } });

    if (this.selectedCountryCode == '91|IND') {
      this.isChecked = true;
    }
  }

  handleChange() {
    this.mobileStatus = '';
    if (this.selectedCountryCode == '91|IND') {
      this.isChecked = true;
    }
    else
      this.isChecked = false;
  }

  async verifyOTP() {
    console.log(this.otpcode);
    console.log(typeof this.otpcode);
    this.otpStatus == ''
    if (this.otpcode == '' || typeof this.otpcode == 'undefined') {
      this.otpStatus = 'empty';
    } else if (this.otpcode.toString().length < 6) {
      this.otpStatus = 'invalid';
    } else {
      this.otpCheckProgress = true;

      let ct_data = {
        event_date: new Date(),
        otp_entered: this.otpcode,
        otp_verified: false
      }
      //logic to check otp 
      try {
        await this.usermanagement.OtpVerify(this.selectedCountryCode, this.mobile, this.otpcode)
        this.otpStatus = 'OTPVerified';
        ct_data.otp_verified = true;
        const currentUrl = this.router.url;
        if (currentUrl.includes('/select_cars')) this.localStorage.save_CT_data('popup-otp', ct_data);
        this.otpCheckProgress = false;
        this.authService.login();
        this.dialogRef.close(1);

      } catch (error: any) {
        //  if (error.message) {
        //   this.errorMessage = error.message;
        // } 
        ct_data.otp_verified = false;
        const currentUrl = this.router.url;
        if (currentUrl.includes('/select_cars')) this.localStorage.save_CT_data('popup-otp', ct_data);
        this.otpStatus = 'OTPVerifiedFailed';
        this.otpCheckProgress = false;
      }
    }
  }

  resetMobileStatus() {
    this.otpStatus = ''
    this.mobileStatus = '';
  }

  startTimer() {
    this.intervalId = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
      } else {
        this.showButton = true;
        clearInterval(this.intervalId);
      }
    }, 1000); // Update timer every second
  }

  async resendOtp() {
    this.startTimer();
    this.otpStatus == ''
    this.mobileStatus = ''
    if (this.mobile == '' || typeof this.mobile == 'undefined' || this.mobile == null) {
      this.mobileStatus = 'empty';
    } else if (this.selectedCountryCode === '91|IND' && this.mobile.toString().length < 10) {
      this.mobileStatus = 'invalid';
    } else if (this.selectedCountryCode !== '91|IND' && this.mobile.toString().length < 7) {
      this.mobileStatus = 'invalid';
    } else if (this.selectedCountryCode === '91|IND' && !/^[6-9]\d{9}$/.test(this.mobile.toString())) {
      this.mobileStatus = 'invalid';
    }
    // else if (/^(\d)\1{9}$/.test(this.mobile.toString())) {
    //   this.mobileStatus = 'invalid'; 
    // }
    else {
      this.otpSentProgress = true;
      this.mobileStatus = 'valid';

      let ct_data = {
        event_date: new Date(),
        phone: "+" + this.selectedCountryCode.split('|')[0] + this.mobile
      }
      const currentUrl = this.router.url;
      if (currentUrl.includes('/select_cars')) this.localStorage.save_CT_data('popup-phone', ct_data);

      const response = await this.paymentService.sent_OTP(this.selectedCountryCode, this.mobile, this.whatsapp_flag);
      let res = JSON.parse(response);
      if (res.otpsend == true) {
        this.otpSentProgress = false;
        this.otpStatus = 'OTPSent';

        await this.handleOtpRequest();

      } else {
        this.otpSentProgress = false;
        this.otpStatus = 'OTPSentfailed';
        if (res.msg) {
          this.errorMessage = res.msg;
        }
      }
      console.log('RESENOTP');
    }
  }

  async resendWhatsAppOtp() {
    this.showButton = false;
    this.timer = 60;
    this.startTimer();
    this.otpStatus == ''
    this.mobileStatus = ''

    this.otpSentProgress = true;
    this.mobileStatus = 'valid';
    this.whatsapp_flag = 1;
    const response = await this.paymentService.sent_OTP(this.selectedCountryCode, this.mobile, this.whatsapp_flag);
    let res = JSON.parse(response);
    if (res.otpsend == true) {
      this.otpSentProgress = false;
      this.otpStatus = 'OTPSent';

      await this.handleOtpRequest();

    } else {
      this.otpSentProgress = false;
      this.otpStatus = 'OTPSentfailed';
      if (res.msg) {
        this.errorMessage = res.msg;
      }
    }
    console.log('RESENOTP');
    // Reset the timer
  }

  /**
 * Handles the request to retrieve OTP (One-Time Password) using the WebOTP API.
 * If OTP credentials are successfully retrieved, it extracts the OTP code,
 * calls the verifyOTP() method, and logs the retrieved OTP code.
 */
  async handleOtpRequest() {
    const o = {
      otp: { transport: ['sms'] },
    };

    try {
      // Attempt to retrieve OTP credentials asynchronously
      const credential = await navigator.credentials.get(o);

      // If OTP credentials are retrieved successfully
      if (credential) {
        if (credential.code) {
          this.otpcode = credential.code;
          this.verifyOTP();
          console.log("OTP retrieved:", this.otpcode);
        } else {
          console.warn("Received credential doesn't have a code property.");
        }
      } else {
        console.log("No OTP credential found.");
      }
    } catch (error) {
      console.error("Error retrieving OTP credential:", error);
    }
  }

  async getCountryCodes() {
    const codes = await this.paymentService.getCountryCode();
    if (codes.length > 0) {
      this.countryCode = codes;
    }
  }

  async loginWithEmail() {
    this.authService.showLogin();
    this.dialogRef.close();
  }

}
